// 我推广的公司
<template>
  <div class="companyReferred">

    <el-dialog :visible.sync="dialogVisible" :show-close="false" width="960px" :modal-append-to-body="false" :close-on-click-modal="false">
      <div class="content">
        <div class="content_header">
          <p>我的推广</p>
          <svg xmlns="http://www.w3.org/2000/svg" class="pointer" @click="close" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M4.7082 6.00001L0.267561 1.55936C0.0368035 1.3286 -0.0533176 0.992267 0.0311455 0.677046C0.115609 0.361825 0.361824 0.115609 0.677045 0.0311455C0.992266 -0.0533177 1.3286 0.0368035 1.55936 0.267561L6 4.70821L10.4406 0.267561C10.6714 0.0368035 11.0077 -0.0533177 11.323 0.0311455C11.6382 0.115609 11.8844 0.361825 11.9689 0.677046C12.0533 0.992267 11.9632 1.3286 11.7324 1.55936L7.2918 6.00001L11.7324 10.4407C12.0892 10.7974 12.0892 11.3757 11.7324 11.7325C11.3757 12.0892 10.7974 12.0892 10.4406 11.7325L6 7.29181L1.55936 11.7325C1.20264 12.0892 0.624281 12.0892 0.267561 11.7325C-0.0891593 11.3757 -0.0891593 10.7974 0.267561 10.4407L4.7082 6.00001Z"
              fill="#999999" />
          </svg>
        </div>
        <div class="content_table">
          <el-table :data="list" style="width: 100%;background:transparent">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="name" label="企业名称" width="350">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="representativeName" label="法人姓名">
              <template slot-scope="scope">
                <span>{{ scope.row.representativeName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="法人手机号">
              <template slot-scope="scope">
                <span>{{ scope.row.phone }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="firstPayment" label="是否完成首充">
              <template slot-scope="scope">
                <span v-if="scope.row.firstPayment" style="color:#0062FF;">完成</span>
                <span v-else style="color:#FF8412;">未完成</span>
              </template>
            </el-table-column>
            <el-table-column prop="authAt" label="企业实名时间">
              <template slot-scope="scope">
                <span>{{ scope.row.authAt | timeFormat }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      list: []
    };
  },
  created() { },
  computed: {},
  methods: {
    async show() {
      const { data } = await this.$api.getCompanyReferredList()
      this.list = data
      this.dialogVisible = true;

    },
    close() {
      this.dialogVisible = false;
      this.list = [];
    }
  },
};
</script>

<style scoped lang='scss'>
.companyReferred {
  &/deep/.el-dialog {
    background: transparent;
    box-shadow: none;

    &__header {
      padding: 0;
    }

    &__body {
      padding: 0;
    }
  }
  .content {
    background: #fff;
    &_header {
      display: flex;
      justify-content: space-between;
      height: 60px;
      padding: 0 20px;
      color: #262626;
      font-family: "Source Han Sans CN";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      align-items: center;
    }
    &_table {
      max-height: 390px;
      overflow-y: auto;
      padding: 20px;
      padding-top: 0;
    }
  }
}
</style>