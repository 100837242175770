<template>
    <div>
        <el-cascader :value="value" placeholder="请选择渠道" clearable @change="change" style="width:100%;"
            :props="props"></el-cascader>
        <!-- <el-select :value="value" placeholder="请选择渠道" clearable @change="change" style="width:100%;">
            <el-option v-for="item in list" :key="item.code" :label="item.name" :value="item.divisionId">
            </el-option>
        </el-select> -->
    </div>
</template>
<script>
import { Promise } from 'core-js';

export default {
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    components: {},
    data() {
        return {
            node: {},
            props: {
                value: 'divisionId',
                label: 'name',
                lazy: true,
                checkStrictly: false,
                lazyLoad: async (node, resolve) => {
                    this.node = node
                    console.log(node)
                    const nodes = await this.getDivisionList(node.value)
                    resolve(nodes.map(res => ({ ...res, leaf: node.level > 0 })));
                }
            }
        };
    },
    mounted() {
        this.getDivisionList()
    },
    methods: {
        change(value) {
            if (value.length) {
                const province = this.node.label;
                const city = this.node.children.find(res => res.value == value[1]).label
                this.$store.commit("electronic/setqiangzhipaichaData", {
                    province:province,
                    city
                })
            }
       


            this.$emit('input', value.lastItem)
        },
        province(res) {
            const [province] = res
            this.$emit('province', province)
        },
        async getDivisionList(parentId) {
            let params = {};
            parentId && (params.parentId = parentId)
            const { data } = await this.$api.getDivisionList(params);
            return new Promise((resolve, reject) => {
                resolve(data)
            })
        }
    },
};
</script>
<style lang='scss' scoped></style>