/*
* @Author: 曹俊杰
* @Date: 2023-01-08 10:59:56
* @Module: 个人中心
*/
<template>
  <div class="personal border">
    <div class="title">个人信息</div>
    <div class="userInfo">
      <div class="avatar item" style="height:80px; ">
        <div class="label">头像：</div>
        <Mavatar :size="58" :src="user.avatar"></Mavatar>
        <el-upload action="" :auto-upload="false" accept="image/*" :show-file-list="false" :on-change="onChange">
          <div class="btn">设置头像</div>
        </el-upload>

      </div>

      <div class="name item">
        <div class="label">姓名：</div>
        <div class="value">
          <p>{{ user.name || '暂无信息' }}</p>
          <renzheng-icon :value="auth"></renzheng-icon>
        </div>
      </div>
      <div class="phone item">
        <div class="label">账号：</div>
        <div class="value">{{ user.phone || '-' }}</div>
      </div>
      <div class="createdAt item">
        <div class="label">注册时间：</div>
        <div class="value ">{{ user.createdAt | timeFormat('yyyy-mm-dd hh:MM') }}</div>
      </div>
    </div>
    <div class="line2"></div>
    <div class="title">所属企业</div>
    <div class="companyInfo">
      <div class="company item">
        <div class="label">所在企业：</div>
        <div class="value">
          <p>{{ companyObj.name || '暂无信息' }}</p>
          <renzheng-icon :value="companyAuth" type="companyAuth"></renzheng-icon>
        </div>
      </div>
      <div class="creator item">
        <div class="label">我的角色：</div>
        <div class="value">
          <p v-if="!companyAuth">--</p>
          <p v-else-if="user.creator" style="color:#FF8412">法人</p>
          <p v-else style="color:#0062FF">员工</p>
        </div>
      </div>
    </div>
    <div class="line2"></div>
    <div class="title">密码管理</div>
    <div class="companyInfo">
      <div class="company item">
        <div class="label"> 账号密码：<span v-if="user.hasPassword">************</span><span v-else>（空）</span></div>
        <div class="btn" @click="settingPasswordClick">{{user.hasPassword?'修改':'设置'}}密码</div>
      </div>
    </div>
    <settingPassword ref="settingPassword"/>
  </div>
</template>
<script>
import userinfo from "@/mixins/userinfo.js"
import renzhengIcon from "@/components/renzhengIcon.vue"
import settingPassword from "./components/settingPassword.vue"
import uploadFile from '@/utils/oss.js'  // 引入oss.js文件
export default {
  components: {
    renzhengIcon,
    settingPassword
  },
  mixins: [userinfo],
  data() {
    return {
      dialogVisible: false,
      data: {
        name: '',
        phone: '',
        address: ''
      },
      form: {
        name: '',
        phone: '',
        address: ''
      },
      showCompanyList: false,
      companyList: []
    };
  },
  mounted() {
    this.$store.dispatch('user/getAccount');
    // this.getCompanyAddress();
    // this.getAccountColleagueList()
  },
  methods: {
    settingPasswordClick(){
      this.$refs.settingPassword.show()
    },
    toAccountAuth() {
      this.$store.dispatch('user/toAccountAuth')
    },
    toAccountCompanyAuth() {
      this.$store.dispatch('user/toAccountCompanyAuth')
    },

    openDialog() {
      this.dialogVisible = true;
      this.form = { ...this.data };
    },
    onSuccess(res) {
      console.log('onSuccess', res)
    },
    async putCompanyAddress() {
      const { companyUuid, form } = this
      const { status, info } = await this.$api.putCompanyAddress({ companyUuid, ...form })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.data = { ...form }
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    async onChange(file) {
      const { data } = await this.$api.getFileSts();
      const response = await uploadFile(file.raw, data, 'znsign/avatar');
      if (response.res.status !== 200) {
        this.$message({
          type: 'error',
          message: '上传失败，请联系管理员'
        })
        return
      }
      const avatarRes = await this.$api.postAccountAvatar({ avatar: response.name });
      if (avatarRes.status !== 200) {
        this.$message({
          type: 'error',
          message: avatarRes.info
        })
        return
      }
      this.$message({
        type: 'success',
        message: avatarRes.info
      })
      this.getAccount();
    },
  },
};
</script>
<style lang='scss' scoped>
.personal {
  padding-bottom: 30px;

  .title {
    font-family: "Source Han Sans CN";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .line2 {
    background: linear-gradient(to left,
        transparent 0%,
        transparent 50%,
        #eee 50%,
        #eee 100%);
    background-size: 10px 1px;
    background-repeat: repeat-x;
    height: 1px;
    margin: 0 20px;
  }

  .title {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 56px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #262626;
  }

  .btn {
    width: 84px;
    background: #ffffff;
    /* 字体/999999 */
    border: 1px solid #999999;
    border-radius: 2px;
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #999999;
    text-align: center;
    right: 20px;
    position: absolute;
  }

  .avatar {
    .btn {
      color: #0062ff;
      border-color: #0062ff;
    }
  }

  .item {
    display: flex;
    padding: 0 20px;
    line-height: 44px;
    align-items: center;
    position: relative;

    .btn {
      color: #0062ff;
      border-color: #0062ff;
      cursor: pointer;
    }

    .label {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }

    .value {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
  }

  .name,
  .company {
    .value {
      display: flex;
      align-items: center;

      .error {
        background-image: url(~@/static/image/weirenzheng.png);
        background-size: 100%;
      }

      .success {
        background-image: url(~@/static/image/yirenzheng.png);
        background-size: 100%;
      }

      .icon {
        width: 60px;
        height: 20px;
        margin-left: 6px;
        background-repeat: no-repeat;
        background-size: 60px 20px;
      }
    }
  }
}
</style>