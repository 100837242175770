/*
* @Author: 曹俊杰
* @Date: 2024-09-22 14:55:48
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-11-20 23:02:38
*/余额
<template>
    <div class="box">
        <div class="top">
            <div class="title">账号:{{ user.name }}（{{ user.phone }}）</div>
            <div class="bg">
                <div class="bg-t">我的余额</div>
                <div class="bg-b">
                    <div class="value">{{ balance | priceFormat(2) }}</div>
                    <div class="btn" @click="onclick">点击充值</div>
                </div>
            </div>
        </div>
        <ul class="menu">
            <router-link v-for="item in menuList" class="item pointer" active-class="def-active" tag='li'
                :key="item.name" :to='`${item.name}`'>
                {{ item.title }}
                <div class="line"></div>
            </router-link>
        </ul>
        <chongzhiDialog ref="chongzhiDialog" />
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
</template>

<script>
import chongzhiDialog from "@/components/chongzhiDialog"
import userinfo from "@/mixins/userinfo.js"
export default {
    mixins: [userinfo],
    components: {
        chongzhiDialog
    },
    data() {
        return {
            menuList: [
                {
                    title: '充值记录',
                    name: 'recharge',
                },
                {
                    title: '消费记录',
                    name: 'expense'
                },
            ],
        };
    },
    mounted() {
        const { query
        } = this.$route;
        console.log(this.$route)
        if (query) {
            query.recharge && this.onclick()
        }
        this.$store.dispatch("user/getAccount")
    },
    methods: {
        onclick() {
            this.$refs.chongzhiDialog.show()
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/assets/style/var.scss";

.box {
    flex-direction: column;

    .top {
        background: #fff;
        padding-bottom: 20px;
        border-radius: 6px;

        .title {
            height: 60px;
            color: #262626;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 60px;
            padding-left: 20px;
        }

        .bg {
            border-radius: 4px;
            background: #F3F6F8;
            padding: 20px;
            margin: 0 20px;

            &-t {
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            &-b {
                margin-top: 6px;
                display: flex;
                align-items: flex-end;

                .value {
                    font-family: "DIN Alternate";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .btn {
                    color: #0062FF;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    line-height: 33px;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}

.menu {
    margin-top: 16px;
    background: #fff;
    height: 60px;
    padding: 0 20px;
    flex: 1;
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;

    .item {
        margin-right: 40px;
        position: relative;
    }

    .def-active {
        color: $--color-primary !important;

        .line {
            display: block;
            background: #0062FF;
        }
    }



    .line {
        width: 16px;
        height: 2px;
        border-radius: 8px;

        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: auto;
        display: none;
    }
}
</style>
