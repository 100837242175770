/*
* @Author: 曹俊杰
* @Date: 2024-09-18 18:16:42
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-12-13 21:59:33
* 首页面板
*/
<template>
    <div class="panel">
        <div class="panel-left">
            <Mavatar :size="60" :src="user.avatar"></Mavatar>
            <div class="panel-left-content">
                <div class="username">
                    <p>
                        {{ user.name || '暂无信息' }}
                    </p>
                    <renzheng-icon :value="auth"></renzheng-icon>
                </div>
                <div class="phone">账号：{{ user.phone }}</div>
            </div>
        </div>
        <div class="panel-right">
            <div class="item">
                <div class="item-top">
                    <svg class="gift" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19"
                        fill="none">
                        <path
                            d="M16.1367 8.68135C16.1367 8.31826 15.8419 8.02344 15.4788 8.02344H2.5212C2.1581 8.02344 1.86328 8.31826 1.86328 8.68135V16.0843C1.86328 16.4474 2.1581 16.7422 2.5212 16.7422H15.4788C15.8419 16.7422 16.1367 16.4474 16.1367 16.0843V8.68135Z"
                            fill="#FF545C" />
                        <path
                            d="M16.1367 8.42668C16.1367 8.31975 16.0942 8.21722 16.0186 8.14161C15.9429 8.066 15.8404 8.02349 15.7335 8.02344H11.1962C11.0893 8.02349 10.9867 8.066 10.9111 8.14161C10.8355 8.21722 10.793 8.31975 10.793 8.42668V16.7422H15.7335C15.8404 16.7421 15.9429 16.6996 16.0186 16.624C16.0942 16.5484 16.1367 16.4459 16.1367 16.3389V8.42668Z"
                            fill="#F2044B" />
                        <path
                            d="M9 6.54688C9 6.54688 9.50766 5.32006 9.49219 3.80469C9.47672 2.28931 9.19659 2.25781 8.64844 2.25781H6.04688C6.04688 2.25781 4.85156 2.40934 4.85156 3.875C4.85156 5.34066 5.64525 5.98438 5.97656 6.33594C6.30788 6.6875 9 6.54688 9 6.54688Z"
                            fill="#FECB31" />
                        <path
                            d="M8.51386 6.56505C7.91072 6.10134 7.52344 5.38338 7.52344 4.57809C7.52344 3.56693 8.1341 2.69351 9.01589 2.28809C9.32238 2.37056 9.48066 2.67607 9.49219 3.80466C9.50766 5.32003 9 6.54684 9 6.54684C9 6.54684 8.80516 6.55704 8.51386 6.56505Z"
                            fill="#FF9058" />
                        <path
                            d="M5.65963 5.97252C5.65963 5.97252 5.60402 5.55367 5.35834 5.13869C5.11267 4.7237 5.03048 4.75963 4.87945 4.84696L4.16275 5.26145C4.16275 5.26145 3.85767 5.49362 4.09117 5.89736C4.32461 6.30116 4.64587 6.352 4.7931 6.39608C4.94041 6.44017 5.65963 5.97252 5.65963 5.97252Z"
                            fill="#FECE27" />
                        <path
                            d="M5.52862 6.05501C5.28857 6.0233 5.06751 5.88725 4.93919 5.66541C4.77811 5.38683 4.80714 5.04898 4.98553 4.79677C5.08305 4.77068 5.17537 4.82961 5.35832 5.1387C5.604 5.55368 5.65961 5.97254 5.65961 5.97254C5.65961 5.97254 5.60758 6.00636 5.52862 6.05501Z"
                            fill="#FF9058" />
                        <path
                            d="M9.63281 6.05469C9.63281 6.05469 9.63281 3.10156 10.8281 3.10156H13.2891C13.2891 3.10156 13.9219 3.34175 13.9219 4.29688C13.9219 5.252 12.4453 6.33594 12.4453 6.33594L9.63281 6.05469Z"
                            fill="#FECA34" />
                        <path
                            d="M10.4786 6.13927L9.63281 6.05468C9.63281 6.05468 9.63281 3.29639 10.7118 3.11133C11.1183 3.2509 11.3406 3.56266 11.3906 4.01562C11.4364 4.43032 10.872 5.48297 10.4786 6.13927Z"
                            fill="#FF9058" />
                        <path
                            d="M12.353 5.69687C12.353 5.69687 13.0384 4.64113 13.4657 4.91851L14.3455 5.48966C14.3455 5.48966 14.516 5.7224 14.2943 6.06383C14.0727 6.40534 13.2932 6.45013 13.2932 6.45013L12.353 5.69687Z"
                            fill="#FECE27" />
                        <path
                            d="M12.6358 5.92341L12.353 5.69686C12.353 5.69686 12.9932 4.71073 13.4218 4.89502C13.5348 5.03923 13.5419 5.20229 13.4546 5.37582C13.3747 5.53472 12.9287 5.78004 12.6358 5.92341Z"
                            fill="#FF9058" />
                        <path
                            d="M16.9453 6.41616C16.9452 6.26437 16.8849 6.11882 16.7776 6.01149C16.6702 5.90416 16.5247 5.84382 16.3729 5.84375H1.6271C1.47531 5.84382 1.32976 5.90416 1.22243 6.01149C1.11509 6.11882 1.05476 6.26437 1.05469 6.41616V8.50571C1.05476 8.6575 1.11509 8.80305 1.22243 8.91039C1.32976 9.01772 1.47531 9.07805 1.6271 9.07812H16.3729C16.5247 9.07805 16.6702 9.01772 16.7776 8.91039C16.8849 8.80305 16.9452 8.6575 16.9453 8.50571V6.41616Z"
                            fill="#FF4C77" />
                        <path d="M5.27344 5.84375H7.38281V16.7422H5.27344V5.84375Z" fill="#FFC735" />
                        <path
                            d="M16.9453 6.41616C16.9452 6.26437 16.8849 6.11882 16.7775 6.01149C16.6702 5.90416 16.5247 5.84382 16.3729 5.84375H10.8062V9.07812H16.3729C16.5247 9.07805 16.6702 9.01772 16.7775 8.91039C16.8849 8.80305 16.9452 8.6575 16.9453 8.50571V6.41616Z"
                            fill="#F00048" />
                        <path d="M12.375 5.84375H14.4844V16.7422H12.375V5.84375Z" fill="#FFC735" />
                        <path d="M1.86328 9.07812H16.1367V10.2031H1.86328V9.07812Z" fill="#E30000" fill-opacity="0.4" />
                    </svg>
                    <p>88元超值礼包</p>
                </div>

                <div class="item-bottom pointer" @click="showchaozhilibao" v-if="user.monthBundleCount">
                    <span style="font-size: 20px; color: #FFEE9B;">立即购买</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7" fill="none">
                        <path
                            d="M3.84489 3.14017C4.05171 3.33882 4.0517 3.66087 3.84489 3.85951L0.904034 6.68419C0.697225 6.88283 0.361928 6.88283 0.15511 6.68419C-0.0517074 6.48554 -0.0516989 6.16349 0.15511 5.96485L2.72151 3.49984L0.15511 1.03482C-0.0517077 0.836177 -0.0516991 0.514126 0.15511 0.315487C0.361919 0.116847 0.697216 0.116839 0.904034 0.315487L3.84489 3.14017Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="item-bottom" v-else>
                    <span style="font-size: 20px;opacity: 0.5;">本月已购买</span>
                </div>
            </div>
            <div class="line"></div>
            <div class="item">
                <div class="item-top">
                    <span class="el-icon-ali-shouye-qiyeyue"></span>
                    <p>余额</p>
                </div>
                <div class="item-bottom pointer" @click="tochongzhi">
                    <span class="value">{{ balance | priceFormat(2) }}</span>
                    <span>充值</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7" fill="none">
                        <path
                            d="M3.84489 3.14017C4.05171 3.33882 4.0517 3.66087 3.84489 3.85951L0.904034 6.68419C0.697225 6.88283 0.361928 6.88283 0.15511 6.68419C-0.0517074 6.48554 -0.0516989 6.16349 0.15511 5.96485L2.72151 3.49984L0.15511 1.03482C-0.0517077 0.836177 -0.0516991 0.514126 0.15511 0.315487C0.361919 0.116847 0.697216 0.116839 0.904034 0.315487L3.84489 3.14017Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
            <div class="line"></div>
            <div class="item">
                <div class="item-top">
                    <span class="el-icon-ali-shouye-daijinquan"></span>
                    <p>代金券</p>
                </div>
                <div class="item-bottom pointer" @click="$utils.newopen('/accountCenter/voucher')">
                    <span class="value">{{ VoucherList.length }}</span>
                    <span>查看</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7" fill="none">
                        <path
                            d="M3.84489 3.14017C4.05171 3.33882 4.0517 3.66087 3.84489 3.85951L0.904034 6.68419C0.697225 6.88283 0.361928 6.88283 0.15511 6.68419C-0.0517074 6.48554 -0.0516989 6.16349 0.15511 5.96485L2.72151 3.49984L0.15511 1.03482C-0.0517077 0.836177 -0.0516991 0.514126 0.15511 0.315487C0.361919 0.116847 0.697216 0.116839 0.904034 0.315487L3.84489 3.14017Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
            <div class="line"></div>
            <div class="item">
                <div class="item-top">
                    <span class="el-icon-ali-shouye-tuiguangshouyi"></span>
                    <p>推广收益</p>
                </div>
                <div class="item-bottom pointer" @click="$utils.newopen('/accountCenter/promotion')">
                    <span class="value">{{ user.reward | priceFormat(2) }}</span>
                    <span>提现</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7" fill="none">
                        <path
                            d="M3.84489 3.14017C4.05171 3.33882 4.0517 3.66087 3.84489 3.85951L0.904034 6.68419C0.697225 6.88283 0.361928 6.88283 0.15511 6.68419C-0.0517074 6.48554 -0.0516989 6.16349 0.15511 5.96485L2.72151 3.49984L0.15511 1.03482C-0.0517077 0.836177 -0.0516991 0.514126 0.15511 0.315487C0.361919 0.116847 0.697216 0.116839 0.904034 0.315487L3.84489 3.14017Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
            <chaozhilibao ref="chaozhilibao" />
        </div>

    </div>
</template>

<script>
import userinfo from "@/mixins/userinfo.js"
import renzhengIcon from "@/components/renzhengIcon.vue"
import chaozhilibao from "./components/chaozhilibao.vue"
export default {
    components: {
        renzhengIcon,
        chaozhilibao
    },
    mixins: [userinfo],
    data() {
        return {
            VoucherList: []
        }
    },
    created() {
        this.getVoucherList()
    },
    methods: {
        showchaozhilibao() {
            this.$refs.chaozhilibao.show()
        },
        async getVoucherList() {
            const { status, data } = await this.$api.getVoucherList({ state: 0 ,size:999});
            if (status === 200) {
                this.VoucherList = data.records;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.panel {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
        display: flex;
        align-items: center;

        &-content {
            margin-left: 20px;

            .username {
                font-weight: 400;
                font-size: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-style: normal;
            }

            .phone {
                opacity: 0.5;
                color: #fff;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }

    &-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .item {
            height: 65px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            gap: 10px;
            color: #fff;

            &-top {
                display: flex;
                align-items: center;
                gap: 6px;
                font-size: 14px;
                font-style: normal;
                font-weight: 350;
                line-height: normal;

                span {
                    font-size: 14px;
                }
            }

            &-bottom {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                font-size: 12px;
                font-style: normal;
                font-weight: 350;
                line-height: normal;

                .value {
                    font-size: 28px;
                }
            }

            @keyframes rotate {

                0%,
                80% {
                    transform: rotate(0deg);
                    /* 保持静止 */
                }

                85% {
                    transform: rotate(-10deg);
                    /* 向左旋转 */
                }

                90% {
                    transform: rotate(10deg);
                    /* 向右旋转 */
                }

                95% {
                    transform: rotate(-10deg);
                    /* 向左旋转 */
                }

                100% {
                    transform: rotate(10deg);
                    /* 向右旋转 */
                }
            }

            .gift {
                animation: rotate 5s infinite;
            }
        }

        .line {
            width: 1px;
            height: 26px;
            opacity: 0.5;
            background: #FFF;
            margin: 0 50px;

        }
    }
}
</style>
