/*
* @Author: 曹俊杰
* @Date: 2024-10-25 23:14:21
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-12-12 21:49:27
*/
<template>
    <div class="byAdvisory">
        <div class="aliform" v-html="aliform" v-show="false"></div>
        <el-dialog :visible.sync="dialogVisible" :show-close="false" width="960px">
            <div class="box1">
                <div class="box1-l">
                    <div class="box1-l-title">
                        <img src="@/static/image/home/home_bg_6.png" alt="">
                        <p>咨询VIP购买</p>
                    </div>
                    <div class="box1-l-content">
                        <div class="box1-l-content-1">
                            <div class="box1-l-content-1-l">选择服务套餐：</div>
                            <div class="box1-l-content-1-r">{{ packageItem ? `咨询VIP（${packageItem.duration}个月）` :
                                '未选择套餐' }}
                            </div>
                        </div>
                        <div class="box1-l-content-2">
                            <div class="box1-l-content-2-l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M1 7.1252V3H17V7.1252C16.1526 7.4272 15.5454 8.24212 15.5454 9.2C15.5454 10.1579 16.1526 10.9728 17 11.2748V15.4H1V11.2748C1.84741 10.9728 2.45454 10.1579 2.45454 9.2C2.45454 8.24212 1.84741 7.4272 1 7.1252Z"
                                        fill="url(#paint0_linear_183_3227)" />
                                    <path d="M7.125 6L9 7.875L10.875 6" stroke="#F4E5DF" stroke-width="1.2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.75 8.25H11.25" stroke="#F4E5DF" stroke-width="1.2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.75 10.5625H11.25" stroke="#F4E5DF" stroke-width="1.2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 8.25V12.75" stroke="#F4E5DF" stroke-width="1.2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <defs>
                                        <linearGradient id="paint0_linear_183_3227" x1="-2.47018" y1="-0.194928"
                                            x2="23.2323" y2="11.6502" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#F07B41" />
                                            <stop offset="1" stop-color="#EB412A" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <p>我的代金券</p>
                            </div>
                            <div class="box1-l-content-2-r pointer">
                                <div v-if="VoucherList.length == 0">暂无可用</div>
                                <div v-else @click="showBox2Click">
                                    <p v-if="voucherItem">-¥{{ voucherItem.amount | priceFormat }}</p>
                                    <p v-else>{{ VoucherList.length }}张 代金劵</p>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8"
                                    fill="none">
                                    <path
                                        d="M0.714286 -3.12224e-08L2.00478e-08 0.666667L3.57143 4L-2.91409e-08 7.33333L0.714286 8L5 4L0.714286 -3.12224e-08Z"
                                        fill="url(#paint0_linear_183_3225)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_183_3225" x1="0.125" y1="7.75" x2="5"
                                            y2="-6.57494e-07" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#F07B41" />
                                            <stop offset="1" stop-color="#EB412A" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div class="box1-l-content-3">
                            <div class="box1-l-content-3-item pointer"
                                :class="{ acitve: item.packageId == params.packageId }" v-for="item in AdvisoryPackage"
                                :key="item.packgeId" @click="AdvisoryPackageClick(item)">
                                <div class="box1-l-content-3-item-1">{{ item.duration }}个月</div>
                                <div class="box1-l-content-3-item-2">
                                    <p>¥</p>
                                    <div>{{ item.price }}</div>
                                </div>
                                <div class="box1-l-content-3-item-3">¥{{ item.originalPrice }}元</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="box1-solid"></div>
                <div class="box1-r">
                    <div class="box1-r-line1">
                        <div class="box1-r-line1-l">实付金额</div>
                        <div class="box1-r-line1-r">
                            <p>¥</p>
                            <div>{{ paymentAmount }}</div>
                        </div>
                    </div>

                    <div class="box1-r-line2">
                        <div class="box1-r-line2-l">{{ packageItem ? `咨询VIP（${packageItem.duration}个月）` :
                            '未选择套餐' }}：</div>
                        <div class="box1-r-line2-r">
                            {{ packageItem ? packageItem.price : '0.00' }}
                        </div>
                    </div>
                    <div class="box1-r-line3">
                        <div class="box1-r-line3-l">代金劵</div>
                        <div class="box1-r-line3-r">
                            <span v-if="voucherItem">
                                -¥{{ voucherItem.amount | priceFormat }}
                            </span>
                            <span v-else>0.00</span>

                        </div>
                    </div>
                    <qiye v-model="qiye" v-if="!companyAuth" />
                    <div class="box1-r-line4">支付方式</div>


                    <paymentMethod v-model="params.paymentMethod" :isbalance="isbalance"/>


                   
                    <div class="box1-subbutton" @click="subbuttonClick">确认购买</div>
                </div>

            </div>

        </el-dialog>
        <el-dialog :visible.sync="dialogVisible1" :show-close="false">
            <div class="box2">
                <div class="box2-title">
                    <p>我的代金券（{{ VoucherList.length }}）</p>
                </div>
                <div class="box2-content">
                    <div class="box2-item pointer" v-for="item in VoucherList" :key="item.voucherId"
                        @click="voucherClick(item)">
                        <div class="box2-item-t">
                            <div class="box2-item-t-l">
                                <div>{{ item.amount }}</div>
                                <p class="icon">元</p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60"
                                fill="none">
                                <path opacity="0.5" d="M1 0V60" stroke="#A85E2E" stroke-dasharray="5 5" />
                            </svg>
                            <div class="box2-item-t-r ">
                                <div>法律咨询-代金券</div>
                                <div v-if="item.voucherId === params.voucherId">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#A85E2E" />
                                        <rect x="4" y="4" width="8" height="8" rx="4"
                                            fill="url(#paint0_linear_189_397)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_189_397" x1="4.26667" y1="8" x2="12"
                                                y2="8" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#BC6416" />
                                                <stop offset="1" stop-color="#914F1A" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <rect opacity="0.5" x="0.5" y="0.5" width="13" height="13" rx="6.5"
                                            stroke="#A85E2E" />
                                    </svg>
                                </div>

                            </div>
                        </div>
                        <div class="box2-item-c"></div>
                        <div class="box2-item-b">
                            <div class="box2-item-b-l">有效期至：</div>
                            <div class="box2-item-b-r">{{ item.validUntil | timeFormat('yyyy-mm-dd') }}</div>
                        </div>
                    </div>
                </div>
                <div class="box2-footer">
                    <div class="box2-footer-btn" @click="box2BtnClick">确认</div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible2" :show-close="false">
            <div class="box3">
                <div class="box3-1">恭喜您开通成功！</div>
                <div class="box3-2">1 对 1 在线法律咨询功能，具备专业性、即时性、便捷性等优势<br />
                    为您提供针对性的法律解答和服务</div>
                <div class="box3-3" @click="kefu">立即咨询</div>
            </div>
        </el-dialog>
    </div>

</template>

<script>
import userinfo from "@/mixins/userinfo.js"
import qiye from "./qiye.vue"
import paymentMethod from "@/components/paymentMethod/paymentMethod"
export default {
    components: {
        qiye,
        paymentMethod
    },
    mixins: [userinfo],
    data() {
        return {
            dialogVisible: false,
            dialogVisible1: false,
            dialogVisible2: false,
            AdvisoryPackage: [],  // 套餐列表
            VoucherList: [],  //代金券列表
            params: {
                packageId: null,
                voucherId: null,
                paymentMethod: null
            },
            aliform: '',
            qiye: false
        }
    },
    watch: {
        "qiye": {
            async handler() {
                // this.params.packageId = null;
                const index = this.AdvisoryPackage.findIndex(res => res.packageId == this.params.packageId)
                await this.getAdvisoryPackage();
                if (index !== -1) {
                    this.params.packageId = this.AdvisoryPackage[index].packageId
                }
            },
            immediate: true,
        }
    },
    computed: {
        packageItem() {   //选中的套餐
            const { packageId } = this.params;
            return this.AdvisoryPackage.find(r => r.packageId == packageId)
        },
        voucherItem() {  //  选中的代金券
            const { voucherId } = this.params;
            return this.VoucherList.find(r => r.voucherId == voucherId)
        },
        paymentAmount() {   //支付金额
            const { packageItem, voucherItem } = this
            if (packageItem) {   //如果选中套餐  就输出套餐金额
                if (voucherItem) {  // 如果选中代金券  就输出套餐 减去代金券金额
                    const price = packageItem.price - voucherItem.amount
                    return price < 0 ? 0 : price     //如果小于0  就输出0
                } else {

                    return packageItem.price
                }
            } else {
                return 0
            }
        },
        isbalance() {  //  余额是否够
            return (this.balance - this.paymentAmount) >= 0
        },
        //  咨询服务的版本
        edition() {
            if (this.companyAuth) {
                return 1
            }
            if (this.qiye) {
                return 1
            }
            return 0
        }
    },
    created() {
        // this.getAdvisoryPackage()
        this.getVoucherList()
    },
    methods: {
        clearData() {
            this.params = {
                packageId: null,
                voucherId: null,
                paymentMethod: null
            }
        },
        show() {
            this.dialogVisible = true
        },
        //套餐列表   版本: 0-个人版, 1-企业版
        async getAdvisoryPackage() {
            const { status, data } = await this.$api.getAdvisoryPackage({ edition: this.edition });
            if (status === 200) {
                this.AdvisoryPackage = data
            }
        },
        handleClose() { },
        //套餐点击
        AdvisoryPackageClick(item) {
            const { packageId } = item;
            if (this.params.packageId == packageId) {
                this.params.packageId = null;
            } else {
                this.params.packageId = packageId;

            }
        },
        // 代金券点击
        voucherClick(item) {
            const { voucherId } = item;
            if (this.params.voucherId == voucherId) {
                this.params.voucherId = null;
            } else {
                this.params.voucherId = voucherId;
            }
        },
        // 代金券确认
        box2BtnClick() {
            this.dialogVisible1 = false
        },
        //显示代金券选择菜单
        showBox2Click() {
            this.dialogVisible1 = true
        },
        async getVoucherList() {
            const { status, data } = await this.$api.getVoucherList({ type: 1, state: 0 });
            if (status === 200) {
                this.VoucherList = data.records;
            }
        },
        // //余额那一行的点击事件
        // yuelineclick() {
        //     //  如果余额够用  选中余额支付   否则跳转充值页
        //     if (this.isbalance) {
        //         this.params.paymentMethod = 1
        //     } else {
        //         this.tochongzhi();
        //         this.dialogVisible = false;
        //     }

        // },
        // // 支付宝那一行的点击事件
        // zhifubaolineClick() {
        //     this.params.paymentMethod = 2
        // },
        //  确认购买
        async subbuttonClick() {
            const { packageId, voucherId, paymentMethod } = this.params;
            if (!packageId) {
                this.$message({ type: 'error', message: '请选择充值套餐！' })
                return
            }
            if (!paymentMethod) {
                this.$message({ type: 'error', message: '请选择支付方式！' })
                return
            }
            if ((paymentMethod == 1) && (!this.isbalance)) {
                this.$message({ type: 'error', message: '余额不够请充值！' })
                return
            }
            if (this.qiye) {
                this.$store.dispatch('user/toAccountCompanyAuth')
                return
            }
            if (paymentMethod == 1) {   //余额购买 弹出确认弹窗。。。。
                try {
                    await this.$confirm('是否使用余额购买?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })

                } catch (error) {
                    return
                }
            }

            this.postAdvisory()
        },
        // 请求购买咨询
        async postAdvisory() {
            const { paymentMethod } = this.params
            const { status, info, data } = await this.$api.postAdvisory(this.params);
            if (status === 200) {
                if (paymentMethod == 1) {
                    this.dialogVisible = false;
                    this.dialogVisible2 = true;
                } else if (paymentMethod == 2) {
                    this.aliform = data;
                    this.$nextTick(() => {
                        document.forms[0].submit();  //重点--这个才是跳转页面的核心,获取第一个表单并提交
                    });
                }
                this.$emit('success')
                this.clearData()
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
                this.$emit('success')
            }
        },
        //开始咨询
        kefu() {
            this.dialogVisible2 = false
            this.$im.open()
        }
    }
}
</script>

<style lang="scss" scoped>
.byAdvisory {
    &/deep/.el-dialog {
        background: transparent;
        box-shadow: none;

        &__header {
            padding: 0;
        }

        &__body {
            padding: 0;
        }
    }

    .box1 {
        width: 960px;
        display: flex;
        border-radius: 6px;
        overflow: hidden;

        &-l {
            width: 640px;
            background: linear-gradient(180deg, #FFEFCE 0%, #FFF 100%);

            &-title {
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(180deg, #FFF2D8 14.29%, #FFE2AC 100%);

                img {
                    width: 34px;
                    height: 28px;
                }

                p {
                    background: linear-gradient(90deg, #582E12 0%, #713915 98.58%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-family: "Source Han Sans CN";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 60px;
                }
            }

            &-content {
                padding: 0 20px;


                &-1 {
                    display: flex;
                    height: 60px;
                    align-items: center;
                    justify-content: space-between;

                    &-l {
                        color: #262626;
                        font-family: "Source Han Sans CN";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    &-r {
                        font-family: "Source Han Sans CN";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        background: linear-gradient(90deg, #582E12 0%, #713915 98.58%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                &-2 {
                    display: flex;
                    height: 38px;
                    flex-shrink: 0;
                    border-radius: 4px;
                    border: 1px solid rgba(235, 65, 42, 0.20);
                    background: linear-gradient(111deg, rgba(240, 123, 65, 0.06) 2.95%, rgba(235, 65, 42, 0.06) 100%);
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;

                    &-l {
                        color: #333;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        display: flex;
                        align-items: center;

                        p {
                            margin-left: 6px;
                        }
                    }

                    &-r {
                        color: #FF0D0D;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        display: flex;
                        align-items: center;

                        p {
                            margin-right: 6px;
                        }
                    }
                }

                &-3 {
                    padding-bottom: 20px;
                    display: flex;
                    flex-wrap: wrap;

                    &-item:nth-child(2n) {
                        margin-right: 0px;
                    }

                    &-item {
                        width: calc((100% - 20px) / 2);
                        margin-right: 20px;
                        height: 146px;
                        flex-shrink: 0;
                        border-radius: 4px;
                        border: 2px solid #EACA99;
                        background: #FEFAEF;
                        margin-top: 16px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        &-1 {
                            color: #6E3714;
                            font-family: "Source Han Sans CN";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        &-2 {
                            margin-top: 6px;
                            display: flex;
                            font-family: "DIN Alternate";
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            align-items: flex-end;
                            background: linear-gradient(90deg, #582E12 0%, #713915 98.58%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;

                            p {
                                color: #6E3714;
                                font-family: "Source Han Sans CN";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                margin-bottom: 4px;
                            }
                        }

                        &-3 {
                            color: #6E3714;
                            font-family: "Source Han Sans CN";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-decoration: line-through;
                            margin-top: 8px;
                        }
                    }

                    .acitve {
                        border-radius: 4px;
                        border: 2px solid #D9A367;
                        background: linear-gradient(104deg, #FEF9EE 0%, #F6DCB6 100%);

                        .box1-l-content-3-item-2 {
                            background: linear-gradient(180deg, #C35632 0%, #983F20 100%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        .box1-l-content-3-item-3 {
                            color: #B9723D;
                            font-family: "Source Han Sans CN";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-decoration-line: strikethrough;
                        }
                    }
                }
            }
        }

        &-solid {
            width: 1px;
            // height: 1000px;
            // flex-shrink: 0;
            background: #EACA99;
        }

        &-r {
            width: 320px;
            background: linear-gradient(180deg, #FFEFCE 0%, #FFF 100%);
            padding: 40px 20px 20px;

            &-line1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 64px;
                border-bottom: 1px dashed #EACA99;

                // background: #f66;
                &-l {
                    color: #262626;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                &-r {
                    display: flex;
                    color: #FF0D0D;
                    font-family: "DIN Alternate";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    align-items: flex-end;

                    p {
                        color: #FF0D0D;
                        font-family: "Source Han Sans CN";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 4px;
                        margin-right: 4px;
                    }
                }
            }

            &-line2 {
                height: 50px;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                align-items: center;

                &-l {
                    color: #666;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                &-r {
                    color: #262626;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            &-line3 {
                height: 50px;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                align-items: center;

                &-l {
                    color: #666;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                &-r {
                    color: #FF0D0D;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            &-line4 {
                height: 50px;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                align-items: center;
                margin-top: 20px;
            }

            &-line5 {
                width: 280px;
                height: 44px;
                flex-shrink: 0;
                border-radius: 4px;
                background: #FFF;
                display: flex;
                align-items: center;
                padding: 0 16px;
                justify-content: space-between;

                &-l {
                    display: flex;
                    align-items: center;
                    color: #262626;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-r {
                    color: #0062FF;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                &-r {
                    .box1-r-icon1 {
                        display: none;
                    }

                    .box1-r-icon2 {
                        display: block;
                    }
                }
            }

            &-line6 {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;
                width: 280px;
                height: 44px;
                flex-shrink: 0;
                border-radius: 4px;

                background: #FFF;

                &-l {
                    display: flex;
                    align-items: center;

                    p {
                        margin-left: 6px;
                        color: #262626;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                &-r {
                    .box1-r-icon1 {
                        display: none;
                    }

                    .box1-r-icon2 {
                        display: block;
                    }
                }


            }

            &-active {
                border: 1px solid #0062FF;

                .box1-r-icon2 {
                    display: none;
                }

                .box1-r-icon1 {
                    display: block;
                }
            }
        }

        &-subbutton {
            cursor: pointer;
            margin: 0 auto;
            margin-top: 60px;
            border-radius: 4px;
            width: 266px;
            height: 40px;
            flex-shrink: 0;
            color: #FFF;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
            background: linear-gradient(180deg, #C35632 0%, #983F20 100%);
        }

    }

    .box2 {
        width: 450px;
        background: linear-gradient(180deg, #FFEFCE 0%, #FFF 100%);
        margin: 0 auto;
        border-radius: 6px;
        overflow: hidden;

        &-title {
            height: 70px;
            background: linear-gradient(180deg, #FFF2D8 14.29%, #FFE2AC 100%);
            font-family: "Source Han Sans CN";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 70px;
            background: linear-gradient(90deg, #BC6416 3.33%, #914F1A 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            overflow: hidden;

            p {

                margin-top: -5px;
            }
        }

        &-content {
            margin-top: 35px;
            height: 325px;
            overflow-y: auto;
        }

        &-item {
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            width: 390px;
            height: 120px;
            margin-bottom: 20px;

            &-t {
                width: 390px;
                height: 88px;
                background: linear-gradient(90deg, #FEEACD 0%, #FBD19C 100%);
                display: flex;
                align-items: center;

                &-l {
                    width: 147px;
                    color: #A85E2E;
                    font-family: "DIN Alternate";
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        color: #A85E2E;
                        font-family: "Source Han Sans CN";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-top: 16px;
                        margin-left: 6px;
                    }
                }

                &-r {
                    flex: 1;
                    display: flex;
                    padding: 0 30px;
                    align-items: center;
                    justify-content: space-between;
                    font-family: "Source Han Sans CN";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(90deg, #BC6416 3.33%, #914F1A 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            &-c {
                width: 390px;
                height: 1px;
                flex-shrink: 0;
                background: linear-gradient(90deg, #FFFAF1 0%, #FED993 100%);
            }

            &-b {
                width: 390px;
                height: 32px;
                flex-shrink: 0;
                background: linear-gradient(90deg, #F6D8AA 0%, #F4C283 100%);
                display: flex;
                padding: 0 30px;
                align-items: center;
                justify-content: space-between;
                color: #93502B;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        &-footer {
            width: 450px;
            height: 80px;
            flex-shrink: 0;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            &-btn {
                width: 144px;
                height: 40px;
                flex-shrink: 0;
                border-radius: 100px;
                background: linear-gradient(90deg, #FEEACD 0%, #FBD19C 100%);
                color: #A85E2E;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .box3 {
        width: 450px;
        height: 376px;
        background-image: url(~@/static/image/accountCenter/img1.png);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 30px;

        &-1 {
            margin-top: 100px;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: linear-gradient(90deg, #974D1C 0%, #311909 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &-2 {
            color: #333;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
            margin-top: 10px;
        }

        &-3 {
            width: 180px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 2px;
            background: linear-gradient(90deg, #974D1C 0%, #311909 100%);
            margin-top: 20px;
            text-align: center;
            color: #fff;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            /* 171.429% */
        }
    }
}
</style>