export default {
    data() {
        return {
            messageList: []
        }
    },
    computed: {},
    mounted() {
        this.intIm()
    },
    methods: {
        async intIm() {
            await this.$im.intIm();
            window.ysf('onunread', res => {
                const index = this.messageList.findIndex(mess => mess.message == res.message)
                if (index < 0) {
                    this.messageList = [...this.messageList, res]
                    this.$store.dispatch('im/setUnreadNum', res.total)
                    if (res.message) {
                        res.message && this.$notify({
                            message: this.$utils.stripHTMLUsingDOM(res.message),
                            iconClass: 'DEVnotify_iconClass',
                            customClass: 'DEVnotify',
                            title: '中能E问:',
                            position: 'bottom-right',
                            // dangerouslyUseHTMLString: true,
                            duration: 0,
                            onClick: () => {
                                this.$im.open()
                            }
                        });
                    }

                }

            })
        },
        Coze() {
 
        }

    }
}