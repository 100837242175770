/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-19 17:06:23
 * @Module: 查询小号
 */
 <template>
  <div class="search">
    <el-alert style="margin-bottom:10px ;background-color: #f5f9ff;color: #0062ff" type="info" show-icon>
      <p slot="title" class="pointer" @click.stop="beanRulesShow">“收费方式”说明</p>
    </el-alert>

    <div class="newSearch">
      <div class="newSearch-top">
        <el-row :gutter="16">
          <el-col :span="15">
            <div class="newSearch-top-left border">
              <div class="t">
                <span>24小时</span>
                <span style="color:#262626">监控查询</span>
              </div>
              <div class="c">点击查询</div>
              <div class="b">
                <div class="btn pointer" @click="$router.push({name: 'searchForm'})">查询小号</div>
                <div class="b-r pointer" v-show="!show" @click="show=true">
                  <span>展开</span>
                  <i class="el-icon-ali-zhankai"></i>
                </div>
                <div class="b-r pointer" v-show="show" @click="show=false">
                  <span>收起</span>
                  <i class="el-icon-ali-shouqi"></i>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="newSearch-top-right border">
              <div class="newSearch-title">
                <p>查询帮助</p>
              </div>
              <div class="newSearch-top-right-content">
                <!-- <div class="item left">
                  <div class="left-t">
                    <p>我的邀请码</p>
                    <p style="color: #0062FF;" class="pointer" v-clipboard:copy="`我在使用小号查询系统。https://zhq.mcn-open.com/   \n我的邀请码：${inviteCode}  \n快来跟我一起使用吧！`" v-clipboard:success="()=>$message({message:'邀请码复制成功',type: 'success'})" v-clipboard:error="()=>$message({message:'邀请码复制失败,推荐使用chrome浏览器',type: 'error'})">复制</p>
                  </div>
                  <div class="value" v-if="inviteCode">{{inviteCode}} </div>
                  <el-button type="text" v-else @click="loginout">重新登录查看我的邀请码</el-button>
                </div> -->
                <div class="item right">
                  <div class="right-l">
                    <p class="label">
                      <i class="el-icon-ali-kefu"></i>
                      <span>专属客服</span>
                    </p>
                    <p class="text">微信扫一扫添加</p>
                  </div>
                  <div class="Qcode serviceCode pointer" v-popover:popover></div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="info border" v-show="show">
        <div class="newSearch-title">
          <p>查询流程介绍</p>
        </div>
        <div style="padding:0 20px 20px">
          <div class="info-content">
            <div class="img"></div>
          </div>
        </div>

      </div>
    </div>

    <!-- <div class="top">
      <div class="title">
        <p>查询小号</p>
        <div class="right">
          <div class="kefu">
            <i class="el-icon-ali-wenhao-bangzhu" />
          </div>
          <el-button type="primary" style="margin-left:36px;" @click="$router.push({name: 'searchForm'})">发布线索</el-button>
        </div>
      </div>

      <div class="content">
        <div class="content-t">
          <p class="left">企业账户：{{company}}</p>
          <p class="right" v-if="inviteCode">邀请码：{{inviteCode}} <el-divider direction="vertical"></el-divider>
            <span class="pointer primary" v-clipboard:copy="`我在使用小号查询系统。https://zhq.mcn-open.com/   \n我的邀请码：${inviteCode}  \n快来跟我一起使用吧！`" v-clipboard:success="()=>$message({message:'邀请码复制成功',type: 'success'})" v-clipboard:error="()=>$message({message:'邀请码复制失败,推荐使用chrome浏览器',type: 'error'})">
              复制
            </span>
          </p>
          <el-button type="text" v-else @click="loginout">重新登录查看我的邀请码</el-button>
        </div>
        <div class="content-c">
          <i class="gold-icon"></i>
          <span>账户卡券</span>
          <el-divider direction="vertical"></el-divider>
          <span class="pointer primary" @click="$router.push({name: 'topUp',params:{type:'BEAN'}})">前去充值</span>
        </div>
        <div class="content-b">
          {{beans}}
        </div>
      </div>
    </div> -->
    <el-card shadow="never" :body-style="{position:'relative'}" style="margin-top:16px">
      <div slot="header" style="position: relative;">
        <hdq-section title="查询列表" more-text="" />
      </div>

      <filtrate @submit="getSearchList" :searchForm.sync="listParams" />
      <el-table :data="list" size="mini" tooltip-effect="dark" style="width: 100%;margin-top:10px;" @sort-change="sortChange">
        <el-table-column label="ID" prop="outerId">
          <template slot-scope="scope">
            <span>{{scope.row.outerId || "--"}}</span>
          </template>
        </el-table-column>

        <el-table-column label="查询图片" show-overflow-tooltip min-width="100">
          <template slot-scope="scope">
            <el-image style="width: 30px; height: 30px;margin:0 5px" fit="cover" v-for="(item,index) in scope.row.imageList" :src="item" :preview-src-list="scope.row.imageList" :key="index">
              <span slot="error">暂无查询图片</span>
            </el-image>
          </template>
        </el-table-column>

        <el-table-column label="网友投稿" min-width="100">
          <template slot-scope="scope">
            <span class="keep1lines" v-if="scope.row.results.length">
              <el-image class="pointer" style="width: 30px; height: 30px;margin:0 5px" fit="cover" @click="()=>showResultsViewer({full:scope.row.results, index})" v-for="(item,index) in scope.row.results" :src="item.image" :key="index">
                <span slot="error">暂无投稿</span>
              </el-image>
            </span>
            <span v-else>暂无投稿</span>
          </template>
        </el-table-column>
        <el-table-column label="基础信息" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <div>
              <div>
                <span>{{ scope.row.nickname||'--' }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{scope.row.gender==1?'男':'女'}}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ scope.row.age||'--' }}岁</span>
              </div>
              <div>

              </div>
            </div>

          </template>
        </el-table-column>
        <!-- <el-table-column label="性别" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <span >男</span>
            <span >女</span>
            <span v-else>--</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="年龄" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.age||'--' }}</template>
        </el-table-column> -->
        <el-table-column label="城市" width="120">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.cityList.join('、')" placement="top">
              <span class="keep1lines">{{scope.row.cityList.join("、")}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="查询平台" width="120">
          <template slot-scope="scope">
            <!-- <el-tag size="mini" style="margin:5px" v-for="(item,index) in scope.row.platformList" :key="index">{{item}}</el-tag> -->
            <el-tooltip class="item" effect="dark" :content="scope.row.platformList.join('、')" placement="top">
              <span class="keep1lines">{{scope.row.platformList.join("、")}}</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="大号信息" width="180">
          <template slot-scope="scope">
            <!-- <el-tag size="mini" style="margin:5px" v-for="(item,index) in scope.row.platformList" :key="index">{{item}}</el-tag> -->
            <div>
              <!-- <p>
                大号平台： <el-tooltip class="item" effect="dark" :content="scope.row.originPlatformList.join('、')" v-if="scope.row.originPlatformList.length" placement="top">
                  <span class="">{{scope.row.originPlatformList.join("、")}}</span>
                </el-tooltip>
                <span v-else>--</span>
              </p> -->
              <span v-if="scope.row.originId">
                ID：{{scope.row.originId||'--'}}
              </span>
              <el-divider direction="vertical" v-if="scope.row.originId&&scope.row.originPlatform"></el-divider>
              <span v-if="scope.row.originPlatform">{{scope.row.originPlatformList.join("、")}}</span>
              <div v-if="(!scope.row.originId)&&(!scope.row.originPlatform)">--</div>
            </div>

          </template>
        </el-table-column>

        <el-table-column label="加速等级" width="110" sortable="level" prop="level">
          <template slot-scope="scope">
            <span>
              <span v-if="scope.row.level==1">
                <i class="el-icon-ali-Union" :style="scope.row.state==1?'color:#0d72ff;':'color:#ccc;'" style="font-size:12px;margin-right:5px;"></i>
                <span>快速</span>
              </span>
              <span v-else-if="scope.row.level==2">
                <i class="el-icon-ali-Vector" :style="scope.row.state==1?'color:#0d72ff;':'color:#ccc;'" style="font-size:12px;margin-right:5px;"></i>
                <span>高速</span>
              </span>
              <span v-else-if="scope.row.level==3">
                <i class="el-icon-ali-Vecto2r" :style="scope.row.state==1?'color:#0d72ff;':'color:#ccc;'" style="font-size:12px;margin-right:5px;"></i>
                <span>光速</span>
              </span>
              <span v-else>
                <i class="el-icon-ali-dian1" :style="scope.row.state==1?'color:#0d72ff;':'color:#ccc;'" style="font-size:12px;margin-right:5px;"></i>
                <span>默认</span>
              </span>

              <span v-if="scope.row.level<3&&(scope.row.state===1)">
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" size="mini" @click="()=>putSearchSpeedup(scope.row)">升级</el-button>
              </span>
            </span>
          </template>
        </el-table-column>

        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.state==4" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#ccc;"></i>
              <span style="color:#666;">取消</span>
            </div>
            <div v-else-if="scope.row.state==3" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0AC258;"></i>
              <span style="color:#262626;">结束</span>
            </div>
            <div v-else-if="scope.row.state==1" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0d72ff;"></i>
              <span style="color:#262626;">查询中</span>
            </div>
            <div v-else-if="scope.row.state==2" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#FF0D0D;"></i>
              <span style="color:#262626;">创建失败</span>
            </div>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column label="自动续费" prop="autoRenew">
          <template slot-scope="scope">
            <el-switch :value="scope.row.autoRenew" size="mini" @click.native="()=>putSearchAutoRenew(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="查询时间" sortable="createdAt" prop="createdAt" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.createdAt">{{
                scope.row.createdAt | timeFormat
              }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="到期时间" sortable="expiryAt" prop="expiryAt" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.expiryAt">{{
                scope.row.expiryAt | timeFormat
              }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="50" :resizable="false">
          <template slot-scope="scope">
            <el-dropdown @command="operate">
              <span class="el-icon-ali-biaogeweibucaidan_ primary"></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{type:'putSearch',data:scope.row}">取消</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <hdq-pagination small ref="pagination" @change="getSearchList" />
      <searchSpeedupForm ref="searchSpeedupForm" @success="getSearchList" />
      <beanRules ref="beanRules" />
    </el-card>
    <el-popover ref="popover" placement="right" width="200" trigger="hover">
      <div style="width:176px;height:176px;" class="serviceCode "></div>
    </el-popover>
    <inform />
    <resultsViewer ref="resultsViewer" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import hdqPagination from "@/components/hdqPagination.vue";
import inform from "@/components/inform.vue";
import platformList from "@/views/Search/components/platformList.js";
import searchSpeedupForm from "@/views/Search/components/searchSpeedupForm.vue";
import filtrate from "@/views/Search/components/filtrate.vue";
import resultsViewer from "@/views/Search/components/resultsViewer.vue";
import beanRules from "@/views/ExpenseCenter/components/beanRules.vue";
export default {
  components: {
    hdqPagination,
    searchSpeedupForm,
    filtrate,
    beanRules,
    inform,
    resultsViewer
  },
  data () {
    return {
      list: [],
      platformList,
      listParams: {
        nickname: '',
        state: null,
        order: '',
        orderBy: '',
        outerId: null
      },
      show: true
    };
  },
  mounted () {
    this.getSearchList();
    this.$store.dispatch("user/getAccount")
  },
  computed: {
    ...mapState({
      balance: state => state.user.balance,
      company: state => state.user.user.company,
      inviteCode: state => state.user.user.inviteCode,
      beans: state => state.user.beans,
    })
  },
  filters: {
  },
  methods: {
    // 查小号自动续费
    async putSearchAutoRenew ({ autoRenew, uuid: searchUuid }) {
      try {
        if (autoRenew) {
          await this.$confirm(`此操作将取消自动续费, 是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } else {
          await this.$confirm(`<span>开启后，查询时间到期后将默认消耗<span class="primary">当前查询条件</span>卡券再次查询</span>`, '自动续费', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          })
        }

        const { status, info } = await this.$api.putSearchAutoRenew({ searchUuid, autoRenew: !autoRenew })
        if (status === 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getSearchList()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    // 排序
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getSearchList()
    },
    // 显示图片预览
    showResultsViewer (data) {
      this.$refs.resultsViewer.show(data)
    },
    async getSearchList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getSearchList({ current, size, ...listParams })
      if (status === 200) {
        const { records } = data
        this.list = records.map(item => {
          let imageList = item.images ? item.images.split(",") : []
          let cityList = item.citys ? item.citys.split(",") : []
          let platformList = item.platforms ? item.platforms.split(",").map(item => {
            const index = this.platformList.findIndex(itm => itm.value == item);
            return this.platformList[index].label
          }) : []

          let originPlatformList = item.originPlatform ? item.originPlatform.split(",").map(item => {
            const index = this.platformList.findIndex(itm => itm.value == item);
            return this.platformList[index].label
          }) : []

          return {
            ...item,
            imageList,
            cityList,
            platformList,
            originPlatformList
          }
        })
        this.$refs.pagination.createPagination(data)
      }
    },
    async putSearch ({ uuid }) {
      try {
        await this.$confirm(`此操作将取消当前的查询, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.putSearch({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getSearchList()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    putSearchSpeedup (item) {
      console.log(item)
      this.$refs.searchSpeedupForm.show(item)
    },
    operate ({ type, data }) {
      if (type === 'putSearch') {
        this.putSearch(data)
      }

    },
    beanRulesShow () {
      this.$refs.beanRules.show()
    },
    loginout () {
      this.$store.commit("user/logout")
      this.$store.dispatch("userLog/clearUserLog")
    }
  },
  beforeRouteEnter (to, form, next) {
    next(vm => {
      // return false
      const { searchConformed, company, name } = vm.$store.state.user.user;
      //  进入查小号页面判断是否开通 是否企业认证  是否个人认证   否则跳转开通页面
      if (company && name) {
        // if (searchConformed && company && name) {
        return true
      } else {
        vm.$router.push({ name: 'searchConformed' })
      }
    })
  },

};
 </script>
 <style lang='scss' scoped>
.serviceCode {
  background-image: url(~@/static/service.jpg);
  background-size: 100%;
}
.search {
  .icon {
    width: 79px;
    height: 30px;
    background: #f66;
    display: inline-block;
    background-image: url(~@/static/zhuanshu.png);
    margin-top: -5px;
    cursor: pointer;
  }

  .operate {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 20px;
    cursor: pointer;
    span:last-child {
      color: #999;
    }
  }
}
.keep1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.search {
  .top {
    margin-bottom: 14px;
    padding: 10px 24px 20px;
    background: #fff;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      font-family: "PingFang SC";
      font-weight: 500;
      .right {
        display: flex;
        align-items: center;
        .kefu {
          font-size: 14px;
          line-height: 26px;
          color: #0062ff;
        }
      }
    }
    .content {
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      margin-top: 10px;
      padding: 0 16px 10px;
      background-image: url(~@/static/image/search_bg_1.png);
      background-repeat: no-repeat;
      background-position: right bottom;
      &-t {
        font-weight: 400;
        font-size: 16px;
        line-height: 50px;
        color: #999999;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #262626;
        }
      }
      &-c {
        line-height: 40px;
        font-size: 16px;
        color: #262626;
      }
      &-b {
        font-weight: 500;
        font-size: 36px;
        line-height: 50px;
        color: #262626;
      }
    }
  }
}
.gold-icon {
  display: table-cell;
  vertical-align: middle;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url(~@/static/image/gold.png);
  margin-right: 5px;
}
.newSearch {
  &-title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    height: 56px;
    p {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
    }
    span {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #999999;
      cursor: pointer;
    }
  }
  &-top {
    &-left {
      background-image: url(~@/static/image/search_bg_3.png) !important;
      background-size: auto 100% !important;
      background-repeat: no-repeat !important;
      background-position: center right !important;
      height: 166px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 26px;
      .t {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 39px;
        /* identical to box height */

        color: #0062ff;
      }
      .c {
        margin-top: 4px;
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #999999;
      }
      .b {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .btn {
          width: 110px;
          line-height: 40px;
          background: #0062ff;
          border-radius: 4px;
          color: #fff;
          text-align: center;
        }
        &-r {
          margin-left: 16px;
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #262626;
          i {
            color: #0062ff;
            margin-left: 4px;
          }
        }
      }
    }
    &-right {
      height: 166px;
      &-content {
        height: 90px;
        display: flex;
        padding: 0 20px;
        .item {
          background: #f7f8f9;
          border-radius: 4px;
          // width: calc((100% - 16px) / 2);
          width: 100%;
          &:first-child {
            margin-right: 16px;
          }
        }
        .left {
          padding: 18px;
          &-t {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
          }
          .value {
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #262626;
            margin-top: 3px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 18px;
          &-l {
            .label {
              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;

              color: #666666;
              i {
                color: #0062ff;
              }
            }
            .text {
              margin-top: 8px;

              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #262626;
            }
          }
          .Qcode {
            width: 54px;
            height: 54px;
            background-color: #ffffff;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .info {
    margin-top: 16px;
    &-content {
      width: 100%;
      padding-bottom: 9.573%;
      position: relative;
      .img {
        height: 100%;
        width: 100%;
        background-image: url(~@/static/image/search_bg_2.png);
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
.keep1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>