<template>
    <div class="mElectronicFooter">
        <el-button :size="size" @click="back" v-if="!hidBack">上一步</el-button>
        <el-button type="primary" :size="size" @click="next" :disabled="disabledNext">下一步</el-button>
    </div>
</template>

<script>
export default {
    props:{
        hidBack:{
            type:Boolean,
            default:false
        },
        disabledNext:{
            type:Boolean,
            default:false
        }
    },
    components: {},
    data() {
        return {
            size: 'small'
        };
    },
    created() { },
    computed: {},
    methods: {
        next() {
            this.$emit('next')
        },
        back() {
            this.$router.go(-1)
            this.$emit('back')
        },

    },
};
</script>

<style scoped lang='scss'>
.mElectronicFooter {
    background: #fff;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
</style>