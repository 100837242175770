
import { mapState, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapState({
            user: state => state.user.user,
            balance: state => state.user.balance,
        }),
        ...mapGetters('user', ['companyAuth', 'companyObj', 'auth']),
        serviceState() {
            var tmp = Date.parse(new Date()).toString().substring(0, 10)
            // 0未开通  1 服务中  已过期
            if (this.user.serviceUntil === null) {
                return {
                    key: 0,
                    value: '未开通'
                }
            } else if (this.user.serviceUntil > tmp) {
                return {
                    key: 1,
                    value: '服务中'
                }
            } else {
                return {
                    key: 2,
                    value: '已过期'
                }
            }
        }
    },
    mounted() {
        this.getAccount();
    },
    methods:{
        tochongzhi(){ // 跳转充值
            this.$utils.newopen('/accountCenter/balance/recharge?recharge=1')
            // this.$router.push({
            //     name:'recharge',
            //     params: {
            //         type:'chongzhi'
            //     }

            // })
        },
        getAccount(){
            this.$store.dispatch('user/getAccount')
        }
    }
}

