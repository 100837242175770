import api from '@/utils/api'
const state = {
	electronicData: {
		subject: '',
		files: [],
		signers: [],
		file: '',
		save: false,
		insuranceId: null,
		insuranceVersion: null,
		title: ''
	},
	enterpriseSearch: [],   // 企业查询
	personalSearch: [],// 个人查询
	contractProfile: {
		all: '',//合同总数
		finishedCount: '',//完成数量
		inProgressCount: '',//签署中数量
		nearDeadlineCount: '',//即将到期数量
		refusedCount: '',//拒签数量
		unarchivedCount: '',//未归档数量
		unprotectedCount: '',//未保障数量
		protectedCount: '',//保障中数量
		expiredCount: '',//已到期数量
		insuranceInProcessCount: '',//拒签数量
		terminatedCount: '',//已完结数量
	},
	fangqibaozhang: false,
	//强制排查表单数据
	qiangzhipaichaData: {
		type: null,     // 审理机关类型
		cityId: 0,  //强制执行地
		province: null,
		city: null
	},
	qiangzhipaichaCallback: [],
	// 合同保表单数据
	hetongbaoData: {
		insuranceId: null,
		title: '',
		insuranceVersion: null,
		grade: 1,
		price: null,
		period: null,
		version: null
	},
	fxpcdata: {
		detectionId: '',
		content: '',
		name: '',
		idNumber: '',
		phone: '',
		createdAt: '',
		exposureList: null,
		contractList: null,
		verification: null,
	},
	signerList: []
}
const mutations = {
	reset(state) {
		state.electronicData = {
			subject: '',
			files: [],
			signers: [],
			file: '',
			save: false,
			insuranceId: null,
			insuranceVersion: null,
			title: ''
		}
		state.contractProfile = {
			all: '',//合同总数
			finishedCount: '',//完成数量
			inProgressCount: '',//签署中数量
			nearDeadlineCount: '',//即将到期数量
			refusedCount: '',//拒签数量
			unarchivedCount: '',//未归档数量
			unprotectedCount: '',//未保障数量
			protectedCount: '',//保障中数量
			expiredCount: '',//已到期数量
			insuranceInProcessCount: '',//拒签数量
			terminatedCount: '',//已完结数量
		}
		state.fangqibaozhang = false;
		state.qiangzhipaichaData = {
			type: null,     // 审理机关类型
			cityId: 0,  //强制执行地
			province: null,
			city: null
		}
		state.qiangzhipaichaCallback = [];
		state.fxpcdata = {
			detectionId: '',
			content: '',
			name: '',
			idNumber: '',
			phone: '',
			createdAt: '',
			exposureList: null,
			contractList: null,
			verification: null,
		}
		state.hetongbaoData = {
			insuranceId: null,
			title: '',
			insuranceVersion: null,
			grade: 1,
			price: null,
			period: null,
			version: null
		}
		state.signerList = []


	},
	setElectronic(state, payload) {
		state.electronicData = {
			...state.electronicData,
			...payload
		}
	},
	setContractProfile(state, payload) {
		state.contractProfile = {
			...state.contractProfile,
			...payload
		}
	},
	setqiangzhipaichaData(state, payload) {
		console.log(321321, payload)
		state.qiangzhipaichaData = {
			...state.qiangzhipaichaData,
			...payload
		}
	},
	setqiangzhipaichaCallback(state, payload) {
		state.qiangzhipaichaCallback = payload
	},
	setfxpcData(state, payload) {
		state.fxpcdata = payload
	},
	sethetongbaoData(state, payload) {
		console.log(payload)
		state.hetongbaoData = { ...state.hetongbaoData, ...payload }
	},
	resethetongbao(state) {
		state.hetongbaoData = {
			insuranceId: null,
			title: '',
			insuranceVersion: null,
			grade: 1,
			price: null,
			period: null,
			version: null
		}
		state.electronicData = {
			...state.electronicData,
			insuranceId: null,
			insuranceVersion: null,
			title: ''
		}
	},
	// 保存历史记录
	saveHistory(state, payload) {
		payload.forEach(item => {
			if (item.type === 'ORG') {
				if (state.enterpriseSearch.findIndex(itm => (itm.operatorIdNumber == item.operatorIdNumber) && (itm.idNumber == item.idNumber)) < 0)
					state.enterpriseSearch.push({
						...item
					})
			} else if (item.type === 'PERSON') {
				if (state.personalSearch.findIndex(itm => itm.operatorIdNumber == item.operatorIdNumber) < 0)
					state.personalSearch.push({
						...item
					})
			}
		});

	},
	fangqibaozhangFn(state, payload) {
		state.fangqibaozhang = payload
	},
	setSignerList(state, payload) {
		state.signerList = payload
	}
}
const actions = {
	async getContractProfile({ commit, state }) {     //合同统计
		const { status, data } = await api.getContractProfile()
		if (status === 200) {
			commit('setContractProfile', data);
		}
	},
	reset({ commit }) {
		commit('reset')
		// commit('insuranceSelect/insuranceSelectClear');
	}
}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}