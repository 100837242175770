import { render, staticRenderFns } from "./Balance.vue?vue&type=template&id=59f74028&scoped=true"
import script from "./Balance.vue?vue&type=script&lang=js"
export * from "./Balance.vue?vue&type=script&lang=js"
import style0 from "./Balance.vue?vue&type=style&index=0&id=59f74028&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59f74028",
  null
  
)

export default component.exports