/*
* @Author: 曹俊杰
* @Date: 2022-02-22 18:40:32
* @Module: 出证
*/
<template>
  <div class="accurateEvidence">
    <div class="left">
      <div class="menu">
        <router-link class="item1 item" active-class="active" v-for="item in menuList" tag='div' :key="item.name"
          :to='`/accurateEvidence/${item.name}`' replace>
          <i class="iconfont" :class="item.icon"></i>
          <span>{{ item.title }}</span>
        </router-link>
      </div>
    </div>
    <div class="right">
      <router-view />
    </div>
  </div>
</template>
<script>

export default {
  components: {

  },
  data() {
    return {
      menuList: [
        {
          title: '签署出证',
          name: 'extension',
          icon: 'el-icon-ali-qianshuchuzheng'
        },
        {
          title: '出证记录',
          name: 'record',
          icon: 'el-icon-ali-chuzhengjilu'
        }
      ]
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang='scss' scoped>
.accurateEvidence {
  display: flex;
  padding-top: 16px;

  .left {
    width: 208px;
    padding-left: 24px;
    padding-right: 16px;

    .menu {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;

      .item {
        width: 168px;
        height: 40px;
        top: 48px;

        border-radius: 4px 0px 0px 0px;

        display: flex;
        padding: 0 20px;
        margin-bottom: 8px;
        align-items: center;
        cursor: pointer;

        i {
          margin-right: 8px;
        }
      }

      .item2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .item3 {
        padding: 0 30px;
      }

      .active {
        background: #fff;
        border-radius: 4px;
        color: #0062FF;
      }

      .fold {
        //折叠
        height: 0px;
        overflow: hidden;
      }
    }
  }

  .right {
    flex: 1;
    padding-right: 20px;

  }
}
</style>