<template>
    <div class="box" @click="toAccountAuth">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3.5 12H20.5H3.5Z" fill="#999999" />
            <path d="M3.5 12H20.5" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 3.5V20.5V3.5Z" fill="#999999" />
            <path d="M12 3.5V20.5" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p>点击上传</p>
        <el-button type="primary" style="width: 80%;">发起签署</el-button>
    </div>
</template>

<script>
import userinfo from "@/mixins/userinfo.js"
export default {
    mixins: [userinfo],
    methods: {
        async toAccountAuth() {
            if (!this.companyAuth) {
                await this.$confirm('请先完成企业实名认证', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.$store.dispatch('user/toAccountCompanyAuth')
                return
            }
            this.$utils.newopen('/electronicDocuments/electronicDocumentsAdd')
        }
    }

}
</script>

<style lang="scss" scoped>
.box {
    border-radius: 4px;
    border: 1px dashed #0062FF;
    background: rgba(255, 255, 255, 0.80);
    height: 160px;
    backdrop-filter: blur(25px);
    color: #666;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        padding-top: 6px;
        padding-bottom: 20px;
    }
}
</style>