/*
* @Author: 曹俊杰
* @Date: 2022-05-10 18:47:09
* @Module: 三要素查询 风险排查
*/
<template>
  <div class="inspiectSearch">
    <div class="aliform" v-html="aliform" id="alipay_submit" v-show="false"></div>
    <el-form :model="form" ref="form" :rules="rules">
      <div class="box">
        <div class="title">风险排查</div>
        <div class="row">
          <div class="row-left">选择排查内容：</div>
          <div class="row-right">
            <el-form-item>
              <verification :formdata="form" @change="change" />
            </el-form-item>
          </div>
        </div>
        <div class="row" style="margin-top: 40px">
          <div class="row-left" style="line-height: 36px">查询信息：</div>
          <div class="row-right">
            <div class="form">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item prop="name" label="姓名">
                    <el-input v-model="form.name" placeholder="请输入被查询姓名"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="phone" label="手机号">
                    <el-input v-model="form.phone" placeholder="请输入被查询人手机号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="idNumber" label="身份证号">
                    <el-input v-model="form.idNumber" placeholder="请输入被查询人身份证号 "></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.verification">
                  <el-form-item prop="verifyCode" label="验证码" :rules="{ required: true, message: '验证码不能为空' }">
                    <el-input v-model="form.verifyCode" placeholder="请输入验证码">
                      <i slot="suffix" style="
                          font-size: 14px;
                          color: #0062ff;
                          margin-right: 15px;
                        " @click="getcode">{{ secondrender }}</i>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <el-button type="primary" @click="submit" :loading="loading" style="margin-left: 165px" class="button">提交</el-button>
      </div>
    </el-form>
    <Buy ref="Buy" :getSubmit="postDetection" v-model="form.paymentMethod" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import verification from "@/components/form/verification.vue";
import userinfo from "@/mixins/userinfo.js";
import Buy from "./components/Buy";
export default {
  components: { verification, Buy },
  mixins: [userinfo],
  props: {
    ismode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    const idNumberValidator = (_, value, callback) => {
      console.log(value);
      if (this.$utils.test.idCardStrict(value)) {
        return callback();
      } else {
        return callback(new Error("请输入正确的身份证号"));
      }
    };
    return {
      aliform: '',
      second: 0,
      loading: false,
      price: 20, //  三要素的价格  现在是写死 的
      form: {
        name: "",
        phone: "",
        idNumber: "",
        verifyCode: "",
        verification: false,
        exposure: false,
        contract: false,
        paymentMethod: 1
      },
      rules: {
        name: [
          { required: true, message: "请输入被查询姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入被查询人手机号", trigger: "blur" },
          { validator: phoneValidator, trigger: "blur" },
        ],
        idNumber: [
          {
            required: true,
            message: "请输入被查询人身份证号",
            trigger: "blur",
          },
          { validator: idNumberValidator, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      electronicData: (state) => state.electronic.electronicData,
    }),
    secondrender() {
      return this.second == 0 ? "获取验证码" : this.second + "s";
    },
  },
  created() {
    const detectionId = this.$route.query.detectionId;
    if (!detectionId) {
      return
    }
    this.getDetectionDetail(detectionId)
  },
  mounted() {
    const { signers } = this.electronicData;
    if (signers.length < 2) {
      return;
    }
    if (!this.ismode) {
      return;
    }
    const data = signers[1];
    const name = data.operatorName;
    const phone = data.operatorPhone;
    const idNumber = data.operatorIdNumber;
    this.form = { ...this.form, name, phone, idNumber };
  },
  methods: {
    async getDetectionDetail(detectionId) {
      const { data, status, info } = await this.$api.getDetectionDetail({ detectionId })
      if (status !== 200) {
        this.$message({
          type: 'error',
          message: info
        })
        return

      }
      this.isSuccess(data)
    },
    change(res) {
      this.form = res;
    },
    secondrun() {
      let js = setInterval(() => {
        this.second--;
        if (this.second == 0) {
          clearInterval(js);
        }
      }, 1000);
    },
    validateField(arr = []) {
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField(arr, (res) => {
          resolve(!res);
        });
      });
    },
    async getcode() {
      if (this.second !== 0) {
        return;
      }
      const verify = await this.validateField(["phone"]);
      if (verify) {
        const { status, info } = await this.$api.postSmsCodeVerification({
          phone: this.form.phone,
        });
        if (status == 200) {
          this.$message({
            message: info,
            type: "success",
          });
          this.second = 60;
          this.secondrun();
        } else {
          this.$message({
            message: info,
            type: "error",
          });
        }
      }
    },
    alert() {
      return new Promise((resolve, reject) => {
        this.$alert(
          '<span>三要素报告需要5-10分钟的查询过程，可稍后前往风险排查中的<span style="color:#0062FF;">排查记录</span>查看结果 </span>',
          "温馨提示",
          {
            confirmButtonText: "确定",
            dangerouslyUseHTMLString: "true",
            showCancelButton: "true",
            type: "info",
            callback: (action) => {
              if (action === "confirm") {
                resolve();
              } else {
                reject();
              }
            },
          }
        );
      });
    },
    // testpostDetection() {
    //   this.$emit("success", {
    //     detectionId: "",
    //     content: "",
    //     name: "",
    //     idNumber: "",
    //     phone: "",
    //     createdAt: "",
    //     exposureList: null,
    //     contractList: null,
    //     verification: null, // 三要素报告
    //   });
    // },
    async submit() {
      this.loading = true;

      if (this.form.verification) {
        await this.$store.dispatch("user/buy", this.price);
        this.loading = false
      }
      try {
        await this.$refs.form.validate();
      } catch (error) {
        this.loading = false
      }
      if (this.form.verification) {
        // await this.alert()
        this.$refs.Buy.show({
          name: "三要素报告",
          price: this.price,
          balance: this.balance,
        });
      } else {
        this.postDetection()
      }
    },
    isSuccess(data) {
      if (this.ismode) {
        this.$emit("success", data);
      } else {
        this.$router.push({
          path: "/inspect/inspectList",
          query: {
            fileUrl: data.fileUrl,
          },
        });
      }
    },
    async postDetection() {
      const returnUrl = window.location.origin + window.location.pathname;
      try {
        const { status, data, info } = await this.$api.postDetection({ ...this.form, returnUrl });
        if (status === 200) {
          this.$message({
            type: "success",
            message: info,
          });
          // 如果支付宝支付  跳转支付宝
          if (this.form.paymentMethod == 2) {
            this.aliform = data;
            this.$nextTick(() => {
              if (this.ismode) {
                document.forms[1].submit();
              } else {
                document.forms[0].submit();  //重点--这个才是跳转页面的核心,获取第一个表单并提交
              }
            });
            return
          }
          this.isSuccess(data)
        } else {
          this.$message({
            type: "error",
            message: info,
          });
        }
        this.loading = false;
      } catch (e) {
        console.log("error", e);
        this.loading = false;
      }
    },
    noCode() {
      this.$alert("如无法获取验证码，请联系对接法务或客服。", "帮助", {
        confirmButtonText: "确定",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.inspiectSearch {
  padding: 16px 24px;

  .box {
    background: #fff;
    border-radius: 6px;
    padding-bottom: 40px;

    .title {
      height: 60px;
      line-height: 60px;
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      padding: 0 20px;
    }

    .row {
      display: flex;

      .row-left {
        width: 162px;
        text-align: right;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 14px;
      }

      .row-right {
        flex: 1;
        padding-right: 50px;
      }
    }

    .button {
      width: 110px;
      height: 40px;
    }
  }

  // background: #fff;
  // min-height: calc(100vh - 120px - 28px);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // padding-bottom: 100px;
  // background-image: url(~@/static/image/inspect_bg_1.png);
  // background-repeat: no-repeat;
  // background-size: 100% auto;
  // background-position: top center;
  // .content {
  //   width: 440px;
  //   background: #ffffff;
  //   box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
  //   border-radius: 6px;
  //   border: 1px solid #e3e3e3;
  //   margin-top: 175px;
  //   padding: 30px;
  //   h1 {
  //     text-align: center;
  //     font-size: 22px;
  //     font-family: PingFangSC-Medium, PingFang SC;
  //     font-weight: 500;
  //     color: #262626;
  //     line-height: 30px;
  //   }
  // }
}
</style>