/*
* @Author: 曹俊杰
* @Date: 2022-05-10 18:47:09
* @Module: 出证
*/
<template>
    <div class="inspiectSearch">
        <div class="box">
            <div class="title">申请出证</div>
            <div class="row" style="margin-top: 20px;">
                <div class="row-left">选择出证类型：</div>
                <div class="row-right paichabox">
                    <div class="item" @click="form.certificateType = 0"
                        :class="{ activeItem: form.certificateType === 0 }">
                        <div class="row1">
                            <div class="row1-l">
                                <c-radio :value="form.certificateType === 0" />
                                <p class="tit">电子版</p>
                            </div>
                            <div class="row1-r">预览</div>
                        </div>
                        <div class="row2">可证明签署全流程有效性，包含双方签署意愿、真实身份与签署文件无篡改</div>
                        <div class="row3">出证时间：3-7个工作日，邮箱发送</div>
                        <div class="row4">
                            <div class="row4-l">
                                <span>出证机构：</span>
                                <span>中能e签（电子章）</span>

                            </div>
                            <div class="row4-r">
                                <span>100元 </span>
                                <span>/ 合同 </span>
                            </div>
                        </div>
                    </div>
                    <div class="item " @click="form.certificateType = 1"
                        :class="{ activeItem: form.certificateType === 1 }">
                        <div class="row1">
                            <div class="row1-l">
                                <c-radio :value="form.certificateType === 1" />
                                <p class="tit">纸质版</p>
                            </div>
                            <div class="row1-r">预览</div>
                        </div>
                        <div class="row2">可证明签署全流程有效性，包含双方签署意愿、真实身份与签署文件无篡改</div>
                        <div class="row3">出证时间：3-10个工作日，线下邮寄</div>
                        <div class="row4">
                            <div class="row4-l">
                                <span>出证机构：</span>
                                <span>中能e签（实体章）</span>

                            </div>
                            <div class="row4-r">
                                <span>200元 </span>
                                <span>/ 合同 </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;" v-show="form.certificateType === 1">
                <div class="row-left" style="line-height: 36px;">填写收件人信息：</div>
                <div class="row-right">
                    <div class="form">
                        <el-form :model="form" ref="form" :rules="rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item prop="receiptName" label="姓名">
                                        <el-input v-model="form.receiptName" placeholder="请输入收件人姓名"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item prop="receiptPhone" label="手机号">
                                        <el-input v-model="form.receiptPhone" placeholder="请输入收件人手机号"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item prop="receiptAddress" label="地址">
                                        <el-input v-model="form.receiptAddress" placeholder="请输入收件人地址"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>

                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;">
                <div class="row-left" style="line-height: 36px;"></div>
                <div class="row-right">
                    <el-button type="primary" :disabled="disabled" @click="sub" :loading="loading"
                        class="button">提交</el-button>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import cRadio from "@/components/form/cRadio.vue"
export default {
    components: { cRadio },
    data() {
        const phoneValidator = (_, value, callback) => {
            if (this.$utils.test.mobile(value)) {
                return callback();
            } else {
                return callback(new Error('请输入正确的手机号'));
            }
        }
        return {
            second: 0,
            loading: false,
            form: {
                receiptName: '',
                receiptPhone: '',
                receiptAddress: '',
                certificateType: 0
            },
            rules: {
                receiptName: [{ required: true, message: '请输入被查询姓名' }],
                receiptPhone: [{ required: true, message: '请输入被查询人手机号' }, { validator: phoneValidator, trigger: 'blur' }],
                receiptAddress: [{ required: true, message: '请输入被查询人地址' }],
            }
        };
    },
    computed: {
        disabled() {
            if (this.certificateType) {
                return this.$utils.test.empty(this.form.receiptName) || this.$utils.test.empty(this.form.receiptPhone) || this.$utils.test.empty(this.form.receiptAddress)
            } else {
                return false
            }
        },
        contract() {
            return JSON.parse(this.$route.query.data)
        }
    },
    mounted() {
        console.log(this.$route.query)
    },
    methods: {
        sub() {
            if (this.form.certificateType == 1) {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.postCertificateRequest()
                    } else {
                        return false;
                    }
                });
            } else {
                this.postCertificateRequest()
            }
        },
        async postCertificateRequest() {
            const { data, status, info } = await this.$api.postCertificateRequest({ ...this.form, contractId: this.contract.contractId });
            if (status === 200) {
                this.$message({
                    message: info,
                    type: 'success'
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: info,
                    type: 'error'
                })
            }
        }


    },
};
</script>
<style lang='scss' scoped>
.inspiectSearch {
    padding: 0 24px;

    .box {
        background: #fff;
        border-radius: 6px;
        padding-bottom: 40px;

        .title {
            height: 60px;
            line-height: 60px;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            padding: 0 20px;
        }

        .row {
            display: flex;

            .row-left {
                width: 162px;
                text-align: right;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-right: 14px;
            }



            .row-right {
                flex: 1;
                padding-right: 50px;

                .item {
                    width: 340px;
                    height: 180px;
                    border-radius: 4px;
                    border: 1px solid #eee;
                    background: linear-gradient(90deg, #FFF 0%, #F8F8F8 100%);
                    padding: 20px;

                    .row1 {
                        display: flex;
                        height: 22px;
                        justify-content: space-between;

                        &-l {
                            display: flex;
                            align-items: center;

                            .tit {
                                margin-left: 8px;
                                font-family: "PingFang SC";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }

                        &-r {
                            color: #0062FF;
                            font-family: "PingFang SC";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .row2 {
                        font-family: "PingFang SC";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        /* 169.231% */
                        margin-top: 8px;
                    }

                    .row3 {
                        color: #666;
                        font-family: "PingFang SC";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-top: 8px;
                    }

                    .row4 {
                        display: flex;
                        justify-content: space-between;
                        font-size: 13px;
                        margin-top: 17px;
                        color: #666;

                        &-l {
                            :last-child {
                                color: #262626;
                            }
                        }

                        &-r {
                            :first-child {
                                color: #FF0D0D;
                            }
                        }
                    }
                }

                .activeItem {
                    border: 1.5px solid #1F5AEC;

                    background: #EFF6FF;
                }

            }

            .paichabox {
                display: flex;

                .item:nth-child(3n - 1) {
                    margin: 0 20px;
                }
            }
        }

        .button {
            width: 110px;
            height: 40px;
        }
    }

}
</style>