/*
* @Author: 曹俊杰
* @Date: 2023-01-05 19:35:34
* @Module: 公共头部
*/
<template>
  <div class="header">
    <div class="header-left">
      <div class="logo " :class="$route.name === 'home' ? 'logo1' : 'logo2'" @click="$router.push({ name: 'home' })">
      </div>
      <Menu :class="{ hidMenu: $route.meta.hidMenu }"></Menu>
    </div>

    <div class="content">
      <el-dropdown @command="headerClick" placement="bottom-start" size="small">
        <div class="user pointer" :class="{ homeStyle: $route.name === 'home' }">
          <span v-if="companyAuth">{{ companyObj.name }}</span>
          <span class="pointer" v-else @click="() => $store.dispatch('user/toAccountCompanyAuth')">加入/创建企业</span>
          <span>｜</span>
          <span v-if="!companyAuth">个人</span>
          <span v-else>
            <span v-if="user.creator" style="color:#FF8412">法人</span>
            <span v-else>
              员工
            </span>
          </span>
          <span style="margin-left:10px;" class="el-icon-ali-qiehuan"></span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in userLogList" :key="item.uuid" :command="item">
            <div class="dropdownItem" style="display:flex;align-items: center;">
              <div class="dropdownItem-content">
                <p>
                  <span v-if="item.company">{{ item.company.name }}</span>
                  <span v-else>暂无公司</span>
                </p>
                <p>
                  <span v-if="item.name">{{ item.name }}</span>
                  <span v-else style="color:#FF0D0D">未认证</span>
                  <span style="margin-left:6px;">{{ item.phone }}</span>
                  <span style="color:#262626;margin:6px" v-if="!item.company">个人</span>
                  <span v-else style="margin:6px">
                    <span v-if="item.creator" style="color:#FF8412">法人</span>
                    <span v-else style="color:#0062FF">员工</span>
                  </span>
                  <i class="el-icon-delete" style="color:#FF0D0D" @click.stop="() => userDelete(item)"></i>
                </p>
              </div>
              <div class="dropdownItem-active el-icon-check" v-show="item.uuid == user.uuid"
                style="color:#0062FF;margin-left:20px;">
              </div>
            </div>

          </el-dropdown-item>
          <el-dropdown-item :command="{ uuid: 'add' }" divided>
            <div style="line-height:48px;text-align: center;">
              添加账号
            </div>
          </el-dropdown-item>

        </el-dropdown-menu>
      </el-dropdown>
      <div class="btns" :style="$route.name === 'home' ? '--clr:#fff' : '--clr:#262626'">
        <i class="el-icon-ali-kefu" v-popover="'kefu'"></i>
        <i class="el-icon-ali-bangzhuzhongxin" @click="$utils.help()"></i>
        <i class="el-icon-ali-xiaoxi"></i>
      </div>
      <el-popover ref="kefu" placement="bottom" width="200" trigger="hover">
        <div style="width:176px;height:176px;" class="service-code"></div>
      </el-popover>
      <div class="solid"></div>
      <div class="r">
        <Mavatar :size="32" :src="user.avatar"></Mavatar>
        <div class="name" :class="{ homeStyle: $route.name === 'home' }">
          <p v-if="user.name">{{ user.name }}</p>
          <p v-else>暂无信息</p>
        </div>
        <p class="el-icon-caret-bottom" style="size:12px;color:#D9D9D9"></p>



        <div class="headerUserInfo">
          <div class="headerUserInfo-t">
            <Mavatar :size="40" :src="user.avatar"></Mavatar>
            <div class="headerUserInfo-t-content">
              <div class="username">
                <p>
                  {{ user.name || '暂无信息' }}
                </p>
                <renzheng-icon :value="auth"></renzheng-icon>
              </div>
              <div class="phone">账号：{{ user.phone }}</div>
            </div>
          </div>
          <div class="headerUserInfo-c pointer" @click="$router.push({ name: 'enterprise' })" v-show="user.creator">
            <p class="el-icon-ali-qiyeguanli"></p>
            <p style="margin-left:6px;">
              企业管理
            </p>
          </div>
          <div class="headerUserInfo-c pointer" @click="$router.push({ name: 'personal' })">
            <p class="el-icon-ali-gerenzhongxin"></p>
            <p style="margin-left:6px;">
              个人中心
            </p>
          </div>
          <div class="headerUserInfo-c pointer" @click="logout">
            <p class="el-icon-ali-zhanghaotuichu"></p>
            <p style="margin-left:6px;">
              退出登录
            </p>
          </div>
        </div>



      </div>
    </div>

  </div>


</template>
<script>
import { mapState, mapGetters } from "vuex";
import Menu from "@/components/layouts/Menu.vue"
import renzhengIcon from "@/components/renzhengIcon.vue"
import userinfo from "@/mixins/userinfo.js"
export default {
  components: {
    Menu,
    renzhengIcon
  },
  data() {
    return {};
  },
  mixins: [userinfo],
  computed: {
    ...mapState({
      userLogList: state => state.userLog.userLogList
    })
  },
  mounted() {
  },
  methods: {
    // 头部菜单点击
    headerClick(item) {
      const { uuid } = item
      if (uuid === 'add') {
        this.$router.push({
          path: '/login'
        })
      } else {
        this.$store.dispatch('user/changeUserInfo', item)
        // this.$im.config()
      }
    },
    logout() {
      this.$confirm(`确定要退出当前账号“${this.user.nickName || this.user.phone}”？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit("user/logout")
        this.$store.dispatch("userLog/clearUserLog")
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      });
    },
    userDelete(item) {
      if (item.uuid == this.user.uuid) {
        this.$alert('不能删除当前登录用户，请切换其他账号再删除。', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        });
      } else {
        this.$store.dispatch("userLog/deleteUserLog", item)
      }
    }
  },
};
</script>
<style lang='scss' scoped>
.header {
  height: 100%;
  display: flex;
  align-items: center;
  // padding: 0 24px;
  justify-content: space-between;
  width: 100%;

  .hidMenu {
    display: none;
  }

  &-left {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;

    .logo {
      background-size: 100%;
      background-repeat: no-repeat;
      width: 122px;
      height: 26px;
      margin-right: 60px;
    }




    .logo1 {
      background-image: url(~@/static/logo2.png);

    }

    .logo2 {
      background-image: url(~@/static/logo1.png);
    }
  }


  .content {
    display: flex;
    align-items: center;

    .solid {
      width: 1px;
      height: 10px;
      background: #eeeeee;
      margin: 0 20px;
    }

    .btns {
      padding-left: 20px;
      display: flex;
      width: 100px;
      color: var(--clr);
      justify-content: space-between;

      i {
        cursor: pointer;
      }
    }

    .user {
      background: #fff;

      padding: 0 16px;
      height: 32px;
      border-radius: 2px;
      line-height: 32px;
    }

    .homeStyle {
      color: #fff;
      background: rgba($color: #F3F6F8, $alpha: .2);
    }

    .r {
      display: flex;
      align-items: center;
      height: 70px;

      .name {
        margin: 0 10px;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
      }

      .homeStyle {
        color: #fff;
        background: none;
      }

      &:hover {
        .headerUserInfo {
          display: block;
        }
      }

      .headerUserInfo {
        width: 240px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.10);
        position: fixed;
        top: 64px;
        z-index: 1000;
        right: 20px;
        display: none;

        &:hover {
          display: block;
        }

        &-t {
          display: flex;
          height: 74px;
          padding: 0 16px;
          align-items: center;

          &-content {
            margin-left: 12px;

            .username {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #262626;
              display: flex;
              align-items: center;


            }

            .phone {
              font-family: "Source Han Sans CN";
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #999999;
              margin-top: 4.5px;
            }
          }
        }

        &-c {
          display: flex;
          align-items: center;
          height: 48px;
          padding: 0 16px;
          font-family: "Source Han Sans CN";
          font-size: 14px;
          line-height: 21px;
          color: #262626;

          &:hover {
            color: #0062FF;
          }
        }
      }
    }
  }

  /* 桌面电脑（屏幕宽度大于 1024px） */
  @media screen and (max-width: 1141px) {
    //低于1350

    /* 桌面电脑布局和样式 */
    .btns {
      display: none !important;
    }
  }

  /* 桌面电脑（屏幕宽度大于 1024px） */
  @media screen and (max-width: 1051px) {
    //低于1350

    /* 桌面电脑布局和样式 */
    .logo {
      zoom: 0.5;
    }
  }

}
</style>