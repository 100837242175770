<template>
    <div class="box">
        <div v-html="h" />
        <!-- {{ equityFrom }} -->
        <el-form :model="equityFrom" ref="form" :rules="rules" label-position="top">
            <div class="top">
                <div class="top-l" @click="close">
                    <i class="el-icon-close"></i>
                    <p>关闭</p>
                </div>
                <div class="top-c">
                    创建权益执行
                </div>
                <div class="top-r" @click="submitForm">提交</div>
            </div>
            <div class="content">
                <div class="form">
                    <div class="title">
                        <i class="solid"></i>
                        <p>主体信息</p>
                    </div>
                    <div class="dec">
                        申请方
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item prop="subjectTypeA" label="申请方类型">
                                <el-select v-model="subjectTypeA" placeholder="请选择申请方类型" style="width: 100%;" clearable>
                                    <el-option v-for="item in subjectType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>


                        <el-col :span="8">
                            <el-form-item prop="subjectA" label="申请方">
                                <el-input v-model="subjectA" placeholder="请输入申请方名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-show="subjectTypeA == 1">
                            <el-form-item prop="contactA" label="申请方联系人">
                                <el-input v-model="contactA" placeholder="请输入申请方联系人 "></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="phoneA" label="申请方电话">
                                <el-input v-model="phoneA" placeholder="请输入申请方电话 "></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="weixinA" label="申请方微信">
                                <el-input v-model="weixinA" placeholder="请输入申请方微信 "></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="dec">
                        被申请方
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item prop="subjectTypeB" label="被申请方类型">
                                <el-select v-model="subjectTypeB" placeholder="请选择被申请方类型" style="width: 100%;">
                                    <el-option v-for="item in subjectType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="subjectB" label="被申请方名称">
                                <el-input v-model="subjectB" placeholder="请输入被申请方名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="phoneB" label="被申请方电话">
                                <el-input v-model="phoneB" placeholder="请输入被申请方电话"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="weixinB" label="被申请方微信">
                                <el-input v-model="weixinB" placeholder="请输入被申请方微信 "></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </div>

                <div class="bottom">
                    <div class="title">
                        <i class="solid"></i>
                        <p>裁判文书</p>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item prop="type" label="裁决文书">
                                <el-select v-model="type" placeholder="请选择裁决文书" style="width: 33.33%;">
                                    <el-option v-for="item in enforceType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="verdictUrl" :label="type == 1 ? '法院判决书' : '仲裁裁决书'">
                                <caijuewenshushangchuan v-model="verdictUrl" :label="type == 1 ? '法院判决书' : '仲裁裁决书'" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-show="type == 2">
                            <el-form-item prop="proofOfService" label="送达回证">
                                <caijuewenshushangchuan v-model="proofOfService" label="送达回证" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-show="type == 2">
                            <el-form-item prop="proofOfEffective" label="生效证明">
                                <caijuewenshushangchuan v-model="proofOfEffective" label="生效证明" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-form>

    </div>
</template>

<script>
import caijuewenshushangchuan from "@/components/caijuewenshushangchuan.vue"
import selectData from '@/mixins/selectData.js'
import userinfo from '@/mixins/userinfo.js'
import { mapState } from "vuex";

function mapFormState(_this) {
    let data = {};
    [
        "type",
        "subjectTypeA",
        "subjectA",
        "contactA",
        "phoneA",
        "weixinA",
        "subjectTypeB",
        "subjectB",
        "phoneB",
        "weixinB",
        "verdictUrl",
        "proofOfService",
        "proofOfEffective"
    ].forEach(res => {
        data[res] = {
            get() {
                return _this.equityFrom[res]
            },
            set(type) {
                _this.setEquityFrom({ [res]: type })
            }
        }

    })
    return data
}
// console.log(...mapFormState())
export default {
    mixins: [selectData, userinfo],
    components: {
        caijuewenshushangchuan,
    },
    data() {
        return {
            form: {
                type: null,
                subjectTypeA: null,
                subjectA: '',
                contactA: '',
                phoneA: '',
                weixinA: '',
                subjectTypeB: null,
                subjectB: null,
                phoneB: '',
                weixinB: '',
                verdictUrl: '',
                proofOfService: '',
                proofOfEffective: '',
            },
            rules: {
                type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                subjectTypeA: [{ required: true, message: '请选择申请方类型', trigger: 'blur' }],
                subjectA: [{ required: true, message: '请输入申请方名称', trigger: 'blur' }],
                phoneA: [{ required: true, message: '请输入申请方电话', trigger: 'blur' }],
                subjectTypeB: [{ required: true, message: '请选择被申请方类型', trigger: 'blur' }],
                subjectB: [{ required: true, message: '请输入被申请方名称', trigger: 'blur' }],
                verdictUrl: [{ required: true, message: '请上传裁决文书', trigger: 'blur' }],
            },
            h: '',
        }
    },
    computed: {
        ...mapState({
            equityFrom: state => state.equity.equityFrom
        }),
        type: {
            get() {
                return this.equityFrom.type
            },
            set(type) {
                this.setEquityFrom({ type: type })
            }
        },
        subjectTypeA: {
            get() {
                return this.equityFrom.subjectTypeA
            },
            set(type) {
                this.setEquityFrom({ subjectTypeA: type })
            }
        },
        subjectA: {
            get() {
                return this.equityFrom.subjectA
            },
            set(type) {
                this.setEquityFrom({ subjectA: type })
            }
        },
        contactA: {
            get() {
                return this.equityFrom.contactA
            },
            set(type) {
                this.setEquityFrom({ contactA: type })
            }
        },
        phoneA: {
            get() {
                return this.equityFrom.phoneA
            },
            set(type) {
                this.setEquityFrom({ phoneA: type })
            }
        },
        weixinA: {
            get() {
                return this.equityFrom.weixinA
            },
            set(type) {
                this.setEquityFrom({ weixinA: type })
            }
        },
        subjectTypeB: {
            get() {
                return this.equityFrom.subjectTypeB
            },
            set(type) {
                this.setEquityFrom({ subjectTypeB: type })
            }
        },
        subjectB: {
            get() {
                return this.equityFrom.subjectB
            },
            set(type) {
                this.setEquityFrom({ subjectB: type })
            }
        },
        phoneB: {
            get() {
                return this.equityFrom.phoneB
            },
            set(type) {
                this.setEquityFrom({ phoneB: type })
            }
        },
        weixinB: {
            get() {
                return this.equityFrom.weixinB
            },
            set(type) {
                this.setEquityFrom({ weixinB: type })
            }
        },
        verdictUrl: {
            get() {
                return this.equityFrom.verdictUrl
            },
            set(type) {
                this.setEquityFrom({ verdictUrl: type })
            }
        },
        proofOfService: {
            get() {
                return this.equityFrom.proofOfService
            },
            set(type) {
                this.setEquityFrom({ proofOfService: type })
            }
        },
        proofOfEffective: {
            get() {
                return this.equityFrom.proofOfEffective
            },
            set(type) {
                this.setEquityFrom({ proofOfEffective: type })
            }
        },
        // ...mapFormState(this)
    },
    created() {
        if (this.$utils.test.objIsEmpty(this.equityFrom)) {
            return
        }
        this.$alert('有上次未完成的表单，是否恢复？', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
                // 如果点击确定  恢复数据，否则清空数据
                if (action == 'confirm') {
                    return
                }
                this.clear()
            }
        })
    },
    watch: {
        subjectTypeA: {
            handler(subjectTypeA) {
                // 法人
                const clear = () => {
                    this.subjectA = '';
                    this.contactA = '';
                    this.phoneA = '';
                }
                if (subjectTypeA === 1) {
                    clear()
                    this.subjectA = this.companyObj.name;
                    this.contactA = this.user.name;
                    this.phoneA = this.user.phone;
                    return
                }
                // 自然人
                if (subjectTypeA === 2) {
                    clear()
                    this.subjectA = this.user.name;
                    this.phoneA = this.user.phone;
                    return
                }
                clear()
            },
            deep: true,
        }
    },
    methods: {
        // 关闭
        close() {
            if (this.$utils.test.objIsEmpty(this.equityFrom)) {
                this.$router.replace({ path: '/equity/execute' })
                return
            }
            this.$alert('退出将会丢失已录入信息，确定退出吗？', '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: action => {
                    if (action !== 'confirm') {
                        return
                    }
                    this.clear()
                    this.$router.replace({ path: '/equity/execute' })
                }
            })
        },
        // 清除
        clear() {
            this.$store.commit("equity/clearEquityFrom")
        },
        //  同步属性
        setEquityFrom(payload) {
            this.$store.commit("equity/setEquityFrom", payload)
        },
        submitForm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.postEnforceAdd()
                } else {
                    return false;
                }
            })
        },
        async postEnforceAdd() {
            const { status, info, data } = await this.$api.postEnforceAdd(this.equityFrom)
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.h = data
                this.clear()
                this.$nextTick(() => {
                    document.forms[0].submit()
                })
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        }
    }


}
</script>

<style lang="scss" scoped>
.box {
    .top {
        display: flex;
        height: 64px;
        justify-content: space-between;
        padding: 0 24px;
        align-items: center;

        &-l {
            display: flex;
            color: #999;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            align-items: center;
            cursor: pointer;

            p {
                margin-left: 4px;
            }
        }

        &-c {
            color: #262626;

            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &-r {
            width: 78px;
            height: 34.277px;
            flex-shrink: 0;
            border-radius: 2px;
            background: #0062FF;
            color: #FFF;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 34.277px;
            cursor: pointer;
        }
    }

    .content {
        width: 1280px;
        margin: 0 auto;
        margin-top: 16px;

        .title {
            height: 60px;
            display: flex;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            align-items: center;

            .solid {
                border-radius: 10px;
                background: #0062FF;
                width: 2px;
                height: 16px;
                margin-right: 10px;
            }
        }

        .form,
        .bottom {
            padding: 0 20px;
            background: #fff;
            border-radius: 6px;

            .dec {
                height: 40px;
                flex-shrink: 0;
                background: #F7F8F9;
                line-height: 40px;
                padding: 0 20px;
                color: #666;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .bottom {
            margin-top: 16px;


        }
    }

}
</style>