/*
* @Author: 曹俊杰
* @Date: 2022-03-02 19:09:41
* @Module: 消费记录
*/
<template>
  <div style="background:#fff;overflow:hidden">
    <div style="padding:0 20px 20px">
      <hdqForm :showLabel="['date']" @search="search" ref="hdqForm" />
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="subject" label="消费功能" min-width="160" />
        <el-table-column label="时间" width="180">
          <template slot-scope="scope">{{ scope.row.createdAt | timeFormat('yy-mm-dd hh:MM:ss') }}</template>
        </el-table-column>
        <!-- <el-table-column label="类型">
          <template slot-scope="scope">{{ transactionTypeRender(scope.row.type) }}</template>
        </el-table-column> -->
        <el-table-column label="支付渠道" align="center">
          <template slot-scope="scope">{{ payTypeRender(scope.row.paymentMethod) }}</template>
        </el-table-column>
        <el-table-column label="消费价格" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.totalAmount">{{ scope.row.totalAmount | priceFormat }}</p>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column label="代金券金额" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.voucherAmount">{{ scope.row.voucherAmount | priceFormat }}</p>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column label="支付金额" align="center" width="150">
          <template slot-scope="scope">
            <div>
              <p v-if="scope.row.paymentAmount">{{ scope.row.paymentAmount | priceFormat }} </p>
              <p v-else>--</p>
              <p v-if="scope.row.refundAmount" style="color: red;font-size: 10px;">已退款(￥{{ scope.row.refundAmount |
                priceFormat }})</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="可开票金额" align="center">
          <template slot-scope="scope">{{ kekaipiao(scope.row) | priceFormat }}</template>
        </el-table-column>

        <el-table-column label="开票进度" align="center">
          <template slot-scope="scope">
            <i class="el-icon-time" v-if="scope.row.invoiced" style="color: #0062ff;"></i>
          </template>
        </el-table-column>
        <!-- <el-table-column label="消费账号">
          <template slot-scope="scope">
            <span>{{ scope.row.empName || '--' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="单号" align="center">
          <template slot-scope="scope">
            <el-popover width="200" trigger="hover">
              <p>单号：{{ scope.row.tradeNo }}</p>
              <div style="text-align: right; margin: 0">
                <a class="primary pointer" style="font-size: 12px;" @click="copyToClipboard(scope.row.tradeNo)">复制</a>
              </div>
              <a class="el-icon-collection-tag" slot="reference"></a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="70">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.note" placement="top"
              :disabled="!scope.row.note">
              <span @click="() => open(scope)" class="el-icon-ali-xingzhuangjiehe1"
                :style="scope.row.note ? '' : 'color:#999;'">
              </span>
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </div>
  </div>
</template>
<script>

import hdqForm from "@/components/hdqForm.vue";
import hdqPagination from "@/components/hdqPagination.vue"
import selectData from "@/mixins/selectData";
export default {
  mixins: [selectData],
  components: {
    hdqForm,
    hdqPagination
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getTransaction()
  },
  methods: {
    kekaipiao({ paymentAmount, refundAmount }) {
      return paymentAmount - refundAmount
    },
    transactionTypeRender(value) {
      console.log(value)
      const item = this.transactionType.find(res => res.value == value)
      return item ? item.label : value
    },
    payTypeRender(value) {
      const item = this.payType.find(res => res.value == value)
      return item ? item.label : value
    },
    search() {
      this.$refs.pagination.createPagination({ current: 1 })
      this.getTransaction()
    },
    paginationChange() {
      this.getTransaction()
    },
    async getTransaction() {
      const { current, size } = this.$refs.pagination
      const { expenseType: type, ...getFormData } = this.$refs.hdqForm.getForm();

      const { status, data } = await this.$api.getTransaction({ current, size, ...getFormData, type })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    async copyToClipboard(text) {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(text);
          this.$message({
            type: 'success',
            message: '已复制到剪贴板'
          })
          console.log('已复制到剪贴板');
        } catch (err) {
          console.error('复制到剪贴板出错:', err);
        }
      } else {
        console.log('您的浏览器不支持此复制功能');
      }

    }
  },
};
</script>
<style lang='scss' scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>