<template>
    <div class="daojishi">
        <div style="color:#333" v-if="guoqi">报告已过期24小时内可查看</div>
        <div style="color:green" class="pointer" v-else @click="onchick">查询成功(请于{{ hours }}:{{ minutes }}:{{ seconds }}内点击查看报告)</div>
    </div>
</template>

<script>
export default {
    props: {
        targetTime: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
            guoqi: false
        }
    },
    mounted() {
        // 在页面加载完成后初始化倒计时
        this.initCountdown();
    },
    methods: {
        onchick(){
            this.$emit('onchick')
        },
        initCountdown() {
            // 设置目标时间，比如10分钟后（这里只是示例，可按需修改）
            // this.targetTime = Date.now() + 10 * 60 * 1000;
            this.tick();
        },
        tick() {
            const now = Date.now();
            const diff = (this.targetTime + 60 * 60 * 24) * 1000 - now;
            if (diff > 0) {
                // 计算剩余的小时、分钟、秒数
                this.hours = Math.floor(diff / (1000 * 60 * 60));
                const remainingMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                this.minutes = remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;
                const remainingSeconds = Math.floor((diff % (1000 * 60)) / 1000);
                this.seconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
                setTimeout(() => {
                    this.tick();
                }, 1000);
            } else {
                // 倒计时结束处理，这里简单重置为0
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
                this.guoqi = true
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.daojishi {
    font-size: 10px;
}
</style>