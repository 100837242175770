/*
* @Author: 曹俊杰
* @Date: 2024-09-22 14:57:49
* @Last Modified by: 曹俊杰杰
* @Last Modified time: 2024-11-11 23:57:555
*/推广
<template>
  <div class="box">
    <div class="alert">
      <div class="el-icon-ali-jinggao" style="margin-right:8px;"></div>
      <div>邀请用户企业认证并完成首充，可获得20元推广收益；被邀请企业的充值，您均可按相应等级获得一定比例的推广收益！</div>
    </div>
    <div class="top">
      <div class="title">
        <p>推广收益</p>
        <ExperienceOfficer />
      </div>
      <div class="itembox">
        <div class="item">
          <div class="item-t">我的收益</div>
          <div class="item-c">{{ user.reward | priceFormat }}</div>
          <div class="item-b">
            <span @click="withdrawClick(-2)">转入余额</span>
            <span style="margin: 0 10px;">|</span>
            <span @click="withdrawClick(-1)">提现</span>
          </div>
        </div>
        <div class="item" style="margin: 0 22px;">
          <div class="item-t pointer" @click="showCompanyReferred">我的推广 <span class="el-icon-arrow-right"></span></div>
          <div class="item-c">
            <span>{{ user.refereeCount }}</span>
            <!-- <span class="as">人</span> -->
          </div>
          <div class="item-b">
            <span @click="haibao">生成推广海报</span>
            <span style="margin: 0 10px;">|</span>
            <span @click="copyToClipboard">复制邀请码</span>
          </div>
        </div>
        <div class="item lastItem">
          <div class="item-t">我的等级</div>
          <div class="item-c">
            <span>{{ user.title }}（返润{{ user.ratio }}%）</span>
          </div>
          <div class="item-b">
            <span @click="tisheng">如何提升推广等级？</span>
          </div>
          <img class="bg-image" src="~/image/accountCenter/accountCenter_bg_card.svg" alt="">
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">推广收益明细</div>
      <el-table :data="list" style="width: 100%">
        <el-table-column label="动账日期">
          <template slot-scope="scope">
            <span>{{ scope.row.createdAt | timeFormat('yyyy-mm-dd hh:MM:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="收益明细">
          <template slot-scope="scope">
            <span v-html="amountRender(scope.row)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="动账来源">
          <template slot-scope="scope">
            <span>{{ typeRender(scope.row).label }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="rewardBalance" label="收益余额">
          <template slot-scope="scope">
            <span>{{ scope.row.rewardBalance |priceFormat }}</span>
          </template>
        </el-table-column>

      </el-table>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="getList" />
      <rewardWithdraw ref="rewardWithdraw" @success="onSuccess" />
      <tuiguang ref="tuiguang" />
      <haibao ref="haibao" />
      <companyReferred ref="companyReferred" />
    </div>
  </div>
</template>

<script>
import hdqPagination from "@/components/hdqPagination.vue"
import selectData from '@/mixins/selectData.js'
import userinfo from '@/mixins/userinfo.js'
import rewardWithdraw from "./components/rewardWithdraw"
import tuiguang from "./components/tuiguang"
import haibao from "./components/haibao"
import companyReferred from "./components/companyReferred"
import ExperienceOfficer from "@/components/ExperienceOfficer"
export default {
  mixins: [selectData, userinfo],
  components: {
    hdqPagination,
    rewardWithdraw,
    tuiguang,
    haibao,
    ExperienceOfficer,
    companyReferred
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {

  },
  mounted() {
    this.getList();
  },
  methods: {
    // 查看我的推广
    showCompanyReferred() {
        this.$refs.companyReferred.show()
    },
    onSuccess() {
      this.getAccount()
      this.getList()
    },
    tisheng() {
      this.$refs.tuiguang.show()
    },
    withdrawClick(type) {
      this.$refs.rewardWithdraw.show(type)
    },
    typeRender(data) {
      return this.rewardRecordType.find(res => res.value == data.type)
    },
    amountRender({ type, amount }) {
      if (type > 0) {
        return `<span style="color:#0062FF">+${this.$utils.priceFormat(amount)}</span>`
      } else {
        return `<span style="color:#FF8412">-${this.$utils.priceFormat(amount)}</span>`
      }
    },
    async getList() {
      const { current, size } = this.$refs.pagination
      const { data, status } = await this.$api.getBrokerageRecordList({ current, size })
      if (status == 200) {
        this.list = data.records
        this.$refs.pagination.createPagination(data)
      }
    },
    toAccountCompanyAuth() {
      this.$store.dispatch('user/toAccountCompanyAuth')
    },
    haibao() {
      if (!this.companyAuth) {
        this.$confirm('请先完成企业实名认证！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.toAccountCompanyAuth()
        })
        return
      }
      this.$refs.haibao.show(this.user.inviteCode)
    },
    async copyToClipboard() {
      if (!this.companyAuth) {
        this.$confirm('请先完成企业实名认证！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.toAccountCompanyAuth()
        })
        return
      }
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(this.user.inviteCode);
          this.$message({
            type: 'success',
            message: '已复制到剪贴板'
          })
          console.log('已复制到剪贴板');
        } catch (err) {
          console.error('复制到剪贴板出错:', err);
        }
      } else {
        console.log('您的浏览器不支持此复制功能');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  .alert {
    border-radius: 2px;
    background: #eff6ff;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    color: #0062ff;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 185.714% */
  }

  .top {
    background: #fff;
    padding: 20px;
    border-radius: 6px;

    .title {
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .itembox {
      display: flex;

      .item {
        border-radius: 4px;
        background: #f3f6f8;
        width: calc((100% - (22px * 2)) / 3);
        padding: 20px;

        &-t {
          font-family: "Source Han Sans CN";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &-c {
          font-family: "DIN Alternate";
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 10px;

          .as {
            color: #999;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 8px;
          }
        }

        &-b {
          color: #0062ff;
          font-family: "Source Han Sans CN";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 10px;
          cursor: pointer;
        }
      }

      .lastItem {
        color: #fff;
        border-radius: 10px;
        background: linear-gradient(180deg, #0062ff 0%, #629eff 100%);
        background-repeat: no-repeat;
        position: relative;

        .item-c {
          font-family: "Source Han Sans CN";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 19px;
        }

        .item-b {
          color: #fff;
          font-family: "Source Han Sans CN";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 19px;
        }

        .bg-image {
          width: 120px;
          position: absolute;
          top: 0;
          right: 20px;
        }
      }
    }
  }

  .content {
    padding: 0 20px;
    margin-top: 16px;
    background: #fff;
    border-radius: 6px;

    .title {
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 60px;
    }
  }
}
</style>