<template>
    <el-table border :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;"
        :row-style="{ height: '80px' }">
        <el-table-column label="编号" show-overflow-tooltip prop="number">
            <template slot-scope="scope">
                <span>{{ scope.row.number || "--" }}</span>
            </template>
        </el-table-column>
        <el-table-column label="裁决文书" show-overflow-tooltip prop="number">
            <template slot-scope="scope">
                <verdictRender :verdict="scope.row.verdictUrl" />
            </template>
        </el-table-column>
        <el-table-column label="创建时间" show-overflow-tooltip prop="number">
            <template slot-scope="scope">
                <span>{{ scope.row.createdAt | timeFormat }}</span>
            </template>
        </el-table-column>
        <el-table-column label="申请方" prop="number">
            <template slot-scope="scope">
                <subjectRender :subjectData="scope.row" type="A"/>
            </template>
        </el-table-column>
        <el-table-column label="被申请方" show-overflow-tooltip prop="number">
            <template slot-scope="scope">
                <subjectRender :subjectData="scope.row" type="B"/>
            </template>
        </el-table-column>
        <el-table-column label="处理专员" show-overflow-tooltip prop="number">
            <template slot-scope="scope">
                <span>{{ scope.row.number || "--" }}</span>
            </template>
        </el-table-column>
        <el-table-column label="执行进度" show-overflow-tooltip prop="number">
            <template slot-scope="scope">
                <span>{{ scope.row.number || "--" }}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <a @click="todetail(scope.row)" style="color: #0062FF; cursor: pointer;">查看详情</a>
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
import verdictRender from "./verdictRender.vue"
import subjectRender from "./subjectRender.vue"
export default {
    components: {
        verdictRender,
        subjectRender
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        }
    },
    methods:{
        todetail(res){
            this.$emit('todetail',res)
        }   
    }
}
</script>

<style></style>