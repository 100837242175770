/**
* 代金券
*/
<template>
    <div class="voucherItem">

        <!-- 法律咨询代金券 -->
        <div v-if="item.type == 1">
            <!-- 未使用 -->
            <div class="card" v-if="item.state === 0">
                <div class="card-t">
                    <div class="card-t-l">
                        <div class="value">
                            {{ item.amount }}</div>
                        <div class="icon">元</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60" fill="none">
                        <path opacity="0.5" d="M1 0V60" stroke="#A85E2E" stroke-dasharray="5 5" />
                    </svg>
                    <div class="card-t-r">
                        <span>法律咨询-代金券</span>
                    </div>
                </div>
                <div class="solid"></div>
                <div class="card-b">
                    <div class="card-b-l">有效期至：</div>
                    <div class="card-b-r">{{ item.validUntil | timeFormat('yyyy-mm-dd') }}</div>
                </div>
            </div>
            <!-- 已使用 -->
            <div class="card card1" v-if="item.state === 1">
                <div class="card-t">
                    <div class="card-t-l">
                        <div class="value">
                            {{ item.amount }}</div>
                        <div class="icon">元</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60" fill="none">
                        <path opacity="0.5" d="M1 0V60" stroke="#A85E2E" stroke-dasharray="5 5" />
                    </svg>
                    <div class="card-t-r">
                        <span>法律咨询-代金券</span>
                    </div>
                </div>
                <div class="solid"></div>
                <div class="card-b">
                    <div class="card-b-l">使用日期：</div>
                    <div class="card-b-r">{{ item.usedAt | timeFormat('yyyy-mm-dd') }}</div>
                </div>
            </div>
            <!-- 已过期 -->
            <div class="card card2" v-if="item.state === 2">
                <div class="card-t">
                    <div class="card-t-l">
                        <div class="value">
                            {{ item.amount }}</div>
                        <div class="icon">元</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60" fill="none">
                        <path opacity="0.5" d="M1 0V60" stroke="#A85E2E" stroke-dasharray="5 5" />
                    </svg>
                    <div class="card-t-r">
                        <span>法律咨询-代金券</span>
                    </div>
                </div>
                <div class="solid"></div>
                <div class="card-b">
                    <div class="card-b-l">有效期至：</div>
                    <div class="card-b-r">{{ item.validUntil | timeFormat('yyyy-mm-dd') }}</div>
                </div>
                <div class="guoqi">过期</div>
            </div>
        </div>



        <!-- 电子合同代金券 -->
        <div v-if="item.type == 2">
            <!-- 未使用 -->
            <div class="card  hetongStyle" v-if="item.state === 0">
                <div class="card-t">
                    <div class="card-t-l">
                        <div class="value">
                            {{ item.amount }}</div>
                        <div class="icon">元</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60" fill="none">
                        <path opacity="0.5" d="M1 0V60" stroke="#0062FF" stroke-dasharray="5 5" />
                    </svg>
                    <div class="card-t-r">
                        <span>电子合同-代金券</span>
                    </div>
                </div>
                <div class="solid"></div>
                <div class="card-b">
                    <div class="card-b-l">有效期至：</div>
                    <div class="card-b-r">{{ item.validUntil | timeFormat('yyyy-mm-dd') }}</div>
                </div>
            </div>
            <!-- 已使用 -->
            <div class="card  hetongStyle card1" v-if="item.state === 1">
                <div class="card-t">
                    <div class="card-t-l">
                        <div class="value">
                            {{ item.amount }}</div>
                        <div class="icon">元</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60" fill="none">
                        <path opacity="0.5" d="M1 0V60" stroke="#0062FF" stroke-dasharray="5 5" />
                    </svg>
                    <div class="card-t-r">
                        <span>电子合同-代金券</span>
                    </div>
                </div>
                <div class="solid"></div>
                <div class="card-b">
                    <div class="card-b-l">使用日期：</div>
                    <div class="card-b-r">{{ item.usedAt | timeFormat('yyyy-mm-dd') }}</div>
                </div>
            </div>
            <!-- 已过期 -->
            <div class="card  hetongStyle card2" v-if="item.state === 2">
                <div class="card-t">
                    <div class="card-t-l">
                        <div class="value">
                            {{ item.amount }}</div>
                        <div class="icon">元</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60" fill="none">
                        <path opacity="0.5" d="M1 0V60" stroke="#0062FF" stroke-dasharray="5 5" />
                    </svg>
                    <div class="card-t-r">
                        <span>电子合同-代金券</span>
                    </div>
                </div>
                <div class="solid"></div>
                <div class="card-b">
                    <div class="card-b-l">有效期至：</div>
                    <div class="card-b-r">{{ item.validUntil | timeFormat('yyyy-mm-dd') }}</div>
                </div>
                <div class="guoqi">过期</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => ({
                state: null,
                validUntil: null,
                type: null,
                amount: null,
                voucherId: null
            })
        }
    },
    components: {},
    data() {
        return {

        };
    },
    created() { },
    computed: {},
    methods: {},
};
</script>

<style scoped lang='scss'>
.voucherItem {

    // 未使用样式
    .card {
        border-radius: 8px;
        overflow: hidden;
        margin-right: 30px;
        margin-bottom: 20px;
        border: 1px solid #F4C283;
        
        &-t {
            height: 88px;
            background-image: url(~@/static/image/accountCenter/voucher_1.png);
            // background: linear-gradient(90deg, #FEEACD 0%, #FBD19C 100%);
            background-size: 100% auto;
            display: flex;
            align-items: center;

            &-l {
                width: 147px;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                .value {
                    color: #A85E2E;
                    font-family: "DIN Alternate";
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .icon {
                    color: #A85E2E;
                    font-family: "Source Han Sans CN";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 40px;
                    margin-left: 6px;

                }
            }

            &-r {
                font-family: "Source Han Sans CN";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: #A85E2E;
                margin-left: 30px;
            }
        }

        .solid {
            background: linear-gradient(90deg, #FFFAF1 0%, #FED993 100%);
            width: 100%;
            height: 1px;
            flex-shrink: 0;
        }

        &-b {
            width: 420px;
            background: linear-gradient(90deg, #F6D8AA 0%, #F4C283 100%);
            display: flex;
            padding: 0 22px;
            align-items: center;
            justify-content: space-between;
            height: 32px;
            color: #93502B;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .guoqi {
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(90deg, #BC6416 3.33%, #914F1A 100%);
            ;
            border-radius: 0 8px 0 4px;
            font-size: 10px;
            color: #fff;
            width: 32px;
            height: 18px;
            text-align: center;
            line-height: 18px;
        }
    }

    // 已使用样式
    .card1 {
        opacity: 0.5;

        .card-t {
            &-l {
                .value {
                    text-decoration: line-through;
                }
            }

            &-r {
                text-decoration: line-through;
            }
        }

    }

    // 已过期样式
    .card2 {
        border: 1px solid #F5F5F5;
        opacity: 0.5;
        position: relative;
       



        .card-t {
            background-image: url(~@/static/image/accountCenter/voucher_2.png);


        }

        .solid {
            background: #EEE;
        }

        .card-b {
            background: #F5F5F5 !important;
        }
    }

    // 合同未使用样式
    .hetongStyle {
        border: 1px solid #D6E8FF;

        .card-t {
            // background: linear-gradient(90deg, #EFF6FF 0%, #D6E8FF 100%);
            background-image: url(~@/static/image/accountCenter/voucher_3.png);
            &-l {
                .value {
                    color: #003B99;
                }

                .icon {
                    background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            &-r {
                color: #003B99;
            }
        }

        .solid {
            background: #FFF;
            opacity: 0.8;
        }

        .guoqi {
            background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
        }

        .card-b {
            background: linear-gradient(90deg, #0062FF 0%, #003B99 100%);
            color: #fff;
        }
    }

    // 合同已使用样式
    .hetongStyle.card1 {
        opacity: 0.5;

        .card-b {
            color: #003B99;
        }
    }

    // 合同已过期样式
    .hetongStyle.card2 {
        opacity: 0.5;
        border: 1px solid #F5F5F5;
        background-image: url(~@/static/image/accountCenter/voucher_4.png);
        .card-b {
            color: #003B99;
        }
    }
}
</style>