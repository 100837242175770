<template>
  <div id="app">
    <el-alert v-show="isDev" title="当前为测试环境，所有数据没有法律效力，如需使用正常功能，请联系客服" type="warning" :closable="false">
    </el-alert>
    <router-view />
    <AI />
    <!-- <seting /> -->
  </div>
</template>
<script>
import im from "@/mixins/im"
import AI from "@/components/AI/AI.vue"
// import seting from "@/components/seting.vue"
export default {
  mixins: [im],
  components: {
    AI
  },
  data() {
    return {
      isDev: false,
    }
  },
  mounted() {

  },
  created() {
    if (process.env.VUE_APP_TYPE == 'dev') {
      this.isDev = true
    }

  },
  methods: {
  }
}

</script>
<style lang="scss">
@import "~@/assets/style/reset.scss";
@import "~@/assets/style/var.scss";

html,
body {
  width: 100%;
  // height: 100vh;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

#app {
  widows: 100vh;
  // height: 100vh;
  background: #F3F6F8;
}

.primary {
  color: $--color-primary !important;
}

.pointer {
  cursor: pointer;
}

.keep2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.viewer-title {
  font-size: 30px !important;
  font-weight: 700 !important;
}

.border {
  background: #ffffff;
  border-radius: 4px;
  background-color: #fff;
}

.DEVnotify {
  width: 400px !important;
  cursor: pointer;

  &_iconClass {
    width: 60px !important;
    height: 60px !important;
    display: block;
    background-image: url(~@/static/profile_picture.png);
    background-size: 60px;

  }

  .el-notification__group {
    width: calc(400px - 100px - 13px - 8px) !important;
  }
}

.service-code {
  background-image: url(~@/static/code.jpg);
  background-size: 100%;
}
</style>
