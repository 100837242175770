import userinfo from "@/mixins/userinfo.js"
export default {
    mixins: [userinfo],
    methods: {
        authFn({ authority }) {

            if (!authority || !authority.length) {
                return true
            }


             // 企业实名才显示
            if (authority.includes('companyAuth')) {
                return this.companyAuth
            }
            //  法人不显示
            if (authority.includes('unCreator')) {
                return !this.user.creator
            }
            //  只有法人显示
            if (authority.includes('creator')) {
                return this.user.creator
            }
            return false
        }
    }
}