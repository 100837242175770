/*
* @Author: 曹俊杰
* @Date: 2024-10-10 22:12:18
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-11-17 12:14:02
*/课程

<template>
    <div class="courseBox">
        <router-view></router-view>
        <!-- <div class="courseBox__title">其他法务知识</div> -->
        <courseList v-show="false"/>
    </div>
</template>

<script>
import courseList from "@/views/Course/components/courseList"
export default {
    components: {
        courseList
    }

}
</script>

<style lang="scss" scoped>
.courseBox {
    width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    &__title {
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
    }
}
</style>