import detail from '@/views/Equity/components/detail.vue'
export default {
    components:{detail},
    data() {
        return {
            list: [],
            listParams: {
                keyword: '',
                type: ''
            }
        };
    },
    mounted(){
        this.getList()
    },
    methods: {
        async getList() {
            const { current, size } = this.$refs.pagination
            const { listParams } = this
            const { status, data } = await this.$api.getEnforceList({ current, size, ...listParams })
            if (status == 200) {
                this.list = data.records;
                this.$refs.pagination.createPagination(data)
            }

        },
        todetail(res){
                // console.log("打开详情",res)
                this.$refs.detail.show(res)
        }
    }
}