/*
* @Author: 曹俊杰
* @Date: 2022-05-25 10:28:21
* @Module: 人员选择
*/
<template>
  <div class="personnelSelection">
    <el-dialog destroy-on-close :close-on-click-modal="false" :title="title" :visible="visible" width="450px"
      @close="close">
      <el-form :model="form" :rules="rules" ref="form" @submit.native.prevent>
        <el-form-item prop="phone" :error="error">
          <el-input clearable suffix-icon="el-icon-search" :disabled="(!multiple) && (list.length > 0)" v-model="form.phone"
            placeholder="添加用户的手机号"></el-input>
        </el-form-item>
        <div style="margin-top: 10px;">
          <el-tag style="margin-right:8px;" :key="item.empUuid" v-for="item in list" closable
            :disable-transitions="false" @close="deleteUser(item)">
            {{ item.empName }}
          </el-tag>
        </div>
        <el-form-item prop="verifyCode" label="验证码" v-show="list.length">
          <el-input type="text" v-model="form.verifyCode" auto-complete="off" placeholder="请输入验证码">
            <i slot="suffix" style="font-size: 14px;color:#0062FF;margin-right:15px;" @click="getcode">{{ secondrender
              }}</i>
          </el-input>
        </el-form-item>
      </el-form>

      <slot></slot>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="!list.length" @click="submit" :loading="loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import second from "@/mixins/second.js"
export default {
  mixins: [second],
  components: {},
  props: {
    title: {
      type: String,
      default: '选择用户'
    },
    visible: {
      type: Boolean
    },
    validate: {
      type: Array,
      default: () => (['companyName'])
    },
    multiple: {   // 多选
      type: Boolean,
      default: true
    }
  },
  data() {
    const phoneValidator = (_, value, callback) => {
      const pushList = (data) => {
        const index = this.list.findIndex(item => item.empUuid == data.empUuid);
        if (index < 0) {
          this.list.push(data);
        }
      }
      if (this.$utils.test.mobile(value)) {
        this.getAccountValidate(({ status, data, info }) => {
          if (status === 200) {
            if (this.$utils.test.empty(data.empName)) {
              return callback(new Error('该用户未实名'));
            } else if (this.$utils.test.empty(data.companyName)) {
              if (this.validate.includes('companyName')) {
                return callback(new Error('该用户未加入公司'));
              } else {
                pushList(data)
                return callback();
              }
            } else {
              pushList(data)
              return callback();
            }
          } else {
            return callback(new Error(info));
          }
        })
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    return {
      loading: false,
      dialogVisible: true,
      form: {
        phone: '',
        verifyCode: ''
      },
      rules: {
        phone: [
          { validator: phoneValidator, trigger: ['change'] },
          { required: true, message: '手机号不能为空', trigger: ['change'] }
        ]
      },
      error: "",
      list: []
    };
  },
  mounted() { },
  methods: {
    submit() {
      // console.log(this.list)
      if (this.list.length) {
        this.$emit("submit", this.list, this.form.verifyCode)
      } else {
        this.$message({
          type: 'error',
          message: `请正确的${this.title}`
        })
      }
    },
    close() {
      this.$emit("update:visible", false)
    },
    async getAccountValidate(callback) {
      const { status, data, info } = await this.$api.getAccountValidate(this.form);
      callback && callback({ status, data, info })
    },
    deleteUser({ empUuid }) {
      const index = this.list.findIndex(item => item.empUuid == empUuid)
      this.list.splice(index, 1)
    },
    async getcode() {
      if (this.second !== 0) {
        return;
      }

      const { status, info } = await this.$api.postAddEmp({ empUuid: this.list[0].empUuid });
      if (status == 200) {
        this.$message({
          message: info,
          type: 'success'
        });
        this.secondrun();
      } else {
        this.$message({
          message: info,
          type: 'error'
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped></style>