import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import './assets/style/iconfont.css'
import install from '@/utils/install.js'
import store from './store'
import VueClipboard from 'vue-clipboard2'
Vue.config.productionTip = false
const app = new Vue({
	store,
	router,
	render: h => h(App)
})
Vue.prototype.$oss = "https://oss.mcn-open.com/"
Vue.use(install, app)
Vue.use(VueClipboard)
app.$mount('#app')

