<template>
    <div class="box">
        <div class="title">合同锁</div>
        <div class="content">
            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { insuranceState: 0 } })">
                <div class="label">未保障</div>
                <div class="value">{{ item.unprotectedCount }}</div>
            </div>
            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { insuranceState: 2 } })">
                <div class="label">保障中</div>
                <div class="value">{{ item.protectedCount }}</div>
            </div>
            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { insuranceState: 5 } })">
                <div class="label">部分退款</div>
                <div class="value">{{ item.partialRefundCount }}</div>
            </div>
            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { insuranceState: 6 } })">
                <div class="label">全额退款</div>
                <div class="value">{{ item.fullRefundCount }}</div>
            </div>

            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { insuranceState: 3 } })">
                <div class="label">已到期</div>
                <div class="value">{{ item.expiredCount }}</div>
            </div>
            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { insuranceState: 4 } })">
                <div class="label">启动维权</div>
                <div class="value">{{ item.insuranceInProcessCount }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            item: state => state.electronic.contractProfile
        }),
    }
}
</script>

<style lang="scss" scoped>
@import "~@/views/Home/hetong.scss";

.item {
    width: calc(100% / 6) !important;
}
</style>