<template>
    <el-avatar :size="size" :src="avatarSrc"></el-avatar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        size: {
            type: Number,
            default: 80
        },
        src: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('user', ['profilePicture']),
        avatarSrc() {
            if (this.src) {
                return this.$oss + this.src
            } else {
                return this.profilePicture
            }
        }
    }


}
</script>

<style></style>