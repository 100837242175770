<template>
    <div class="tuiguang ">
        <el-dialog :visible.sync="dialogVisible" width="912px">
            <div class="content">
                <a class="link"  v-popover="'kefu'"></a>
                <el-popover ref="kefu" placement="bottom" width="200" trigger="hover">
                    <div style="width:176px;height:176px;" class="service-code"></div>
                </el-popover>
            </div>

        </el-dialog>
    </div>

</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        kefu() {
            this.dialogVisible = false
            this.$im.open()
        },
        show() {
            this.dialogVisible = true
        }
    }
}
</script>

<style lang="scss" scoped>
.tuiguang {

    /deep/.el-dialog {
        &__header,
        &__body {
            padding: 0;
        }
    }

    .content {

        height: 520px;
        background-image: url(~@/static/image/accountCenter/tuiguangbg.png);
        position: relative;
        background-size: 100%;
        background-repeat: no-repeat;

        .link {
            display: block;
            width: 270px;
            height: 25px;
            position: absolute;
            bottom: 55px;
            left: 0;
            right: 0;
            margin: auto;
            cursor: pointer;
        }
    }
}
</style>