<template>
    <div class="box">

        <div class="person"></div>

        <div class="top">
            <div class="title">中能E问（法律咨询）</div>
            <div class="time">工作日 12:00 - 22:00 为您服务</div>
            <div class="line"></div>
        </div>
        <div class="content">
            <div class="content_1">—— 累计服务直播公司 ——</div>
            <div class="content_2">
                <div class="content_2_l">{{ totalAdvisory }} </div>
                <div class="content_2_r">家</div>
            </div>
            <div class="content_3" @click="triggerMessage" style="margin-bottom: 10px;">进入服务</div>
            <!-- <div class="content_4">为直播公司保驾护航</div> -->
        </div>
        <div class="bottom" :class="{ none: serviceState.key === 0 }">
            <div class="bottom_l">
                <div class="bottom_l_l"></div>
                <div class="bottom_l_r">
                    <div class="bottom_l_r_t">咨询VIP</div>
                    <div class="bottom_l_r_b">
                        <span>{{ serviceState.value }}</span>
                        <span v-if="serviceState.key == 1">
                            <span style="margin: 0 4px;">｜</span>
                            <span>{{ user.serviceUntil | timeFormat('yyyy-mm-dd') }} 到期</span>
                        </span>

                    </div>
                </div>
            </div>
            <div class="bottom_r" v-if="serviceState.key === 0"
                @click="$router.push({ path: '/accountCenter/advisory?buy=1' })">
                <div>立即开通</div>
                <p class="el-icon-arrow-right"></p>
            </div>
            <div class="bottom_r" v-else @click="$router.push({ name: 'advisory' })">
                <div>管理</div>
                <p class="el-icon-arrow-right"></p>
            </div>
        </div>
    </div>
</template>

<script>
import userinfo from "@/mixins/userinfo.js"
export default {
    mixins: [userinfo],
    data(){
        return{
            totalAdvisory:0
        }
    },
    computed: {
        randomNumber() {
            const baseValue = 4000; // 初始用户数
            const maxDailyIncrement = 50; // 每天最多增长 200
            const scaleFactor = maxDailyIncrement / 86400000; // 每毫秒的增量，86400000 毫秒为一天

            // 获取统一的时间基准，可以是一个固定的时间，比如从 1970 年 1 月 1 日算起
            const startDate = new Date("2024-12-25T00:00:00Z").getTime(); // 使用 UTC 时间作为基准
            const timestamp = Date.now(); // 当前时间戳（毫秒）

            // 计算自 startDate 以来的时间差
            const elapsedMilliseconds = timestamp - startDate;

            // 计算用户数量（确保每天最多增加 200）
            const newUserCount = baseValue + Math.floor(elapsedMilliseconds * scaleFactor);

            return newUserCount;
        }
    },
    mounted() {
        this.getAdvisoryProfile();
    },
    methods: {
        triggerMessage() {
            // this.$im.url()
            this.$im.open()
        },
        async getAdvisoryProfile() {
            const {data} = await this.$api.getAdvisoryProfile()
            console.log(data)
            if(data.totalAdvisory){
                console.log("totalAdvisory",data.totalAdvisory)
                this.totalAdvisory = data.totalAdvisory
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$--person-offset: 22px;

.box {
    background-image: url(~@/static/image/home/home_bg_2.png);
    height: 380px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 $--person-offset;
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    // background-color: #d43e2c;
    .person {
        background-image: url(~@/static/image/home/home_bg_3.png);
        background-size: auto 156px;
        background-repeat: no-repeat;
        background-position: bottom right;
        position: absolute;
        top: 0;
        right: 4px;
        height: 156px;
        width: 127px;
        z-index: 2;
    }

    .top {
        padding: 20px;
        padding-top: 42px;


        .title {
            margin-top: 14px;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: linear-gradient(90deg, #EFD069 0%, #F8EFAC 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .time {
            color: #FDEAAC;
            font-family: "Source Han Sans CN";
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 4px;
            letter-spacing: .6px;

        }

        .line {
            background: linear-gradient(90deg, #EFD069 0%, #F8EFAC 33%, #EFD069 68%, #F8EFAC 100%);
            width: 65%;
            height: 1.5px;
            flex-shrink: 0;
            margin-top: 10px;
        }

    }

    .content {
        width: calc(100% - (20px * 2));
        height: 164px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #fff;
        background-image: url(~@/static/image/home/home_bg_5.png);
        background-size: 100% auto;
        margin: 0 20px;
        z-index: 3;
        position: absolute;
        padding: 20px 0;
        padding-bottom: 9px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &_1 {
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &_2 {
            color: #262626;
            font-family: "DIN Alternate";
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            align-items: flex-end;

            &_r {
                color: #262626;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                line-height: 30px;
                margin-left: 10px;
            }
        }

        &_3 {
            width: 116px;
            height: 32px;
            flex-shrink: 0;
            border-radius: 4px;
            background: linear-gradient(327deg, #E45844 0%, #B60E00 100%);
            color: #FFF;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            cursor: pointer;

        }

        &_4 {
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 2px;
        }
    }

    .bottom {
        width: calc(100% - (20px * 2));
        height: 60px;
        flex-shrink: 0;
        position: absolute;
        top: 303px;
        margin: 0 20px;
        background-image: url(~@/static/image/home/home_bg_4.png);

        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 8px;

        &_l {
            display: flex;

            &_l {
                width: 65px;
                height: 53.368px;
                background-image: url(~@/static/image/home/home_bg_6.png);
                background-size: 100%;

            }

            &_r {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &_t {
                    font-family: "Source Han Sans CN";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(90deg, #974D1C 0%, #311909 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                &_b {
                    color: #974D1C;
                    font-family: "Source Han Sans CN";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 4px;
                }
            }
        }

        &_r {
            display: flex;
            color: #974D1C;
            font-family: "Source Han Sans CN";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-items: center;
            cursor: pointer;

            p {
                margin-left: 4px;
            }
        }
    }

    .none {
        background-image: url(~@/static/image/home/home_bg_7.png);

        .bottom_l {
            &_l {
                background-image: url(~@/static/image/home/home_bg_8.png);
            }
        }

    }


}
</style>