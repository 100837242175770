import store from '../store'
import { Message } from 'element-ui';
import im from "@/utils/im"

export function setupPermissionGuard (router) {
	router.beforeEach(async (to, from, next) => {
		const { state, commit, dispatch } = store;

		// 不需要登录,可访问
		if (to.meta.ignoreAuth === true) {
			next();
			return;
		}


		const hasToken = !!state.user.user.token;
		if (!hasToken) {
			next({
				path: '/login',
				replace: true,
				query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})
			return
		}

		next();

		// next();
	});

	router.afterEach((to, from) => {
		if (to.meta?.title) {
			document.title = `中能E签 - ${to.meta.title}`
		}
	})
}


// if (['expenseCenter', 'electronicDocuments', 'exposure', 'archiveManagement', 'inspectSearch', 'inspect', 'sealManagement', 'accountColleague'].includes(to.meta.menu)) {
// 	const { company, creator } = store.state.user.user
// 	if (company) {
// 		if (['accountColleague', 'sealManagement'].includes(to.meta.menu)) {
// 			if (creator) {
// 				next()
// 			} else {
// 				Message({
// 					message: '此功能需要法人账号操作',
// 					type: 'warning'
// 				})
// 			}
// 		} else {
// 			next()
// 		}
// 	} else {
// 		next({
// 			path: '/auth/CompanyAuth'
// 		})
// 		Message({
// 			message: '此功能需加入/创建企业',
// 			type: 'warning'
// 		})
// 	}
// } else {
// 	next()
// }
// next()