/*
* @Author: 曹俊杰
* @Date: 2024-10-11 11:53:19
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-12-19 02:12:43
*/购买菜单
<template>
    <div class="dialog">
        <el-dialog :visible.sync="dialogVisible" width="20%" title="订单支付" @close="close" append-to-body>
            <div class="dialog__title" style="background-color: #F3F6F8;">订单信息</div>
            <div class="dialog__item">
                <div class="dialog__item__l">{{ parme.name }}：</div>
                <div class="dialog__item__r">¥ {{ parme.price |priceFormat}}</div>
            </div>
            <div class="dialog__item">
                <div class="dialog__item__l">总金额：</div>
                <div class="dialog__item__r blue">¥ {{ parme.price|priceFormat }}</div>
            </div>
            <div class="dialog__title" style="margin-top: 20px;background-color: #F3F6F8;" >支付方式</div>
            <div class="dialog__item">
                <div class="dialog__item__l">余额支付(当前余额：¥ {{ parme.balance |priceFormat}})：</div>
                <div class="dialog__item__r red">¥ {{ parme.price|priceFormat }}</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="getSubmit" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
export default {
    props:{
        getSubmit:{
            type:Function,
            default:()=>({})
        }
    },
    data() {
        return {
            dialogVisible: false,
            parme: {
                name: '',
                price: null,
                balance: null,
            }
        }
    },
    methods: {
        show(parme) {
            this.parme = parme
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog {
    .blue {
        color: #0062FF;
    }

    .red {
        color: #FF0D0D;
    }

    /deep/.el-dialog {
        background: #F3F6F8
    }

    &__title {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        background: #fff;
        padding: 0 16px;

    }

    &__item {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__l {}

        &__r {}
    }
}
</style>
