/*
* @Author: 曹俊杰
* @Date: 2024-10-13 08:57:59
* @Last Modified by: 曹俊杰
* @Last Modified time: 2024-11-22 11:52:24
*/合同锁选择
<template>
  <div class="insuranceSelect">
    <div class="nav">
      <div class="item" v-for="(item, index) in navlist" :key="item.key" :class="{ active: item.key == grade }" @click="navclick(item)">
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="30" viewBox="0 0 19 30" fill="none">
          <path
            d="M7.5996 28.5754C7.6356 28.338 8.433 27.2125 12.1974 26.8072C13.7043 26.6444 15.7432 27.1405 18.3115 28.2953C17.2447 29.0527 15.9152 29.5648 14.3243 29.8341C10.1785 30.5342 7.85563 28.8047 7.6196 28.594L7.5996 28.5754ZM2.62304 20.6865C2.63504 20.6025 4.72993 19.8384 8.4997 21.7319C9.86651 22.4173 11.2667 23.6735 12.7002 25.5003C10.8306 25.7643 9.15308 25.635 7.66626 25.1136C4.5406 24.0162 2.6057 20.8145 2.62304 20.6865ZM0.0147187 12.5149C0.125387 12.4495 3.80045 13.0563 5.98204 15.5606C7.0195 16.7527 7.76755 18.2689 8.22495 20.1117C6.0047 19.4596 4.3232 18.6249 3.17907 17.6061C0.022751 14.7978 -0.058602 12.5562 0.0147187 12.5149ZM1.76557 6.09415C1.87893 6.05015 4.83257 7.53431 6.57276 10.1106C7.39954 11.3374 7.80359 12.8616 7.78224 14.6871C5.81671 13.3496 4.50853 12.3068 3.85515 11.5614C1.47619 8.84114 1.69222 6.1235 1.76557 6.09415ZM6.7435 0.000187428C7.10085 0.0708516 8.90506 2.46846 9.15843 5.43145C9.27844 6.83961 9.02241 8.24242 8.39034 9.63858C7.01284 7.65704 6.22876 5.83415 6.03674 4.16731C5.65404 0.829593 6.66615 -0.0145017 6.7435 0.000187428Z"
            fill="url(#paint0_linear_245_10542)" />
          <defs>
            <linearGradient id="paint0_linear_245_10542" x1="9.15573" y1="0" x2="9.15573" y2="30" gradientUnits="userSpaceOnUse">
              <stop offset="0.145" stop-color="#FFD189" />
              <stop offset="1" stop-color="#E0BF6C" />
            </linearGradient>
          </defs>
        </svg>
        <div :class="'style' + index">
          {{ item.title }}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="30" viewBox="0 0 19 30" fill="none">
          <path
            d="M11.0233 28.5754C10.9873 28.338 10.1899 27.2125 6.42547 26.8072C4.91862 26.6444 2.87973 27.1405 0.311443 28.2953C1.37822 29.0527 2.70771 29.5648 4.29855 29.8341C8.44435 30.5342 10.7673 28.8047 11.0033 28.594L11.0233 28.5754ZM15.9999 20.6865C15.9879 20.6025 13.893 19.8384 10.1232 21.7319C8.75639 22.4173 7.35623 23.6735 5.92273 25.5003C7.79228 25.7643 9.46981 25.635 10.9566 25.1136C14.0823 24.0162 16.0172 20.8145 15.9999 20.6865ZM18.6082 12.5149C18.4975 12.4495 14.8224 13.0563 12.6409 15.5606C11.6034 16.7527 10.8553 18.2689 10.3979 20.1117C12.6182 19.4596 14.2997 18.6249 15.4438 17.6061C18.6001 14.7978 18.6815 12.5562 18.6082 12.5149ZM16.8573 6.09415C16.744 6.05015 13.7903 7.53431 12.0501 10.1106C11.2234 11.3374 10.8193 12.8616 10.8407 14.6871C12.8062 13.3496 14.1144 12.3068 14.7677 11.5614C17.1467 8.84114 16.9307 6.1235 16.8573 6.09415ZM11.8794 0.000187428C11.522 0.0708516 9.71784 2.46846 9.46447 5.43145C9.34445 6.83961 9.60048 8.24242 10.2326 9.63858C11.6101 7.65704 12.3941 5.83415 12.5862 4.16731C12.9689 0.829593 11.9567 -0.0145017 11.8794 0.000187428Z"
            fill="url(#paint0_linear_245_10544)" />
          <defs>
            <linearGradient id="paint0_linear_245_10544" x1="9.46717" y1="0" x2="9.46717" y2="30" gradientUnits="userSpaceOnUse">
              <stop offset="0.145" stop-color="#FFD189" />
              <stop offset="1" stop-color="#E0BF6C" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <el-checkbox v-show="showclose" @change="close" :value="insuranceId === 0">放弃保障</el-checkbox>
    </div>
    <div class="content_box" :class="`content_type${grade}`">
      <div class="content_box_item" v-for="item in list" :class="{ active: insuranceId == item.insuranceId }" :key="item.insuranceId" @click="itemClick(item)">
        <div class="content_box_item_1">{{ item.title }}</div>
        <div class="content_box_item_2">{{ item.label }}</div>
        <div class="content_box_item_3">
          <div class="price">{{ item.price }}</div>
          <span style="margin-left: 6px;">元</span>
          <span style="color: #999;">/案</span>
        </div>
        <div class="content_box_item_4">{{ item.detail }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    showclose: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      navlist: [
        {
          title: '基础方案',
          key: 1
        },
        {
          title: '进阶方案',
          key: 2
        },
        {
          title: '高级方案',
          key: 3
        }
      ],
      list: []
    }
  },
  computed: {
    ...mapState('insuranceSelect', ['insuranceId', 'title', 'insuranceVersion', 'grade']),
  },
  mounted() {
    this.getContractInsuranceList()
  },
  methods: {
    navclick({ key }) {
      this.$store.dispatch("insuranceSelect/setGrade", key)
      this.getContractInsuranceList()
    },
    itemClick({ insuranceId, title, version,price }) {
      this.$store.dispatch("insuranceSelect/setInsuranceId", insuranceId)
      this.$store.dispatch("insuranceSelect/setTitle", title)
      this.$store.dispatch("insuranceSelect/setInsuranceVersion", version)
      this.$store.dispatch("insuranceSelect/setPrice", price)
    },
    async getContractInsuranceList() {
      const { grade } = this
      const { status, data } = await this.$api.getContractInsuranceList({ grade })

      if (status == 200) {
        this.list = data
      }
    },
    async close() {
      if (this.insuranceId === 0) {
        return
      }
      try {
        await this.$alert('放弃保障，将不能提前锁定维权成本！确定放弃吗？', '提示', {
          showCancelButton: true,
          cancelButtonText: '我再想想',
          confirmButtonText: '确定放弃'
        })
        this.$store.dispatch("insuranceSelect/setInsuranceId", 0)
        this.$store.dispatch("insuranceSelect/setTitle", '放弃保障')
        this.$store.dispatch("insuranceSelect/setInsuranceVersion", null)
        this.$store.dispatch("insuranceSelect/setGrade", null)
        this.$store.dispatch("insuranceSelect/setPrice", 0)
      } catch (res) {
        console.log(res)
      }

      // this.$store.commit("electronic/fangqibaozhangFn", res)
    }
  }

}
</script>

<style lang="scss" scoped>
.insuranceSelect {
  .nav {
    display: flex;
    align-items: center;

    .item {
      width: 200px;
      height: 60px;
      flex-shrink: 0;
      line-height: 60px;
      text-align: center;
      color: #999;
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.176px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 18.311px;
        height: 30px;
        opacity: 0;
      }
    }

    .active {
      svg {
        opacity: 1;
      }

      .style0 {
        background: linear-gradient(97deg, #0062ff -0.18%, #003b99 99.82%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .style1 {
        background: linear-gradient(118deg, #ff8412 0.44%, #994f0b 100.44%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .style2 {
        background: linear-gradient(97deg, #bf59f7 -0.18%, #311256 99.82%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .content {
    &_type1 {
      .price {
        background: linear-gradient(180deg, #0062ff 0%, #003b99 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .active {
        border: 2px solid #1f5aec;
        background: #eff6ff;
      }
    }

    &_type2 {
      .price {
        background: linear-gradient(113deg, #ff8412 4.92%, #994f0b 94.37%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .active {
        border: 2px solid #ff8412;
        background: #fff6ee;
      }
    }

    &_type3 {
      .price {
        background: linear-gradient(96deg, #bf59f7 0.98%, #311256 97.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .active {
        border: 1.5px solid #bf59f7;
        background: #faf1ff;
      }
    }
  }

  .content_box {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 10px;

    &_item:nth-child(3n - 1) {
      margin: 0 20px;
    }

    &_item {
      width: calc((100% - (20px * 2)) / 3);
      height: 249px;
      border-radius: 4px;
      border: 1.5px solid #fff;
      background: linear-gradient(90deg, #fff 0%, #f8f8f8 100%);
      padding: 20px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      cursor: pointer;

      &_1 {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_2 {
        color: #999;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 171.429% */
        letter-spacing: -0.154px;
      }

      &_3 {
        display: flex;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.154px;
        align-items: flex-end;

        .price {
          font-family: "DIN Alternate";
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.396px;
          line-height: 36px;
        }
      }

      &_4 {
        font-family: "Source Han Sans CN";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        /* 214.286% */
        letter-spacing: -0.154px;
      }
    }
  }
}
</style>
