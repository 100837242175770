<template>
    <div class="box">
        <div class="title">
            <p>待归档合同</p>
            <p class="sec">指本人账号发起成功的合同没有进行归档</p>
        </div>
        <ul class="tabs">
            <li v-for="item in tabs" :key="item.value" class="item" :class="{ active: item.value === listParams.state }"
                @click="tabsClick(item)">
                {{ item.label }}
                <div class="solid"></div>
            </li>
        </ul>
        <div class="tableBox">
            <el-table :data="list" style="width: 100%;background:transparent">
                <el-table-column prop="name" label="合同名称" width="220">
                    <template slot-scope="scope">
                        <span style="color:#0062FF">{{ scope.row.subject }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createdAt" label="发起时间" width="180">
                    <template slot-scope="scope">
                        <span class="pointer moving">{{ scope.row.createdAt | timeFormat }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="operatorName" label="发起方">
                    <template slot-scope="{row}">
                        <span>{{ row.signers[0].operatorName || "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="operatorName" label="签署方">
                    <template slot-scope="{row}">
                        <span>{{ row.signers[1].operatorName || "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="合同状态">
                    <template slot-scope="scope">
                        <qianshuIcon :value="scope.row.state" />
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="{row}">
                        <div class="btn" @click="guidang(row)">待归档</div>
                    </template>
                </el-table-column>
            </el-table>
            <hdqPagination ref="pagination" small layout="prev, pager, next" :size="5" @change="getContract"/>
        </div>
        <dirDialog ref="dirDialog" @success="getContract" />
    </div>
</template>

<script>
import hdqPagination from "@/components/hdqPagination.vue"
import selectData from "@/mixins/selectData.js"
import qianshuIcon from "@/components/qianshuIcon"
import dirDialog from "@/views/ElectronicDocuments/components/dirDialog"
import { mapActions } from "vuex";
export default {
    mixins: [selectData],
    components: {
        hdqPagination,
        qianshuIcon,
        dirDialog
    },
    data() {
        return {
            listParams: {
                state: "",
                dirId: -1,
            },
            list: []
        }
    },
    mounted() {
        this.getContract();
        this.getArchiveList();
    },
    computed: {
        tabs() {
            return [
                {
                    label: "全部",
                    value: ''
                },
                ...this.contractStateList
            ]
        }
    },
    methods: {
        ...mapActions('documents', ['getArchiveList']),
        tabsClick({ value }) {
            this.listParams.state = value;
            this.getContract()
        },
        async getContract() {
            const { current,size } = this.$refs.pagination
            const listParams = this.listParams;
            const { status, data } = await this.$api.getContract({ current,size, ...listParams })
            if (status === 200) {
                const { records } = data
                this.list = records
                this.$refs.pagination.createPagination(data)
            }
        },
        guidang(data) {
            console.log(data)
            // this.$refs.dirDialog.show(data)
            this.$router.push({ path: '/electronicDocumentsList/-1' })
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    margin-top: 16px;
    height: 500px;
    flex-shrink: 0;
    background-image: url(~@/static/image/home/home_bg_1.png);
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 1px;
    background-color: #fff;

    .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: absolute;
        top: 24px;
        left: 20px;

        .sec {
            color: #999;
            font-family: "Source Han Sans CN";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 4px;
        }
    }

    .tabs {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 24px;

        .item {
            margin-right: 40px;
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
        }

        .active {
            color: #0062FF;
            position: relative;

            .solid {
                width: 28px;
                height: 2px;
                flex-shrink: 0;
                background: #0062FF;
                position: absolute;
                bottom: -8px;
                left: 0;
                right: 0;
                margin: auto;

            }

        }



    }

    .tableBox {
        padding: 10px 20px;

        &/deep/.el-table {
            tr {
                background-color: transparent;
            }
        }
    }

    .btn {
        border-radius: 2px;
        border: 1px solid #0062FF;
        opacity: 0.45;
        color: #0062FF;
        /* 14px 正文文字 / 描述、按钮 / 常规 */
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        width: 78px;
        height: 32px;
        text-align: center;
    }
}
</style>