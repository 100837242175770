/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-27 18:08:01
 * @Module: 印章详情
 */
 <template>
  <div class="SealManagementDetail">
    <el-card shadow="never" :body-style="{position:'relative'}">
      <div slot="header" style="padding-left:24px;">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="line-height:50px;margin-top:10px;">
          <el-breadcrumb-item :to="{ path: '/enterprise/sealManagement' }">印章管理</el-breadcrumb-item>
          <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-table :data="list" style="width: 100%">
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column label="授权人">
          <template slot-scope="scope">
            {{scope.row.authorizerEmpName}}
          </template>
        </el-table-column>
        <el-table-column label="授权对象">
          <template slot-scope="scope">
            {{scope.row.authorizedEmpName}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="授权角色" width="140">
          <template slot-scope="scope">
            <span>
              <el-link type="primary" :underline="false" v-if="scope.row.sealRole=='SEAL_USER'">印章-使用员</el-link>
              <el-link type="warning" :underline="false" v-if="scope.row.sealRole=='SEAL_EXAMINER'">印章-审批员</el-link>
            </span>
          </template>
        </el-table-column> -->
        <el-table-column label="授权状态">
          <template slot-scope="scope">
            <span v-if="scope.row.authorizeStatus===null">
              <i style="color:#0062FF;" class="el-icon-ali-dian1"></i>
              <span>待审批</span>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="()=>openQrDialog(scope.row)">审批</el-button>
            </span>
            <span v-if="scope.row.authorizeStatus===0">
              <i style="color:#999999;" class="el-icon-ali-dian1"></i>
              <span>未开启</span>
            </span>
            <span v-if="scope.row.authorizeStatus===1">
              <i style="color:#67C23A;" class="el-icon-ali-dian1"></i>
              <span>已启用</span>
            </span>
            <span v-if="scope.row.authorizeStatus===3">
              <i style="color:#EF7D00;" class="el-icon-ali-dian1"></i>
              <span>待生效</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="有效期" width="320">
          <template slot-scope="scope">
            <span>
              {{scope.row.effectiveTime |timeFormat}}至
              {{scope.row.expireTime |timeFormat}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :resizable="false">
          <template slot-scope="scope">
            <el-link :underline="false" slot="reference" type="info" @click="()=>operate('delete',scope)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>

      <qrDialog ref="qrDialog" @close="qrDialogClose">
        <template>
          <span class="text">
            <span>请法人用</span>
            <span>支付宝APP</span>
            <span>扫码并按照提示完成签署</span>
          </span>
        </template>
      </qrDialog>

      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </el-card>
  </div>
</template>
 <script>
import hdqPagination from "@/components/hdqPagination.vue"
import qrDialog from "@/components/qrDialog.vue"
let js;
export default {
  components: {
    hdqPagination,
    qrDialog
  },
  data () {
    return {
      list: []
    };
  },
  mounted () { },
  methods: {
    async getSealAuthList (current = 1, size = 10) {
      const { sealId } = this.$route.params
      const { data, status } = await this.$api.getSealAuthList({ current, size, sealId })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    paginationChange ({ current, size }) {
      this.getSealAuthList(current, size)
    },
    operate (type, data) {
      if (type === 'delete') {
        console.log("shanchu")
        this.$confirm(`此操作将取消对${data.row.authorizedEmpName}的印章授权, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async (res) => {
          if (res == 'confirm') {
            const { status, info } = await this.deletetSealAuth(data.row);
            if (status === 200) {
              this.onDelete(data)
              this.$message({
                type: 'success',
                message: info
              });
            } else {
              this.$message({
                type: 'error',
                message: info
              })
            }
          }
        }).catch(() => ({}));
      }

    },
    deletetSealAuth ({ uuid }) {
      return this.$api.deletetSealAuth({ uuid })
    },
    onDelete ({ row }) {
      const index = this.list.findIndex(item => item.uuid == row.uuid);
      console.log(index)
      this.list.splice(index, 1)
    },
    qrDialogClose () {
      js && clearInterval(js);
    },
    openQrDialog (data) {
      this.$refs.qrDialog.show(data.authorizationSignUrl);
      js = setInterval(async () => {
        const { data: sealData, status: sealStatus } = await this.$api.getSealAuth({ authUuid: data.uuid });
        if (sealStatus == 200 && (sealData.authorizeStatus !== null)) {
          const index = this.list.findIndex(item => item.uuid == sealData.uuid);
          this.list[index].authorizeStatus = sealData.authorizeStatus;
          this.$refs.qrDialog.hidden();
          clearInterval(js);
        }
      }, 1000)
    }
  },
  watch: {
    "$route.params": {
      handler () {
        this.$nextTick(() => {
          this.getSealAuthList()
        })
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
</style>