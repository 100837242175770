<template>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="450px" >
        <div>
            <el-form size="mini" label-position="top" :model="form" ref="form">
                <el-form-item label="法人手机号" prop="phone" :rules="[
                    { required: true, message: '请输入法人手机号', trigger: 'blur' },
                ]">
                    <el-input disabled="" placeholder="请输入法人手机号" v-model.number="phone"></el-input>
                </el-form-item>

                <el-form-item label="验证码" prop="verifyCode" :rules="[
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ]">
                    <el-input placeholder="请输入验证码" v-model="form.verifyCode">
                        <i slot="suffix" style="font-size: 14px;color:#0062FF;margin-right:15px;" class="pointer"
                            @click="postDeleteEmp()">{{ secondrender }}</i>
                    </el-input>

                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="mini">取 消</el-button>
            <el-button type="primary" @click="submit" size="mini">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import userinfo from '@/mixins/userinfo.js'
import second from '@/mixins/second.js'
export default {
    mixins: [userinfo, second],
    props: {
        list: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
    },
    computed: {
        phone() {
            return this.companyObj.representativePhone
        },
        // 操作的数据
        acData() {
            return this.list.length ? this.list.find(res => res.uuid === this.empUuid) : { name: null }
        },
        title() {
            return `删除员工`
        }
    },
    data() {

        return {
            dialogVisible: false,
            form: {
                verifyCode: null,
            },
            empUuid: null
        };
    },
    mounted() { },
    methods: {
        close() {
            this.dialogVisible = false
        },
        submit() {
            this.deleteAccount()
        },
        show(empUuid) {
            this.empUuid = empUuid;
            this.dialogVisible = true
        },
        async postDeleteEmp() {
            if (this.second !== 0) {
                return
            }
            const { status, data, info } = await this.$api.postDeleteEmp({ phone: this.phone })
            if (status === 200) {
                this.secondrun()
                this.$message({
                    type: 'success',
                    message: info
                });
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        },
        async deleteAccount() {
            const { status, info } = await this.$api.deleteAccount({ empUuid: this.empUuid,verifyCode:this.form.verifyCode });
            if (status === 200) {
                this.$emit('success')
                this.close()
                this.$message({
                    type: 'success',
                    message: info
                });
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        }

    },
};
</script>
<style lang='scss' scoped>
/deep/.el-dialog__body {
    padding: 0 20px;
}
</style>