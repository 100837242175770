<template>
    <el-drawer :visible.sync="visible" direction="rtl" :before-close="handleClose" :size="988">
        <div class="detail">
            <div class="detail_top">案件编号：202409071626</div>
            <div class="detail_content">
                <div class="detail_left">
                    <div class="detail_title">申请方</div>
                    <div class="detail_left_row">
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">{{ data.subjectTypeA == 1 ? '单位名称' : '自然人姓名' }}</div>
                            <div class="detail_left_col_b">{{ data.subjectA||'无' }}</div>
                        </div>
                        <div class="solidy"></div>
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">裁决文书</div>
                            <div class="detail_left_col_b">
                                <verdictRender :verdict="data.verdictUrl"/>
                            </div>
                        </div>
                    </div>
                    <div class="detail_left_row">
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">联系微信</div>
                            <div class="detail_left_col_b">{{ data.weixinA||'无' }}</div>
                        </div>
                        <div class="solidy"></div>
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">联系电话</div>
                            <div class="detail_left_col_b">{{ data.phoneA||'无' }}</div>
                        </div>
                    </div>
                    <div class="detail_left_row" v-if="data.subjectTypeA == 1">
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">联系人姓名</div>
                            <div class="detail_left_col_b">{{ data.contactA ||'无'}}</div>
                        </div>
                        <div class="solidy"></div>
                    </div>
                    <div class="detail_title">被申请方</div>
                    <div class="detail_left_row">
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">{{ data.subjectTypeB == 1 ? '单位名称' : '自然人姓名' }}</div>
                            <div class="detail_left_col_b">{{ data.subjectB ||'无'}}</div>
                        </div>
                        <div class="solidy"></div>
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">手机号</div>
                            <div class="detail_left_col_b">{{ data.phoneB ||'无'}}</div>
                        </div>
                    </div>
                    <div class="detail_left_row">
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">微信号</div>
                            <div class="detail_left_col_b">{{ data.weixinB ||'无'}}</div>
                        </div>
                        <div class="solidy"></div>
                    </div>
                    <div class="detail_title">处理专员</div>
                    <div class="detail_left_row">
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">专员姓名</div>
                            <div class="detail_left_col_b">{{ data.user?data.user.name:'无' }}</div>
                        </div>
                        <div class="solidy"></div>
                        <div class="detail_left_col">
                            <div class="detail_left_col_t">联系电话</div>
                            <div class="detail_left_col_b">{{ data.user?data.user.phone:'无' }}</div>
                        </div>
                    </div>
                    <div class="detail_title">案件费用明细</div>
                    <div class="detail_left_table">
                        <el-table :data="data.enforceCosts" style="width: 100%" border size="mini">
                            <el-table-column prop="createdAt" label="时间" width="180">
                            </el-table-column>
                            <el-table-column prop="amount" label="金额" width="180">
                            </el-table-column>
                            <el-table-column prop="note" label="备注">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="detail_right">
                    <div class="detail_title">案件进度</div>
                    <div class="steps">
                        <div class="steps_row" v-for="item in data.enforceProgresses" :key="item.progressId">
                            <div class="steps_row_l">
                                <p v-if="item.finished">完成</p>
                            </div>
                            <div class="steps_row_c"></div>
                            <div class="steps_row_r">
                                <div class="steps_row_r_t">{{ item.createdAt |timeFormat }}</div>
                                <div class="steps_row_r_c">{{ item.content }}</div>
                                <!-- <div class="steps_row_r_b">处理专员：李云飞</div> -->
                            </div>
                        </div>
                       

                        <div class="steps_solid"></div>
                    </div>
                </div>
            </div>

        </div>
    </el-drawer>
</template>

<script>
import verdictRender from "./verdictRender.vue"
export default {
    components: {verdictRender},
    data() {
        return {
            visible: false,
            data: {

            }
        };
    },
    created() {
    },
    computed: {},
    methods: {
        handleClose() { 
            this.visible = false;
        },
        show(res) {
            this.getEnforceDetail(res)
        },
        async getEnforceDetail({enforceId}) {
            const { status, data ,info} = await this.$api.getEnforceDetail({ enforceId })
            if (status == 200) {
                this.data = data;
                this.visible = true
            }else{
                this.$message({
                    type:'error',
                    message:info
                })
            }
        }
    },
};
</script>

<style scoped lang='scss'>
/deep/.el-drawer {
    &__header {
        display: none;
    }
}

.detail {
    width: 998px;

    &_top {
        border-bottom: 1px solid #eee;
        padding: 0 20px;
        width: 988px;
        height: 60px;
        flex-shrink: 0;
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
    }

    &_content {
        display: flex;

    }

    &_left {
        width: 608px;
        padding: 0 20px;
        border-right: 1px solid #eee;

        .solidy {
            background: #EEE;
            width: 1px;
            height: 26px;
            flex-shrink: 0;
        }

        &_row {

            display: flex;
            align-items: center;
            height: 76px;
            border-bottom: 1px solid #eee;
        }

        &_col {
            width: calc((100% - 1px)/2);
            padding-left: 16px;


            &_t {
                color: #666;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            &_b {
                color: #262626;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 6px;
            }
        }

        &_table {
            margin-top: 10px;
        }

    }

    &_right {
        width: 380px;
        padding: 0 20px;

        .steps {
            position: relative;

            &_solid {
                stroke-width: 1px;
                background: #0D72FF;
                opacity: 0.2;
                width: 1px;
                height: calc(100% - 30px);
                flex-shrink: 0;
                position: absolute;
                top: 0px;
                left: 114px;

            }

            &_row {
                display: flex;
                height: 63px;

                &_l {
                    width: 110px;
                    padding-top: 20px;
                    padding-left: 14px;

                    P {
                        width: 56px;
                        height: 23px;
                        flex-shrink: 0;
                        border-radius: 15px;
                        background: #EFF6FF;
                        color: #0062FF;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                        text-align: center;
                    }
                }

                &_c {
                    width: 8px;
                    height: 8px;
                    background: #0062FF;
                    border-radius: 8px;
                    margin-top: 28px;
                }

                &_r {
                    margin-top: 20px;
                    padding-left: 16px;

                    &_t {
                        margin-top: 3px;
                        color: #999;
                        font-family: "Source Han Sans CN";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    &_c {
                        color: #262626;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-top: 6px;
                    }

                    &_b {
                        color: #666;
                        font-family: "Source Han Sans CN";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-top: 6px;
                    }
                }
            }
        }
    }

    &_title {
        width: 100%;
        height: 40px;
        flex-shrink: 0;
        background: #F7F8F9;
        color: v#262626;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        padding-left: 16px;
        margin-top: 20px;
    }
}
</style>