const state = {
    insuranceId: null,
    title: null,
    insuranceVersion: null,
    grade: 1,
    price: 0,
    hetongbaoshow: true
}
const mutations = {
    setValue(state, { key, value }) {
        state[key] = value
    },
    insuranceSelectClear(state) {
        state.insuranceId = null;
        state.title = null;
        state.insuranceVersion = null;
        state.grade = 1;
        state.hetongbaoshow = true;
    }
}
const actions = {
    setInsuranceId({ commit }, value) {
        commit('setValue', { key: 'insuranceId', value })
    },
    setTitle({ commit }, value) {
        commit('setValue', { key: 'title', value })
    },
    setInsuranceVersion({ commit }, value) {
        console.log('InsuranceVersion',value)
        commit('setValue', { key: 'insuranceVersion', value })
    },
    setGrade({ commit }, value) {
        commit('setValue', { key: 'grade', value })
    },
    setPrice({ commit }, value) {
        commit('setValue', { key: 'price', value })
    },
    setShow({ commit }, value) {
        commit('setValue', { key: 'hetongbaoshow', value })
    },
    insuranceSelectClear({ commit }) {
        commit('insuranceSelectClear')
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}