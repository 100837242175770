<template>
    <div class="box">
        <div class="title">
            <p>违约主播</p>
            <span @click="$router.push('/exposure')">查看更多</span>
        </div>
        <div class="exposure-content" :style="user.newerBundleCount?'height: 150px;':'height: 240px;'">
            <div class="item " v-for="item in exposureList" :key="item.uuid">
                <div class="item-l">
                    <div class="time">{{ item.updatedAt | timeFormat }}</div>
                    <div class="name">{{ item.name }}</div>
                </div>
                <div class="idNumber">{{ item.idNumber }}</div>
            </div>
            <div class="mask"></div>
        </div>
    </div>
</template>

<script>
import userinfo from '@/mixins/userinfo';
export default {
    data() {
        return {
            exposureList: [

            ]
        }
    },
      mixins: [userinfo],
    mounted() {
        this.getExposureList()
    },
    methods: {
        async getExposureList() {
            const { status, data } = await this.$api.getExposureList({ type: 0 });
            if (status === 200) {
                const { records } = data;
                this.exposureList = records;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #fff;
}

.title {
    line-height: 60px;
    flex-shrink: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    span {
        color: #999;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-weight: 400;
    }
}

.exposure {
    // height: 374px;
    background: #66f;
    @keyframes rowup {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        100% {
            -webkit-transform: translate3d(0, -307px, 0);
            transform: translate3d(0, -307px, 0);
        }
    }

    &-content {
        padding: 0 20px;
        overflow: hidden;
        
        position: relative;

        .mask {
            position: absolute;
            width: 100%;
            height: 100px;
            background: linear-gradient(180deg,
                    rgba(255, 255, 255, 0) 0%,
                    #ffffff 100%);
            bottom: 0px;
            left: 0px;
        }

        .item {
            height: 56px;
            display: flex;
            justify-content: center;
            flex: 1;
            -webkit-animation: 20s rowup linear infinite normal;
            animation: 20s rowup linear infinite normal;
            flex-direction: column;
            border-bottom: 1px solid #eee;
            &-l {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .time {
                    font-family: "Source Han Sans CN";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 21px;
                    color: #666666;
                }

                .name {
                    margin-left: 30px;
                    font-family: "Source Han Sans CN";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 21px;

                    color: #ff8412;
                }
            }

            .idNumber {
                font-family: "Source Han Sans CN";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
                color: #262626;
            }
        }
    }
}
</style>