/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-22 18:44:20
 * @Module: 账号管理
 */
 <template>
  <div class="enterprise">
      <div class="left">
          <c-menu :list="menuList" />
      </div>
      <div class="right">
          <keep-alive>
              <router-view />
          </keep-alive>
      </div>
  </div>
</template>
<script>
import cMenu from "@/components/layouts/cMenu.vue"
export default {
  components: {
      cMenu
  },
  data() {
      return {
          menuList: [
              {
                  title: '个人信息',
                  name: 'personal',
                  icon: 'el-icon-ali-fengxianpaicha'
              },
              {
                  title: '被授权印章',
                  name: 'sealManagement',
                  icon: 'el-icon-ali-paichajilu',
                  authority: ['unCreator']
              }
          ]
      };
  },
}
</script>
<style lang='scss' scoped>
.enterprise {
  display: flex;
  padding-top: 16px;

  .left {
      width: 208px;
      padding-left: 24px;
      padding-right: 16px;
  }

  .right {
      flex: 1;
      padding-right: 20px;
  }
}
</style>