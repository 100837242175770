/*
* @Author: 曹俊杰
* @Date: 2024-09-25 12:03:11
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-01 10:41:09
*/合同菜单
<template>
    <div class="menu">
        <div class="item1 item " v-for="item in sysList" :class="{ active: (parmId == item.dirId) && activeItemNull }"
            :key="item.dirId" @click="itmClick(item)">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
        </div>

        <div class="item1 item " v-for="item in archiveList" :class="{ active: (parmId == item.dirId) }"
            :key="item.dirId" @click="itmClick(item)"
            @contextmenu.prevent="(e) => onRightClick({ ...item, type: 'fenlei' }, e)">
            <i class="iconfont el-icon-ali-zidingyihetongfenlei"></i>
            <span>{{ item.label }}</span>
        </div>

        <!-- 一级叫分类 二级叫目录 -->
        <!-- <div v-for="item in archiveList" :key="item.dirId">
                <div class="item2 item" @click="itemClick(item)" :class="{ active: isActive(item) }"
                    @contextmenu.prevent="(e) => onRightClick({ ...item, type: 'fenlei' }, e)">
                    <div>
                        <i class="iconfont el-icon-ali-zidingyihetongfenlei"></i>
                        <span>{{ item.label }}</span>
                    </div>
                    <b :class="isActive(item) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></b>
                </div>
                <div v-for="itm in item.children" :key="itm.dirId" :class="{ fold: !isActive(item) }">
                    <div class="item3 item " @contextmenu.prevent="(e) => onRightClick({ ...itm, type: 'mulu' }, e)"
                        :class="{ blue: parmId == itm.dirId }" @click="itmClick(itm)">
                        <i style="font-size: 30px;color:#aaa;">·</i>
                        <span>{{ itm.label }}</span>
                    </div>
                </div>
            </div> -->
        <dirForm ref="dirForm" @success="getArchiveList" />
        <caidan ref="caidan" @onclick="caidanCallback" />
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dirForm from "@/views/ElectronicDocuments/components/dirForm.vue";
import caidan from "./caidan"
import userinfo from "@/mixins/userinfo.js"
export default {
    mixins: [userinfo],
    components: {
        dirForm,
        caidan
    },
    computed: {
        ...mapState('documents', ['archiveList']),
        parmId() {
            const { dirId } = this.$route.params;
            return dirId
        },
        activeItemNull() {
            return !this.activeItem.dirId && (!this.activeItem.label) && (!this.activeItem.level)
        }
    },
    data() {
        return {
            activeItem: {
                dirId: null,
                label: null,
                level: null,
            },
            sysList: [{
                label: '全部合同',
                dirId: '0',
                level: 0,
                isSys: true,
                icon: 'el-icon-ali-quanbuhetong'
            },
            {
                label: '未归档合同',
                dirId: '-1',
                level: 0,
                isSys: true,
                icon: 'el-icon-ali-weiguidanghetong'
            }],
            disabled: false
        }
    },
    mounted() {
        this.getArchiveList();
    },
    methods: {
        ...mapActions('documents', ['getArchiveList']),
        clearitem() {
            this.activeItem = {
                dirId: null,
                label: null,
                level: null,
            }
        },
        //  菜单回调   type   点击事件回调   data item内容   data.type item的样式
        caidanCallback(type, data) {
            //  添加分类
            if (type == 'addfenlei') {
                this.add({ ...data, type: 'fenlei' })
                return
            }
            // 添加目录
            if (type == 'addmulu') {
                this.add({ ...data, type: 'mulu' })
                return
            }
            // 编辑
            if (type == 'bianji') {
                this.revision(data);
                this.disabled = true
                return
            }
            // 删除
            if (type == 'shanchu') {
                if (!this.user.creator) {
                    this.$confirm('仅支持法人账号删除分类', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return
                }
                this.ondelete(data)
                return
            }
        },
        itemClick(item) {
            if (item.dirId == this.activeItem.dirId) {
                // this.clearitem()
            } else {
                this.activeItem = item;
            }
        },
        itmClick({ dirId, isSys }) {
            isSys && this.clearitem()
            if (dirId != this.$route.params.dirId) {
                this.$router.replace(`/electronicDocumentsList/${dirId}`)
            }
        },
        isActive({ dirId }) {
            return this.activeItem.dirId === dirId;
        },
        onRightClick(data, { clientX, clientY }) {
            this.$refs.caidan.showFn({
                clientX,
                clientY,
                show: true,
                data
            })
        },
        addfenlei() {
            if (!this.user.creator) {
                this.$confirm('仅支持法人账号创建分类', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            }
            this.$refs.dirForm.show({ type: 'add', data: { dirId: null, type: 'fenlei' } })
        },
        add(data) {
            const { type, dirId } = data
            function findParentDirId(data, targetId, parentId = null) {
                for (const node of data) {
                    if (node.dirId === targetId) {
                        // 如果是顶层节点，直接返回它自己的 dirId
                        return parentId === null ? targetId : parentId;
                    }
                    if (node.children && node.children.length > 0) {
                        const result = findParentDirId(node.children, targetId, node.dirId);
                        if (result !== null) {
                            return result;
                        }
                    }
                }
                return null; // 未找到
            }
            const parentDirId = findParentDirId(this.archiveList, dirId)
            console.log(parentDirId)
            if (!parentDirId) {
                console.error('error')
                return
            }
            if (!this.activeItem.isSys) {
                if (type == 'fenlei') {
                    this.$refs.dirForm.show({ type: 'add', data: { ...data, dirId: null } })
                } else {
                    this.$refs.dirForm.show({ type: 'add', data: { ...data, dirId: parentDirId } })
                }
            } else {
                this.$message('系统目录不能添加子菜单');
            }
        },
        //  删除目录
        async ondelete({ type, dirId, label }) {
            const title = type == 'fenlei' ? '分类' : type == 'mulu' ? '目录' : ''
            try {
                await this.$confirm(`此操作将永久删除该${title}《${label}》, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                const { status, info } = await this.$api.deleteArchiveDir({ dirId });
                if (status === 200) {
                    this.$message({
                        type: 'success',
                        message: info
                    })
                    this.getArchiveList();
                } else {
                    this.$message({
                        type: 'error',
                        message: info
                    })
                }
            } catch (res) {
                console.log(res)
            }


        },
        revision(data) {
            this.$refs.dirForm.show({ type: 'revision', data })
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    // font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;

    .item {
        width: 168px;
        height: 40px;
        top: 48px;
        border-radius: 4px 0px 0px 0px;

        display: flex;
        padding: 0 20px;
        margin-top: 8px;
        align-items: center;
        cursor: pointer;

        i {
            margin-right: 8px;
        }
    }

    .item2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item3 {
        padding: 0 30px;
    }

    .active {
        background: #fff;
        border-radius: 4px;
        color: #0062FF;
    }

    .fold {
        //折叠
        height: 0px;
        overflow: hidden;
    }

    .blue {
        color: #0062FF;
    }
}
</style>