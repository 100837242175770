<template>
  <el-table border :data="list" style="width: 100%; background: transparent" size="mini"
    :header-cell-style="{ 'textAlign': 'center' }">
    <el-table-column prop="name" label="合同名称" width="150" show-overflow-tooltip>
      <template slot-scope="scope">
        <div style="padding-left: 8px;">
          <span style="color: #0062ff; font-size: 12px">{{
            scope.row.subject
          }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="合同编号" show-overflow-tooltip width="80" align="center">
      <template slot-scope="scope">
        <span style="font-size: 12px">{{ scope.row.contractNo || "--" }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="createdAt" label="合同状态" width="70" min-width="60" show-overflow-tooltip align="center">
      <template slot-scope="scope">
        <div style="font-size: 12px;display: flex;flex-direction: column;align-items: center;">

          <qianshuIcon :value="scope.row.state" />


        </div>
      </template>
    </el-table-column>
    <el-table-column prop="signers" label="签约时间" width="140">
      <template slot-scope="scope">
        <div style="display: flex;flex-direction: column;align-items: center;">
          <div style="width:110px;">发起：
            <span v-if="scope.row.createdAt">{{
              scope.row.createdAt | timeFormat("yyyy-mm-dd")
            }}</span>
            <span v-else>--</span>
          </div>
          <div style="width:110px">完成：
            <span v-if="scope.row.finishedAt">{{
              scope.row.finishedAt | timeFormat("yyyy-mm-dd")
            }}</span>
            <span v-else>--</span>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="signers" label="签约方信息" align="center">
      <template slot-scope="scope">
        <div style="display: flex;flex-direction: column;align-items: center;">
          <signers :list="scope.row.signers" />
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="insuranceVersion" label="合同锁" width="85" align="center">
      <template slot-scope="scope">
        <div>
          <div v-if="scope.row.insuranceState || scope.row.insuranceSnapshot">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <hetongbaoIcon :value="scope.row.insuranceState" :item="scope.row"
                @buyClick="operate('hetongbao', scope.row)" />
            </div>
            <el-tooltip effect="dark" placement="top">
              <div style="width:300px" slot="content" v-if="scope.row.insuranceSnapshot">
                {{ scope.row.insuranceSnapshot.detail }}</div>
              <p style="font-size: 12px;margin-top: 4px;" class="pointer" v-if="scope.row.insuranceSnapshot">
                {{ scope.row.insuranceSnapshot.title || "--" }}
              </p>
              <p v-else style="font-size: 12px">--</p>
            </el-tooltip>
          </div>
          <div v-else>--</div>

        </div>
      </template>
    </el-table-column>
    <el-table-column prop="insuranceVersion" label="锁定时间" width="110" align="center">
      <template slot-scope="scope">
        <div v-if="!(scope.row.insuranceState == 0)" style="font-size: 12px">
          <div>
            <div v-if="scope.row.createdAt" style="display: flex; align-items: center">
              <img src="~@/static/image/electronicDocuments/start.png" style="width: 12px; margin-right: 5px" alt=""
                srcset="" />{{ scope.row.createdAt | timeFormat }}
            </div>
            <span v-else>--</span>
          </div>
          <div>
            <div v-if="scope.row.insuranceUntil" style="display: flex; align-items: center">
              <img src="~@/static/image/electronicDocuments/end.png" style="width: 12px; margin-right: 5px" alt=""
                srcset="" />{{ scope.row.insuranceUntil | timeFormat }}
            </div>
            <span v-else>--</span>
          </div>
        </div>
        <div v-else style="font-size: 12px">--</div>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="归档管理" width="110" show-overflow-tooltip align="center">
      <template slot-scope="{ row }">
        <div style="display: flex;flex-direction: column;align-items: center;">

          <div @click="operate('guidang', row)" class="pointer primary guidang" style="font-size: 12px">
            <!-- <div class="el-icon-ali-zidingyihetongfenlei" style="font-size: 12px"></div> -->
            <div class="ellipsis" v-if="row.dirLabel">
              {{ row.dirLabel }}
              <!-- 一二三四五六七 -->
            </div>
            <div v-else style="width: 46px;
height: 19px;border-radius: 2px;background: #EFF6FF;padding: 1px 5px;color: #333;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;">待归档</div>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="操作" width="50" align="center">
      <template slot-scope="{ row }">
        <el-dropdown @command="(res) => operate(res, row)" trigger="click" placement="bottom">
          <span class="el-dropdown-link pointer" style="font-size: 12px">
            <span class="el-icon-more"></span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="chexiao">撤销</el-dropdown-item>
            <el-dropdown-item command="xiazai">下载</el-dropdown-item>
            <!-- <el-dropdown-item command="chuzheng">出证</el-dropdown-item> -->
            <el-dropdown-item command="xiugai">修改名称</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- <span @click="operate('chakan', row)">查看</span>
                <span>｜</span> -->
        <!-- <span @click="()=>window.open($oss+scope.row.files[0].url)">下载</span> -->
        <!-- <span @click="operate('xiazai', row)">下载</span>
                <span>｜</span>
                <span class="primary" @click="operate('chuzheng', row)">出证</span> -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import qianshuIcon from "@/components/qianshuIcon";
import hetongbaoIcon from "@/components/hetongbaoIcon";
import signers from "./components/signers";

export default {

  components: {
    qianshuIcon,
    hetongbaoIcon,
    signers,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    operate(type, data) {
      this.$emit("operate", { type, data });
    },
  },
};
</script>
<style lang="scss" scoped>
.guidang {
  display: flex;
  align-items: center;

  .ellipsis {
    white-space: nowrap;
    /* 确保文本在一行内显示 */
    overflow: hidden;
    /* 隐藏超出容器的文本 */
    text-overflow: ellipsis;
    /* 使用省略号表示被截断的文本 */
    // width: 170px;
    /* 定义容器宽度 */
  }
}
</style>
