<template>
  <div class="hetongbao">
    <insuranceSelect :showclose="true" />
    <div class="bottom">
      <div class="bottom_1" @click="sub">提交方案</div>
      <!-- <div class="bottom_2" @click="fangqi">放弃保障</div> -->
      <div class="bottom_3" v-if="title">已选择：
        <span class="primary">{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import insuranceSelect from "@/components/insuranceSelect"
export default {
  components: {
    insuranceSelect
  },
  data() {
    return {

    }
  },

  computed: {
    ...mapState('insuranceSelect', ['insuranceId', 'title', 'insuranceVersion', 'grade']),
  },
  mounted() {

  },
  methods: {
    sub() {
      if (this.insuranceId === null) {
        this.$alert('请选择合同锁方案，或勾选“放弃保障”', '提示', {
          confirmButtonText: '确定',
        })
        return
      }
      //   this.$emit('submit')
      this.$store.dispatch("insuranceSelect/setShow", false)
    }
  }
}
</script>

<style lang="scss" scoped>
.hetongbao {
  .bottom {
    background: #fff;
    box-shadow: 0px -6px 8px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 80px;

    &_1 {
      width: 102px;
      height: 38px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: "Source Han Sans CN";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 38px;
      text-align: center;
      border-radius: 2px;
      background: #0062ff;
      cursor: pointer;
    }

    &_2 {
      color: #999;
      text-align: center;
      font-family: "Source Han Sans CN";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 40px;
    }

    &_3 {
      text-align: center;
      font-family: "Source Han Sans CN";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #666;
      margin: 0 40px;
    }
  }
}
</style>