/*
* @Author: 曹俊杰
* @Date: 2024-12-12 21:37:26
* @Last Modified by: 曹俊杰杰
* @Last Modified time: 2024-12-12 21:45:399
*/支付方式

<template>
    <div>  

        <!-- 余额支付 -->
        <div class="box1-r-line5 pointer" :class="{ 'box1-r-active': value == 1 }" @click="yuelineclick">
            <div class="box1-r-line5-l">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                        d="M1 5.25C1 4.83578 1.33579 4.5 1.75 4.5H15.25C15.6642 4.5 16 4.83578 16 5.25V15C16 15.4142 15.6642 15.75 15.25 15.75H1.75C1.33579 15.75 1 15.4142 1 15V5.25Z"
                        fill="#0062FF" />
                    <path d="M7.125 7.125L9 9L10.875 7.125" stroke="white" stroke-width="1.2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M6.75 9.375H11.25" stroke="white" stroke-width="1.2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M6.75 11.625H11.25" stroke="white" stroke-width="1.2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M9 9.375V13.125" stroke="white" stroke-width="1.2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M3 2.25H15" stroke="#0062FF" stroke-width="1.2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <p style="margin-left: 6px;">余额支付</p>
                <p>
                    <span style="color: #666;">（余额：</span>
                    <span>¥{{ balance }}</span>
                    <span style="color: #666;">）</span>
                </p>
            </div>
            <div class="box1-r-line5-r" v-if="isbalance">
                <div class="box1-r-icon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <rect width="14" height="14" rx="7" fill="#0062FF" />
                        <path
                            d="M10.7768 5.21992L6.21352 9.80083C6.11432 9.90042 5.91592 10 5.71752 10H5.61831C5.41991 10 5.32071 9.90042 5.12231 9.80083L3.23748 8.30705C2.93987 8.0083 2.93987 7.51037 3.13827 7.21162C3.43588 6.91286 3.83269 6.81328 4.22949 7.11203L5.61831 8.30705L9.68558 4.22407C9.98318 3.92531 10.4792 3.92531 10.7768 4.22407C11.0744 4.42324 11.0744 4.92116 10.7768 5.21992Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="box1-r-icon2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill="#F3F6F8" stroke="#EEEEEE" />
                    </svg>
                </div>
            </div>
            <div class="box1-r-line5-r" v-else>去充值</div>
        </div>



        <!-- 支付宝支付 -->
        <div class="box1-r-line6 pointer" @click="zhifubaolineClick" :class="{ 'box1-r-active': value == 2 }">
            <div class="box1-r-line6-l">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <g clip-path="url(#clip0_183_3321)">
                        <path
                            d="M17.0008 11.6382V3.26825C17.0004 2.40145 16.6558 1.57029 16.0428 0.957445C15.4298 0.344603 14.5986 0.000225202 13.7317 0L3.26825 0C2.40159 0.000450445 1.57056 0.344928 0.957746 0.957746C0.344928 1.57056 0.000450445 2.40159 0 3.26825V13.7317C0.000225328 14.5985 0.34463 15.4296 0.957497 16.0425C1.57036 16.6554 2.40153 16.9998 3.26825 17H13.7317C14.4984 16.9995 15.2406 16.7297 15.8287 16.2379C16.4169 15.7461 16.8136 15.0634 16.9499 14.3089C16.0828 13.9332 12.3258 12.3114 10.3683 11.3764C8.8791 13.181 7.3185 14.2639 4.9674 14.2639C2.6163 14.2639 1.04635 12.8155 1.23505 11.0424C1.35915 9.87955 2.1573 7.9781 5.62275 8.30365C7.45025 8.47535 8.2858 8.8162 9.77585 9.30835C10.1609 8.60115 10.4813 7.8234 10.7245 6.99635H4.11825V6.34185H7.38735V5.16545H3.4V4.4455H7.3865V2.74975C7.3865 2.74975 7.4222 2.48455 7.71545 2.48455H9.35V4.4455H13.6V5.1663H9.35V6.341H12.8172C12.5181 7.579 12.0438 8.76796 11.4087 9.8719C12.416 10.2374 17 11.6382 17 11.6382H17.0008ZM4.7073 13.1419C2.22275 13.1419 1.83005 11.5736 1.9618 10.9183C2.09185 10.2654 2.8118 9.41375 4.19305 9.41375C5.78 9.41375 7.20205 9.82005 8.90885 10.6514C7.71035 12.212 6.2373 13.1419 4.7073 13.1419Z"
                            fill="#009FE8" />
                        <path
                            d="M17.0009 11.6382V3.26825C17.0004 2.40145 16.6558 1.57029 16.0428 0.957445C15.4298 0.344603 14.5986 0.000225202 13.7318 0L3.26825 0C2.40159 0.000450445 1.57056 0.344928 0.957746 0.957746C0.344928 1.57056 0.000450445 2.40159 0 3.26825V13.7317C0.000225328 14.5985 0.34463 15.4296 0.957497 16.0425C1.57036 16.6554 2.40153 16.9998 3.26825 17H13.7318C14.4984 16.9995 15.2406 16.7297 15.8287 16.2379C16.4169 15.7461 16.8136 15.0634 16.9499 14.3089C16.0829 13.9332 12.3259 12.3114 10.3683 11.3764C8.8791 13.181 7.3185 14.2639 4.9674 14.2639C2.6163 14.2639 1.04635 12.8155 1.23505 11.0424C1.35915 9.87955 2.1573 7.9781 5.62275 8.30365C7.45025 8.47535 8.2858 8.8162 9.77585 9.30835C10.1609 8.60115 10.4814 7.8234 10.7245 6.99635H4.11825V6.34185H7.38735V5.16545H3.4V4.4455H7.3865V2.74975C7.3865 2.74975 7.4222 2.48455 7.71545 2.48455H9.35V4.4455H13.6V5.1663H9.35V6.341H12.8172C12.5181 7.579 12.0438 8.76796 11.4087 9.8719C12.416 10.2374 17 11.6382 17 11.6382H17.0009ZM4.7073 13.1419C2.22275 13.1419 1.83005 11.5736 1.9618 10.9183C2.09185 10.2654 2.8118 9.41375 4.19305 9.41375C5.78 9.41375 7.20205 9.82005 8.90885 10.6514C7.71035 12.212 6.2373 13.1419 4.7073 13.1419Z"
                            fill="#0062FF" />
                    </g>
                    <defs>
                        <clipPath id="clip0_183_3321">
                            <rect width="17" height="17" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <p>支付宝</p>
            </div>
            <div class="box1-r-line6-r ">
                <div class="box1-r-icon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <rect width="14" height="14" rx="7" fill="#0062FF" />
                        <path
                            d="M10.7768 5.21992L6.21352 9.80083C6.11432 9.90042 5.91592 10 5.71752 10H5.61831C5.41991 10 5.32071 9.90042 5.12231 9.80083L3.23748 8.30705C2.93987 8.0083 2.93987 7.51037 3.13827 7.21162C3.43588 6.91286 3.83269 6.81328 4.22949 7.11203L5.61831 8.30705L9.68558 4.22407C9.98318 3.92531 10.4792 3.92531 10.7768 4.22407C11.0744 4.42324 11.0744 4.92116 10.7768 5.21992Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="box1-r-icon2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill="#F3F6F8" stroke="#EEEEEE" />
                    </svg>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import userinfo from "@/mixins/userinfo.js"
export default {
    mixins: [userinfo],
    props: {
        value: {
            default: null
        },
        // 余额是否够
        isbalance: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        yuelineclick() {
            if (this.isbalance) {
                this.$emit('input', 1)
            } else {
                this.tochongzhi();
                this.dialogVisible = false;
            }
        },
        zhifubaolineClick() {
            this.$emit('input', 2)
        }
    }
}
</script>

<style scoped lang="scss">
.box1-r {

    &-line5 {
        width: 100%;
        height: 44px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #FFF;
        display: flex;
        align-items: center;
        padding: 0 16px;
        justify-content: space-between;

        &-l {
            display: flex;
            align-items: center;
            color: #262626;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &-r {
            color: #0062FF;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &-r {
            .box1-r-icon1 {
                display: none;
            }

            .box1-r-icon2 {
                display: block;
            }
        }
    }

    &-line6 {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        width: 100%;
        height: 44px;
        flex-shrink: 0;
        border-radius: 4px;

        background: #FFF;

        &-l {
            display: flex;
            align-items: center;

            p {
                margin-left: 6px;
                color: #262626;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        &-r {
            .box1-r-icon1 {
                display: none;
            }

            .box1-r-icon2 {
                display: block;
            }
        }


    }

    &-active {
        border: 1px solid #0062FF;

        .box1-r-icon2 {
            display: none;
        }

        .box1-r-icon1 {
            display: block;
        }
    }
}
</style>