<!-- 超值礼包 -->
<template>
    <div class="chaozhilibao">
        <el-dialog :show-close="false" :visible.sync="dialogVisible" width="960px" :modal-append-to-body="false">
            <div class="chaozhilibao_diaog">
                <div class="chaozhilibao_diaog_l">
                    <div class="chaozhilibao_diaog_l_title">
                        <div class="chaozhilibao_diaog_l_title_l">88元超值礼包</div>
                        <div class="chaozhilibao_diaog_l_title_r">每月仅限购买一次</div>
                    </div>
                    <div class="chaozhilibao_diaog_l_content" :class="tableClass">
                        <div class="table">
                            <div class="table_item" :class="{ ['style_' + index]: item.bundleId === bundleId }"
                                v-for="(item, index) in list" :key="item.bundleId" @click="tableClick(item)">{{
                                    item.bundleName }}
                            </div>
                        </div>
                        <div class="main">
                            <!-- 合同券 -->
                            <div class="main_item">
                                <div class="main_item_top">
                                    <div class="value">{{ hetong.amount }}</div>
                                    <div class="unit">元</div>
                                    <div class="icon">x</div>
                                    <div class="value">{{ hetong.count }}</div>
                                    <div class="unit">张</div>
                                </div>
                                <div class="main_item_center">有效期一年</div>
                                <div class="main_item_bottom">{{ voucherTypeRender(2) }}</div>
                            </div>
                            <div class="jia">+</div>
                            <!-- 法律券 -->
                            <div class="main_item main_item2">
                                <div class="main_item_top">
                                    <div class="value">{{ falv.amount }}</div>
                                    <div class="unit">元</div>
                                    <div class="icon">x</div>
                                    <div class="value">{{ falv.count }}</div>
                                    <div class="unit">张</div>
                                </div>
                                <div class="main_item_center">有效期一年</div>
                                <div class="main_item_bottom">{{ voucherTypeRender(1) }}</div>
                            </div>
                        </div>
                        <div class="footer">
                            <span>{{ item.bundleName }}：</span>
                            <!-- 合同券 -->
                            <span style="color: #0062FF;">{{ hetong.count }}张{{ hetong.amount }}元{{ voucherTypeRender(2)
                                }}</span>
                            <span> + </span>
                            <!-- 法律券 -->
                            <span style="color: #93502B;">{{ falv.count }}张{{ falv.amount }}元{{ voucherTypeRender(1)
                                }}</span>
                        </div>
                    </div>
                </div>
                <div class="chaozhilibao_diaog_line"></div>
                <div class="chaozhilibao_diaog_r">
                    <div class="chaozhilibao_diaog_r_top">
                        <div class="line_1">
                            <div class="line_1_l">实付金额</div>
                            <div class="line_1_r">
                                <span class="icon">¥</span>
                                <span class="value">{{ item.price | priceFormat }}</span>
                            </div>
                        </div>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="280" height="2" viewBox="0 0 280 2" fill="none">
                            <path d="M0 1H280" stroke="#EACA99" stroke-dasharray="4 4" />
                        </svg> -->
                        <div class="line_2">
                            <div class="line_2_l">{{ item.bundleName }}：</div>
                            <div class="line_2_r">¥ {{ item.price | priceFormat }}</div>
                        </div>
                    </div>
                    <div class="chaozhilibao_diaog_r_bottom">
                        <div class="line_3">
                            支付方式
                        </div>
                        <div class="line_4">
                            <div class="line_4_l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M1 5.25C1 4.83578 1.33579 4.5 1.75 4.5H15.25C15.6642 4.5 16 4.83578 16 5.25V15C16 15.4142 15.6642 15.75 15.25 15.75H1.75C1.33579 15.75 1 15.4142 1 15V5.25Z"
                                        fill="#0062FF" />
                                    <path d="M7.125 7.125L9 9L10.875 7.125" stroke="white" stroke-width="1.2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.75 9.375H11.25" stroke="white" stroke-width="1.2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M6.75 11.625H11.25" stroke="white" stroke-width="1.2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9.375V13.125" stroke="white" stroke-width="1.2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M3 2.25H15" stroke="#0062FF" stroke-width="1.2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <div style="margin-left: 4px;font-size: 13px;">
                                    <span>余额支付</span>
                                    <span style="color: #666;">（余额：</span>
                                    <span>¥{{ balance | priceFormat }}</span>
                                    <span style="color: #666;">）</span>

                                </div>
                            </div>
                            <div class="line_4_r" @click="tochongzhi">
                                去充值
                            </div>
                        </div>
                        <div class="line_5" @click="buy">确认购买</div>
                    </div>
                </div>
                <svg class="close" @click="clear" xmlns="http://www.w3.org/2000/svg" width="10" height="11"
                    viewBox="0 0 10 11" fill="none">
                    <path opacity="0.2"
                        d="M8.44195 10.6138C8.79838 10.9998 9.37626 10.9998 9.73268 10.6138C10.0891 10.2278 10.0891 9.60195 9.73268 9.21596L6.29073 5.48849L9.73268 1.76103C10.0891 1.37503 10.0891 0.749217 9.73268 0.363225C9.37626 -0.0227676 8.79838 -0.0227671 8.44195 0.363225L5 4.09069L1.55805 0.363225C1.20162 -0.0227675 0.623745 -0.0227676 0.267319 0.363225C-0.0891064 0.749217 -0.0891064 1.37503 0.267319 1.76103L3.70927 5.48849L0.267319 9.21596C-0.0891063 9.60195 -0.0891064 10.2278 0.267319 10.6138C0.623745 10.9998 1.20162 10.9998 1.55805 10.6138L5 6.88629L8.44195 10.6138Z"
                        fill="#703915" />
                </svg>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import selectData from '@/mixins/selectData';
import userinfo from '@/mixins/userinfo';

export default {
    mixins: [selectData, userinfo],
    components: {},
    data() {
        return {
            dialogVisible: false,
            list: [],
            bundleId: null
        };
    },
    created() {
        // this.getBundleList()
    },
    computed: {
        item() {
            return this.list.find(res => res.bundleId == this.bundleId) || {
                bundleId: null,
                bundleName: '',  //礼包名称
                originalPrice: null,  //原价
                price: null,    //价格
                voucherTemplates: []   //券列表
            }
        },
        // 法律券
        falv() {
            const def = {
                type: 1,
                amount: 0,
                count: 0,
                period: 0
            }
            if (!this.item.voucherTemplates.length) {
                return def
            }
            return this.item.voucherTemplates.find(res => res.type == 1) || def
        },
        //合同券
        hetong() {
            const def = {
                type: 2,
                amount: 0,
                count: 0,
                period: 0
            }
            if (!this.item.voucherTemplates.length) {
                return def
            }
            return this.item.voucherTemplates.find(res => res.type == 2) || def
        },
        // table 背景
        tableClass() {
            const index = this.list.findIndex(res => res.bundleId == this.bundleId);
            if (index < 0) {
                return 'style_1'
            }
            return `style_${index + 1}`
        }
    },
    methods: {
        async postBundleBuy() {
            const { status, info } = await this.$api.postBundleBuy({ bundleId: this.item.bundleId })
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.getAccount()
                this.clear()
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        },
        async buy() {
            await this.$store.dispatch("user/buy", this.item.price);
            this.postBundleBuy()
        },
        tableClick({ bundleId }) {
            this.bundleId = bundleId
        },
        show() {
            this.getBundleList();
            this.dialogVisible = true;
        },
        voucherTypeRender(type) {
            return this.voucherTypeData.find(res => res.value == type).label
        },
        clear() {
            this.dialogVisible = false;
            // this.bundleId = null
        },
        async getBundleList() {
            const { status, info, data } = await this.$api.getBundleList({ type: 2 })
            if (status == 200) {
                this.list = data;
                if (!this.bundleId) {
                    this.bundleId = data[0].bundleId
                }
                return
            }
            this.$message({
                type: 'error',
                message: info
            })
        }
    },
};
</script>

<style scoped lang='scss'>
.chaozhilibao {
    margin-top: 10px;
    position: relative;

    .close {
        position: absolute;
        right: 20px;
        top: 27px;
        cursor: pointer;
    }

    /deep/ .el-dialog {
        background-color: rgba($color: #000000, $alpha: 0);
        box-shadow: none;

        &__header {
            padding: 0;
        }

        &__body {
            padding: 0;
            background-color: rgba($color: #000000, $alpha: 0);
        }
    }

    &_diaog {
        border-radius: 10px;
        // height: 385px;
        display: flex;
        overflow: hidden;

        &_l {
            padding-bottom: 20px;
            width: 639px;
            // background: linear-gradient(180deg, #FFF2D8 14.29%, #FFE2AC 100%);
            background-image: url(~@/static/image/bundle/bundle_bg_left.png);
            background-size: 100% auto;

            &_title {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px;

                &_l {
                    font-family: "Source Han Sans CN";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(90deg, #582E12 0%, #713915 98.58%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                &_r {
                    color: #999;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .style_1 {
                background-image: url(~@/static/image/bundle/bundle_style_1.png);
            }

            .style_2 {
                background-image: url(~@/static/image/bundle/bundle_style_2.png);
            }

            .style_3 {
                background-image: url(~@/static/image/bundle/bundle_style_3.png);
            }

            &_content {
                width: 600px;
                margin: 0 auto;

                background-size: 100% auto;
                border-radius: 0 10px 10px 10px;
                overflow: hidden;

                .table {
                    display: flex;
                    height: 60px;
                    align-items: center;

                    &_item {
                        width: calc(100% / 3);
                        text-align: center;
                        background: linear-gradient(90deg, #582E12 0%, #713915 98.58%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-family: "Source Han Sans CN";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        cursor: pointer;
                    }

                    .style_0 {
                        background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 800;
                    }

                    .style_1 {
                        background: linear-gradient(170deg, #FF8412 7.48%, #994F0B 92.52%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 800;
                    }

                    .style_2 {
                        background: linear-gradient(180deg, #BF59F7 2.44%, #311256 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 800;
                    }
                }

                .main {
                    display: flex;
                    padding: 0 20px;
                    padding-top: 30px;
                    align-items: center;
                    justify-content: space-between;

                    .jia {
                        color: #666;
                        font-family: "Source Han Sans CN";
                        font-size: 50px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &_item {
                        display: flex;
                        width: 250px;
                        height: 135px;
                        flex-direction: column;
                        // justify-content: space-between;
                        padding-top: 15px;
                        padding-bottom: 14px;
                        align-items: center;
                        border-radius: 20px;
                        // border: 2px solid #B3D5FF;
                        // background: linear-gradient(90deg, #EFF6FF 0%, #D6E8FF 100%);
                        background-image: url(~@/static/image/bundle/bundle_card_2.png);
                        background-size: 100% 100%;

                        &_top {
                            display: flex;
                            align-items: flex-end;

                            .value {
                                font-family: "DIN Alternate";
                                font-size: 40px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }

                            .unit {
                                font-family: "DIN Alternate";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                letter-spacing: -0.154px;
                                background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                padding-bottom: 8px;
                                margin-left: 4px;
                            }

                            .icon {
                                font-family: "DIN Alternate";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                opacity: 0.5;
                                background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                padding-bottom: 7px;
                                margin: 0 5px;
                            }
                        }

                        &_center {
                            color: #333;
                            text-align: center;
                            font-family: "Source Han Sans CN";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        &_bottom {
                            color: #FFF;
                            font-family: "Source Han Sans CN";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            margin-top: 18px;
                        }
                    }

                    &_item2 {
                        background-image: url(~@/static/image/bundle/bundle_card_1.png);

                        .main_item_top {
                            .value {
                                background: linear-gradient(180deg, #BC6416 14.66%, #914F1A 85.34%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }

                            .unit {
                                background: linear-gradient(180deg, #BC6416 0%, #914F1A 100%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }

                            .icon {
                                opacity: 0.5;
                                background: linear-gradient(180deg, #BC6416 0%, #914F1A 100%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }

                        .main_item_bottom {
                            color: #93502B;
                        }
                    }
                }

                .footer {
                    color: #262626;
                    text-align: center;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 60px;
                }
            }
        }

        &_line {
            width: 1px;
            flex-shrink: 0;
            background: #EACA99;
        }

        &_r {
            width: 320px;
            // background: #fef7e1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 40px;
            padding-bottom: 20px;
            background-image: url(~@/static/image/bundle/bundle_bg_right.png);
            background-size: 100% auto;

            &_top {}

            &_bottom {}

            .line {
                &_1 {
                    height: 64px;
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                    margin: 0 20px;
                    overflow: hidden;
                    border-bottom: 2px dashed #EACA99;

                    &_l {
                        color: #262626;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-top: 28px;
                    }

                    &_r {
                        display: flex;
                        align-items: center;

                        .icon {
                            color: #FF0D0D;
                            font-family: "Source Han Sans CN";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            padding-top: 12px;
                            margin-right: 6px;
                        }

                        .value {
                            color: #FF0D0D;
                            font-family: "DIN Alternate";
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }
                }

                &_2 {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 20px;
                    line-height: 50px;

                    &_l {
                        color: #666;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }

                    &_r {
                        color: #262626;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }

                &_3 {
                    color: #262626;
                    font-family: "Source Han Sans CN";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    padding: 0 20px;
                }

                &_4 {
                    width: 280px;
                    height: 44px;
                    flex-shrink: 0;
                    border-radius: 4px;
                    background: #FFF;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    justify-content: space-between;

                    &_l {
                        display: flex;
                        align-items: center;
                        color: #262626;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &_r {
                        color: #0062FF;
                        font-family: "Source Han Sans CN";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        cursor: pointer;
                    }
                }

                &_5 {
                    width: 280px;
                    height: 40px;
                    flex-shrink: 0;
                    border-radius: 4px;
                    background: linear-gradient(180deg, #C35632 0%, #983F20 100%);
                    color: #FFF;
                    text-align: center;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 40px;
                    text-align: center;
                    margin: 0 auto;
                    margin-top: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>