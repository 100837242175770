<template>
    <div class="box">
        <div class="title">我的合同</div>
        <div class="content">
            <div class="item"
                @click="$router.push({ path: '/electronicDocumentsList', query: { state: 'IN_PROGRESS' } })">
                <div class="label">签署中</div>
                <div class="value">{{ item.inProgressCount }}</div>
            </div>
            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { state: 'FINISHED' } })">
                <div class="label">签署完成</div>
                <div class="value">{{ item.finishedCount }}</div>
            </div>
            <div class="item" @click="$router.push({ path: '/electronicDocumentsList', query: { state: 'CANCELED' } })">
                <div class="label">已撤销</div>
                <div class="value">{{ item.canceledCount }}</div>
            </div>


            <div class="item" @click="$router.push({ name: 'electronicDocumentsList', params: { dirId: -1 } })">
                <div class="label">待归档</div>
                <div class="value">{{ item.unarchivedCount }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            item: state => state.electronic.contractProfile
        }),
    }
}
</script>

<style lang="scss" scoped>
@import "~@/views/Home/hetong.scss";
</style>