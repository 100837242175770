/*
* @Author: 曹俊杰
* @Date: 2024-09-28 10:06:40
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-08 22:46:18
*/签署状态样式

<template>
    <div class="qianshuIcon">
        <div class="qianshuIcon_r" :style="`background:${item.color};`">{{ item.label }}</div>
    </div>
</template>

<script>
import selectData from "@/mixins/selectData.js"
export default {
    mixins: [selectData],
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    computed: {
        item() {
            return this.contractStateList.find(res => res.value == this.value)
        }
    }

}
</script>

<style lang="scss" scoped>
.qianshuIcon {
    display: flex;
    align-items: center;


    &_r {
        color: #666 !important;
        font-family: "Source Han Sans CN";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 1px 5px;
        border-radius: 2px;
    }
}
</style>