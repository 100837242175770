<template>
    <div class="caidan" :class="{ caidan_show: onshow }" @click="maskClick">
        <div class="caidan_menu" :style="`margin-top:${clientY}px;margin-left:${clientX}px`">
            <!-- <div class="caidan_menu_item" @click.stop="onclick('addfenlei')" v-show="data.type==='fenlei'">添加分类</div> -->
            <!-- <div class="caidan_menu_item" @click.stop="onclick('addmulu')"  v-show="data.type==='fenlei'">添加目录</div> -->
            <div class="caidan_menu_item" @click.stop="onclick('bianji')">重命名</div>
            <div class="caidan_menu_item" @click.stop="onclick('shanchu')">删除</div>
        </div>
    </div>

</template>

<script>
export default {
    components: {},
    data() {
        return {
            onshow: false,
            clientX: 0,
            clientY: 0,
            data: {
                type:null
            }
        };
    },
    created() { },
    computed: {},
    methods: {
        showFn({ show, clientX, clientY, data }) {
            this.clientX = clientX;
            this.clientY = clientY;
            this.onshow = show;
            this.data = data
        },
        maskClick() {
            this.onshow = false
        },
        onclick(type) {
            this.$emit('onclick', type, this.data)
            this.onshow = false
        }
    },
};
</script>

<style scoped lang='scss'>
.caidan {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;

    &_menu {
        display: flex;
        width: 116px;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid #eee;
        background: #fff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);

        &_item {
            width: 116px;
            height: 40px;
            flex-shrink: 0;
            line-height: 40px;
            padding: 0 16px;
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                background-color: #F3F6F8;
            }
        }
    }
}

.caidan_show {
    display: block;
}
</style>