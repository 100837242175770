<!-- 新手礼包 -->
<template>
    <div class="libao">
        <el-dialog :show-close="false" :visible.sync="dialogVisible" width="420px" :lock-scroll="false"
            :modal-append-to-body="false">
            <div class="libao_diaog">
                <img src="~@/static/code.jpg" alt="" class="image">
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            dialogVisible: false
        };
    },
    created() { },
    computed: {


    },
    methods: {
        onshow() {
            this.dialogVisible = true
        }
    },
};
</script>

<style scoped lang='scss'>
.libao {
    /deep/ .el-dialog {
        background-color: rgba($color: #000000, $alpha: 0);
        box-shadow: none;
        margin-top: 0px !important;

        &__header {
            padding: 0;
        }

        &__body {
            padding: 0;
            background-color: rgba($color: #000000, $alpha: 0);
        }
    }

    &_diaog {
        margin-top: calc((100vh - 595px) / 2);
        background-image: url(~@/static/image/home/home_bg_12.png);
        width: 420px;
        height: 595px;
        background-size: 420px 595px;
        background-repeat: no-repeat;
        overflow: hidden;
        position: relative;

        .image{
            position: absolute;
            margin: 0 auto;
            left: 0 ;
            right: 0;
            bottom: 77px;
            width: 147px;
            height: 147px;
        }
    }
}
</style>