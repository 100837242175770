<template>
  <div>
    <e-header :step="3" @next="postContract" />
    <div class="aliform" v-html="aliform" v-show="false"></div>
    <div class="ElectronicDocumentsPDF">
      <div class="left-box">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="changePdfPage(0)">上一页</el-button>
          <el-button type="primary" size="mini" @click="changePdfPage(1)">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </el-button-group>
        <el-divider content-position="left">设置盖章区域</el-divider>
        <div class="sign" draggable="true" @dragstart="event => event.dataTransfer.setData('type', 'sign')">签署区</div>
        <div class="seal" draggable="true" @dragstart="event => event.dataTransfer.setData('type', 'seal')">骑缝章</div>
      </div>
      <div class="box" :style="`width:${pdfWidth}px`">
        <pdf ref="pdf" :src="electronicData.file" :page="currentPage" :rotate="pageRotate" @num-pages="numPages" @loaded="loadPdfHandler" @progress="loadedRatio = $event"></pdf>

        <div class="main" id="pdfMain" @drop="dropEvent" @dragover.prevent>
          <!-- <div class="signer" :data-pdfid="item.positionId" v-show="currentPage==item.posPage" @mousedown="move" v-for="item in signerList" :key="item.positionId">
            <div style="" class="signer-content">
              <p class="name">{{item.name||item.operatorName}}</p>
              <p style="margin-top:5px;">盖章区域</p>
              <i class="el-icon-close" @click="()=>signerListDelete(item)"></i>
            </div>
          </div> -->
          <div class="signNode" @mousedown="move" @click="() => { active = item.positionId; sealActive = '' }" v-show="currentPage == item.posPage" :data-pdfid="item.positionId" v-for="item in signerList"
            :style="`left:${item.position.posX}px;top:${item.position.posY}px;`" :key="item.positionId">
            <div class="content">
              <span>签署区</span>
              <div class="username" :style="item.positionId == active ? 'display:block;' : ''">
                {{ item.name || item.operatorName }}</div>
              <div class="date">{{ item.signDateBean.format }}</div>
            </div>
            <div class="delete" @click.stop="() => signerListDelete(item)">删除</div>
          </div>
          <!-- 骑缝章 -->
          <div class="sealNode" @mousedown="sealMove" :style="`right:0px;top:${item.position.posY}px;`" v-for="item in sealList" :key="item.positionId" @click="() => { sealActive = item.positionId; active = '' }"
            :data-pdfid="item.positionId">
            <div class="content">
              <span style="writing-mode: vertical-lr;">骑缝章</span>
              <div class="username" :style="item.positionId == sealActive ? 'display:block;' : ''">
                {{ item.name || item.operatorName }}</div>
            </div>
            <div class="delete" @click.stop="() => sealListDelete(item)">删除</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <mElectronicFooter @next="postContract" :disabledNext="disabledNext" />
      </div>

      <div class="right-box">
        <div v-if="active">
          <div class="label">签署方设置</div>
          <el-select @change="signatory" :value="signerList[activeIndex].id" size="mini" placeholder="签署方设置">
            <el-option v-for="item in electronicData.signers" :key="item.id" :label="item.name || item.operatorName" :value="item.id"></el-option>
          </el-select>
          <div class="label">签署日期设置</div>
          <el-select v-model="signerList[activeIndex].signDateBean.format" size="mini" placeholder="签署日期设置">
            <el-option :value="''" label="不显示"></el-option>
            <el-option value="yyyy/MM/dd" label="YYYY/MM/DD"></el-option>
            <el-option value="yyyy-MM-dd" label="YYYY-MM-DD"></el-option>
            <el-option value="yyyy年MM月dd日" label="YYYY年MM月DD日"></el-option>
          </el-select>
        </div>
        <div v-else-if="sealActive">
          <div class="label">签署方设置</div>
          <el-select @change="sealchange" :value="sealList[sealActiveIndex].id" size="mini" placeholder="签署方设置">
            <el-option v-for="item in electronicData.signers" :key="item.id" :label="item.name || item.operatorName" :value="item.id"></el-option>
          </el-select>
        </div>
        <div v-else class="text">
          <i class="el-icon-box"></i>
          <p>从控件库中拖动控件到文件区域</p>
          <p>或选中已经添加到文件上的控件</p>
        </div>
      </div>
    </div>
    <hetongbuy ref="hetongbuy" @success="tijiao" />
  </div>
</template>

<script>
import eHeader from "./components/eHeader.vue"
import hetongbuy from "@/components/hetongbuy/hetongbuy.vue"
import mElectronicFooter from "@/components/mElectronicFooter.vue"
import { mapState } from "vuex";
import pdfjsLib from "@/utils/pdfjsLib.js"
// pdf预览
import pdf from "vue-pdf";
const itemWidth = 120;   // 元素宽度
const itemHeight = 120;  // 元素高度
const sealWidth = 58;// 骑缝章宽度
const sealHeight = 118;// 骑缝章高度
export default {
  name: "home",
  components: {
    pdf,
    eHeader,
    hetongbuy,
    mElectronicFooter
  },
  data() {
    return {
      src: "",
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      scale: 100,
      pageRotate: 0,
      signerList: [],  // 签署区列表
      active: "",
      sealList: [],  // 骑缝章列表
      sealActive: "",
      signers: [],
      aliform: '',
      loadedRatio: 0,
      loading: false,
      pdfWidth: 0
    };
  },
  created() {
    const signerList = this.$store.state.electronic.signerList;
    console.log(signerList)
    this.signerList = signerList
  },
  watch: {
    signerList: {
      handler(newValue) {
        console.log(newValue)
        this.$store.commit('electronic/setSignerList', newValue)
      },
      deep: true // 深度监听，确保对象内部属性变化也会触发
    },
    loadedRatio: {
      handler(newValue) {
        if (newValue == 1) {
          console.log('加载完毕')
          this.loading.close();
        }
      },
      deep: true // 深度监听，确保对象内部属性变化也会触发
    }
  },
  computed: {
    ...mapState({
      electronicData: state => state.electronic.electronicData
    }),
    activeIndex() {
      return this.signerList.findIndex(item => item.positionId == this.active)
    },
    sealActiveIndex() {
      return this.sealList.findIndex(item => item.positionId == this.sealActive)
    },
    pdfSize() {
      return this.$refs.pdf.pdf
    },
    disabledNext() {
      return false
    }
  },
  async mounted() {
    this.loading = this.$loading({
      lock: true,
      text: '合同文件加载中，请耐心等待',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    const { width, height } = await pdfjsLib(this.electronicData.file)
    this.pdfWidth = width
  },
  methods: {
    numPages($event) {
      this.pageCount = $event;
    },
    //  接收拖拽事件
    dropEvent(event) {
      const type = event.dataTransfer.getData('type')
      if (type == 'sign') {
        this.addSign(event)
      } else if (type == 'seal') {
        this.addSeal(event)
      }

    },
    //  添加签署区
    addSign(event) {
      const { offsetX, offsetY } = event
      const positionId = Date.parse(new Date());
      this.signerList.push({
        ...this.electronicData.signers[0],
        posPage: this.currentPage,
        positionId,
        signDateBean: {
          format: ''
        },
        signType: 1,
        position: {
          posX: offsetX - (itemWidth / 2),
          posY: offsetY - (itemHeight / 2)
        }
      })
      this.sealActive = "";
      this.active = positionId;
    },
    // 添加骑缝章
    addSeal(event) {
      const { offsetY } = event;
      const positionId = Date.parse(new Date());
      this.sealList.push({
        ...this.electronicData.signers[0],
        positionId,
        signType: 2,
        position: {
          posY: offsetY - (itemHeight / 2)
        }
      })
      this.sealActive = positionId;
      this.active = "";
    },
    // 选择签署方
    signatory(id) {
      const index = this.electronicData.signers.findIndex(item => item.id == id);
      console.log(index)
      const { positionId, position, posPage, signDateBean, signType } = this.signerList[this.activeIndex];

      this.signerList.splice(this.activeIndex, 1)

      this.signerList.push({
        ...this.electronicData.signers[index],
        positionId, position, posPage,
        signDateBean,
        signType
      })
    },
    // 骑缝章选择签署方
    sealchange(id) {
      const index = this.electronicData.signers.findIndex(item => item.id == id);
      console.log(id)
      const { positionId, position, signType } = this.sealList[this.sealActiveIndex];
      this.sealList.splice(this.sealActiveIndex, 1);
      this.sealList.push({
        ...this.electronicData.signers[index],
        positionId, position, signType
      })
    },




    // pdf加载时
    loadPdfHandler(e) {

      this.currentPage = 1; // 加载的时候先加载第一页

    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    positionSwitch(item) {

      const pdfMain = document.getElementById("pdfMain");
      const boxHeight = pdfMain.offsetHeight;  //pdf高度
      if (item.signType == 1) {
        const { posX, posY } = item.position;
        return {
          posX: posX - 0 + (itemWidth / 2),
          posY: boxHeight - posY - (itemHeight / 2)
        }
      } else if (item.signType == 2) {
        const { posY } = item.position;
        return {
          posY: boxHeight - posY - (sealHeight / 2)
        }
      } else {
        return {}
      }

    },

    async tijiao({ voucherId, paymentMethod, price }) {
      const { insuranceId, insuranceVersion } = this.$store.state.insuranceSelect;
      let parm = {
        subject: this.electronicData.subject,
        files: this.electronicData.files,
        signers: this.signers,
        voucherId,
        paymentMethod
      }
      //  如果合同保id不为零  请求参数加上合同保参数  （本地设定合同保id为0 是暂不选择合同宝）
      if (insuranceId) {
        parm.insuranceId = insuranceId
        parm.insuranceVersion = insuranceVersion
      }
      const { status, data, info } = await this.$api.postContract(parm)
      if (status === 200) {
        if (paymentMethod == 2 && price) {
          this.aliform = data;
          this.$nextTick(() => {
            document.forms[0].submit();  //重点--这个才是跳转页面的核心,获取第一个表单并提交
          });
          return
        }
        this.$message({
          message: info,
          type: "success"
        })
        this.$router.push({
          path: '/electronicDocuments/electronicDocumentsSuccess',
          query: {
            url: data.signUrl,
            uuid: data.uuid
          }
        })

        this.$store.dispatch('user/getAccount')
        // 重置合同签订记录
        this.$store.dispatch('electronic/reset')
        this.$store.dispatch('insuranceSelect/insuranceSelectClear')
      } else {
        this.$message({
          message: info,
          type: "error"
        })
      }
    },
    async postContract() {
      let signers = this.electronicData.signers
      let obg = {}
      const list = [...this.signerList, ...this.sealList]
      list.forEach(item => {
        let data = {
          fileId: this.electronicData.files[0].fileId,
          signType: item.signType,
          ...this.positionSwitch(item)
        }
        if (item.signType == 1) {
          data.posPage = item.posPage;
          data.signDateBean = item.signDateBean;
        }
        if (obg[item.id]) {
          obg[item.id].push(data)
        } else {
          obg[item.id] = [data]
        }
      })
      let n = 0
      signers.forEach(e => {
        e.signFields = obg[e.id] || [];
        if (e.signFields.length == 0) {
          this.$message.error(`请设置${e.name || e.operatorName}的签署区`);
          n++;
        } else {
          const gaizhangquyu = e.signFields.filter(item => item.signType == 1);
          if (gaizhangquyu.length == 0) {
            this.$message.error(`请设置${e.name || e.operatorName}的签署区`);
            n++;
          }
        }
      });
      if (n > 0) return;
      this.signers = signers;
      this.$refs.hetongbuy.show({
        name: '发起合同',
        price: 5,
        balance: 11,
        courseId: 11,
      })
    },
    // //放大
    // scaleD () {
    //   this.scale += 5;
    //   this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
    // },
    // //缩小
    // scaleX () {
    //   if (this.scale === 100) {
    //     return;
    //   }
    //   this.scale += -5;
    //   this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
    // },
    // // 顺时针
    // clock () {
    //   this.pageRotate += 90;
    // },
    // // 逆时针
    // counterClock () {
    //   this.pageRotate -= 90;
    // },
    add(item) {
      this.signerList.push({
        posPage: this.currentPage,
        ...item,
        positionId: Date.parse(new Date()),
        position: {
          posX: 0,
          posY: 0
        }
      })
    },
    // 删除
    signerListDelete({ positionId }) {
      const index = this.signerList.findIndex(item => item.positionId == positionId)
      this.signerList.splice(index, 1);
      if (this.active == positionId) {
        this.active = "";
      }
    },
    // 骑缝章删除
    sealListDelete({ positionId }) {
      const index = this.sealList.findIndex(item => item.positionId == positionId)
      this.sealList.splice(index, 1);
      if (this.sealActive == positionId) {
        this.sealActive = "";
      }
    },
    sealMove(e) {
      const pdfMain = document.getElementById("pdfMain");
      const boxHeight = pdfMain.offsetHeight;  //pdf高度
      const pdfid = e.target.dataset.pdfid;
      let odiv = e.target;        //获取目标元素
      //算出鼠标相对元素的位置
      let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let top = e.clientY - disY;
        if (top < 0) {
          odiv.style.top = 0 + 'px';
        } else if (top > (boxHeight - sealHeight)) {
          odiv.style.top = boxHeight - sealHeight + 'px'; // 印章
        } else {
          odiv.style.top = top + 'px';
        }
      }
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
        const index = this.sealList.findIndex(item => item.positionId == pdfid)
        this.sealList[index].position = {
          posY: odiv.style.top.replace("px", "")
        }
      }
    },
    move(e) {
      const pdfMain = document.getElementById("pdfMain");

      const boxWidth = pdfMain.offsetWidth;  // pdf宽度
      const boxHeight = pdfMain.offsetHeight;  //pdf高度
      const pdfid = e.target.dataset.pdfid;

      let odiv = e.target;        //获取目标元素

      //算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      //后台要的元素位置   左下角为（0，0）
      let posX = 50, posY = boxHeight - (itemHeight / 2);
      document.onmousemove = (e) => {       //鼠标按下并移动的事件

        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        // 612 792
        if (left < 0) {
          odiv.style.left = 0 + 'px';
          posX = 0 + (itemWidth / 2);
        } else if (left > (boxWidth - itemWidth)) {
          odiv.style.left = boxWidth - itemWidth + 'px';   // 印章
          posX = boxWidth - itemWidth + (itemWidth / 2);
        } else {
          odiv.style.left = left + 'px';
          posX = left + (itemWidth / 2);
        }

        if (top < 0) {
          odiv.style.top = 0 + 'px';
          posY = boxHeight - (itemHeight / 2)
        } else if (top > (boxHeight - itemHeight)) {
          odiv.style.top = boxHeight - itemHeight + 'px'; // 印章
          posY = itemHeight - (itemHeight / 2)
        } else {
          odiv.style.top = top + 'px';
          posY = boxHeight - top - (itemHeight / 2)
        }
        // this.disX = posX
        // this.disY = posY
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
        console.log(e)
        const index = this.signerList.findIndex(item => item.positionId == pdfid)
        this.signerList[index].position = {
          posX: odiv.style.left.replace("px", ""),
          posY: odiv.style.top.replace("px", "")
        }
        // this.signerList[index].position.posX = posX;
        // this.signerList[index].position.posY = posY;
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/style/var.scss";

.ElectronicDocumentsPDF {
  display: flex;

  .bottom {
    width: 100%;
    height: 72px;
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);

    & /deep/.mElectronicFooter {
      margin-top: 0;
      width: 1200px;
      box-shadow: none;
    }
  }

  .left-box {
    background: #fff;
    width: 210px;
    padding: 0 20px;
    padding-top: 20px;
    position: fixed;
    height: 100vh;
    left: 0;

    .sign,
    .seal {
      width: 100%;
      font-size: 12px;
      color: $--color-primary;
      border: 1px dashed $--color-primary;
      line-height: 30px;
      padding: 0 20px;
      user-select: none;
      cursor: move;
    }

    .seal {
      margin-top: 10px;
    }
  }

  .right-box {
    background: #fff;
    width: 210px;
    padding: 0 20px;
    padding-top: 20px;
    position: fixed;
    height: 100vh;
    right: 0;

    .label {
      font-size: 14px;
      margin: 10px 0;
    }

    .text {
      margin-top: 100%;
      color: #999;
      font-size: 12px;
      line-height: 17px;
      text-align: center;

      i {
        font-size: 30px;
        font-weight: 100;
      }
    }
  }

  .box {
    margin: 0 auto;
    position: relative;
    width: 595px;
    margin-bottom: 80px;

    .main {
      position: absolute;
      left: 0;
      top: 0;

      // height: 792px !important;
      height: 100%;
      width: 100%;
      box-sizing: content-box;
      // border: 2px solid $--color-primary;
      box-shadow: 4px 5px 10px;

      // overflow: hidden;
      .username {
        position: absolute;
        font-size: 10px;
        background: $--color-primary;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        /* for Opera */
        text-align: center;
        width: 100%;
        top: -20px;
        color: #fff;
        display: none;
      }

      .delete {
        position: absolute;
        font-size: 10px;
        line-height: 20px;
        // bottom: -20px;
        bottom: 0;
        background: rgba($color: #000000, $alpha: 0.6);
        color: #fff;
        padding: 0 10px;
        visibility: hidden;
        transform: translate(-50%, 100%);
        left: 50%;
        cursor: pointer;

        &:hover {
          visibility: visible;
        }
      }

      .sealNode {
        width: 58px;
        height: 118px;
        position: absolute;
        border: 1px solid $--color-primary;
        background: rgba($color: #fff, $alpha: 0.8);
        display: flex;
        align-items: center;
        justify-content: center;

        .content {
          pointer-events: none;
          user-select: none;
        }

        .delete {
          padding: 0;
          width: 100%;
          text-align: center;
        }

        .username {
          left: 0;
        }

        &:hover {
          .username {
            display: block;
          }

          .delete {
            visibility: visible;
          }
        }
      }

      .signNode {
        border: 1px solid $--color-primary;
        position: absolute;
        width: 120px;
        height: 120px;
        background: rgba($color: #fff, $alpha: 0.8);

        .content {
          height: 100%;
          width: 100%;
          pointer-events: none;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;

          .date {
            position: absolute;
            bottom: 0px;
            color: #999;
            line-height: 18px;
            font-size: 14px;
          }
        }

        &:hover {
          .username {
            display: block;
          }

          .delete {
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>