/*
* @Author: 曹俊杰
* @Date: 2024-09-30 10:33:35
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-07 12:08:49
*/核查结果
<template>

    <el-drawer :visible.sync="isshow" direction="rtl" size="988px" :with-header="false" append-to-body>
        <div class="content">
            <div class="title">
                <div class="title-l">风险排查结果</div>
                <div class="title-r" @click="hidd">确认</div>
            </div>
            <div class="box">
                <div class="info1">
                    <div class="box-title">排查人信息</div>
                    <div class="info1-content">
                        <div class="info1-item">姓名：{{ detail.name }}</div>
                        <div class="info1-item">手机号：{{ detail.phone }}</div>
                        <div class="info1-item">身份证号码：{{ detail.idNumber }}</div>
                    </div>
                </div>
                <div class="info2">
                    <div class="box-title">曝光台检索</div>
                    <div class="info2-content" v-for="item in detail.exposureList" :key="item.uuid">
                        <el-descriptions class="margin-top" :colon="false" :column="8" direction="vertical">
                            <el-descriptions-item label="曝光日期">{{ item.createdAt | timeFormat('yyyy-mm-dd')
                                }}</el-descriptions-item>
                            <el-descriptions-item label="违约主播">
                                <span class="red">{{ item.name }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="身份证号码" :span="2">
                                <span class="red">{{ item.idNumber }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="手机号">
                                <span class="red">kooriookami</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="曝光方" :span="2">kooriookami</el-descriptions-item>
                            <el-descriptions-item label="曝光账号">kooriookami</el-descriptions-item>
                            <el-descriptions-item label="违约情况" :span="8">{{ item.content }}</el-descriptions-item>

                        </el-descriptions>
                    </div>
                    <el-empty description="无曝光台排查信息" :image-size="30" v-if="!detail.exposureList.length"></el-empty>
                </div>
                <div class="info3">
                    <div class="box-title">重复签约检索</div>
                    <div class="info3-box" v-if="detail.contractList.length">
                        <div class="info3-tab">
                            <div class="info3-col1">合同名称</div>
                            <div class="info3-col2">编号</div>
                            <div class="info3-col3">签约日期</div>
                            <div class="info3-col4">发起方</div>
                            <div class="info3-col5">签约方信息</div>
                        </div>
                        <div class="info3-content">
                            <div class="info3-item" v-for="item in detail.contractList" :key="item.contractId">
                                <div class="info3-col1 filter">{{ item.subject }}</div>
                                <div class="info3-col2 filter">{{ item.contractNo }}</div>
                                <div class="info3-col3 ">{{ item.finishedAt | timeFormat }}</div>
                                <div class="info3-col4 ">{{ maskStringWithRegex(item.signers[0].name) }}</div>
                                <div class="info3-col5 ">{{ item.signers[1].operatorName }} ｜ {{
                                    item.signers[1].operatorPhone }} ｜ {{ item.signers[1].operatorIdNumber }} </div>
                            </div>
                        </div>
                    </div>
                    <el-empty :image-size="30" description="无重复签约检索排查信息" v-else></el-empty>
                </div>
                <div class="info4">
                    <div class="box-title">三要素报告</div>
                    <div class="info4-content" v-if="detail.verification">
                        <div class="info4-content-title">核查报告</div>
                        <daojishi :targetTime="detail.verification.createdAt" @onchick="sanyaosuDetail" />
                    </div>
                    <el-empty :image-size="30" description="无三要素报告" v-else></el-empty>
                </div>
            </div>
            <sanyaosu ref="sanyaosu" />
            <!-- <el-table :data="[]">
                <el-table-column property="date" label="日期" width="150"></el-table-column>
                <el-table-column property="name" label="姓名" width="200"></el-table-column>
                <el-table-column property="address" label="地址"></el-table-column>
            </el-table> -->
        </div>

    </el-drawer>
</template>

<script>
import daojishi from "@/components/daojishi.vue";
import sanyaosu from "./sanyaosu"
export default {
    data() {
        return {
            isshow: false,
            detail: {
                companyId: null,
                content: "曝光台检索、重复签约检查、三要素报告",
                contractList: [],
                createdAt: null,
                detectionId: 2,
                empId: 4096,
                exposureList: [],
                idNumber: "",
                name: "",
                phone: "",
                verification: null,
                verificationId: null,
            },
            detectionId: null
        }
    },
    components: {
        daojishi,
        sanyaosu
    },
    methods: {
        maskStringWithRegex(name) {
            if (name.length <= 8) {
                return name; // 如果字符串长度小于等于 8，直接返回原字符串
            }
            const start = name.slice(0, 2); // 开头两个字符
            const end = name.slice(-6);    // 结尾六个字符
            const middle = '*'.repeat(name.length - 8); // 中间部分用 * 填充
            return start + middle + end;  // 拼接结果
        },
        show(detectionId) {
            this.detectionId = detectionId
            this.getDetectionDetail(detectionId)
            this.isshow = true;
        },
        hidd() {
            this.isshow = false;
            this.$emit("callback")
        },
        async getDetectionDetail() {
            const { detectionId } = this
            const { data, status } = await this.$api.getDetectionDetail({ detectionId });
            console.log(data, status)
            this.$nextTick(() => {
                this.detail = data
            })
            // if (status === 200) {
            //     this.detali = data;
            // }
        },
        sanyaosuDetail() {
            this.$refs.sanyaosu.show(this.detail)
        }
    }

}
</script>

<style lang="scss" scoped>
.content {
    .title {
        height: 60px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        &-l {
            color: #262626;
            font-family: "Source Han Sans CN";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &-r {
            width: 78px;
            height: 32px;
            flex-shrink: 0;
            border-radius: 2px;
            background: #0062FF;
            color: #fff;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
        }
    }

    .box {
        padding: 0 20px;

        &-title {
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 50px;
            margin-top: 20px;
        }

        .info1 {
            &-content {
                display: flex;

            }

            &-item {
                width: 33.33%;
                line-height: 50px;
                color: #666;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                padding-left: 16px;
            }

            border-bottom: 1px #eee solid;
        }

        .info2 {
            &-content {
                border: #eee 1px solid;
                border-radius: 4px;
                background: #F3F6F8;
                padding: 0 16px;
                padding-top: 16px;
                margin-bottom: 16px;
            }


            &/deep/.el-descriptions__body {
                background: #F3F6F8;
                color: #262626;
            }

            .red {
                color: #FF0D0D;
            }
        }

        .info3 {
            &-tab {
                display: flex;
            }

            &-box {
                border: 1px solid #eee;
                background: #F3F6F8;
                border-radius: 4px;
                color: #666;
                font-family: "Source Han Sans CN";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &-col1,
            &-col2,
            &-col3,
            &-col4,
            &-col5 {
                padding: 10px 16px;
                position: relative;
            }

            .filter {
                filter: blur(8px);
            }

            &-col1 {
                width: 110px;
            }

            &-col2 {
                width: 80px;
            }

            &-col3 {
                width: 100px;
            }

            &-col4 {
                width: 280px;
            }

            &-col5 {
                flex: 1;
            }

            &-item {
                display: flex;
            }
        }

        .info4 {
            &-content {
                border: #eee 1px solid;
                border-radius: 4px;
                background: #F3F6F8;
                padding: 0 16px;
                height: 60px;
                display: flex;
                align-items: center;

                &-title {
                    font-size: 14px;
                    color: #999;
                    margin-right: 6px;
                }
            }
        }
    }
}
</style>
