<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="header">
        <el-button type="text" class="back" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
        <div class="tittle">核查结果</div>
      </div>
      <div id="container">
        <div class="conter">
          <img src="@/static/image/inspect/seal_1.png" class="seal" />
          <!-- <img src="@/static/image/inspect/seal_2.png" class="seal" /> -->
          <div class="title">
            <div class="title-l">
              <img src="@/static/image/inspect/image001.jpg" alt="" srcset="">
              <p>审批报告</p>
            </div>
            <div class="title-r">
              <p>报告编号：3030569_20231214174207_58691</p>
              <p>D9DA18,3030569_20231214174207_04211DABA19</p>
              <p>生成时间：2023-12-14 17:42:07</p>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="top">
            <div class="top-l">
              <p>验证规则：<span class="succ">低风险</span> </p>
              <p class="des">(MCP_BR0001195 / 预置_验证流程策略_001)</p>
            </div>
            <div class="top-r">
              <p>反欺诈规则： <span class="err">高风险</span></p>
              <p class="des">(STR0040902 / 贷前策略api)</p>
            </div>
          </div>
          <div class="hint">
            <div class="hint-l">规则风险提示</div>
            <div class="hint-r">
              该用户共命中 <span class="red">0</span> 条异常规则信息
            </div>
          </div>




          <!-- 要素核查 -->
          <div>
            <h4>要素核查</h4>
            <div class="box">
              <p>策略描述： 使用姓名、手机号、身份证号、银行卡号等信息对被查询人进行信息核</p>
              <p>
                结论分析：
                <span><i class="el-icon-check dui"></i> 验证通过</span>
              </p>
            </div>
          </div>
          <!-- 运营商核验 -->
          <div>
            <h4>运营商核验</h4>
            <div class="box">
              <p>策略描述： 查询被查询人的运营商信息</p>
              <p>
                结论分析：
                <span><i class="el-icon-check dui"></i> 验证通过</span>
              </p>
            </div>
          </div>
          <!-- 风险识别 -->
          <div>
            <h4>风险识别</h4>
            <div class="box">
              <p>策略描述： 检测被查询人的借贷风险情况，及在司法体系中是否存失信行为等风险</p>
              <p>
                结论分析：
                <span><i class="el-icon-close wu"></i> 无结果</span>
              </p>
            </div>
          </div>
          <!-- 借贷评估 -->
          <div>
            <h4>借贷评估
              <span style="font-size:12px;font-weight:400;color: #84919a;">该产品类命中 <span
                  style="color:#ff6365;font-weight:600">1</span> 条高风险</span>
            </h4>
            <div class="box">
              <p>策略描述： 检测被查询人的借贷风险情况，及在司法体系中是否存失信行为等风险</p>
              <p>
                结论分析：
                <span><i class="el-icon-ali-gaoxiao text-red"></i> 在非银机构申请次数极多</span>
              </p>
            </div>
          </div>

          <div class="tableHeader">
            <div class="tableHeader_number">01</div>
            <div class="tableHeader_title">
              <div class="tableHeader_title_t">要素核查产品-命中详情</div>
              <div class="tableHeader_title_b">Element verification</div>
            </div>
          </div>

        </div>
      </div>
    </el-card>
  </div>

</template>

<script>

export default {
  components: {
  },
  computed: {
  },
  created() {

  },
  destroyed() {

  },
  mounted() {


  },
  data() {
    return {

    }
  },
  methods: {


  }
}
</script>

<style scoped lang="scss">
$succ: #40c2bd;
$err: #ff6365;

.text-red {
  color: $err
}

.text-green {
  color: $succ;
}

.header {
  position: relative;

  .tittle {
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
    border-bottom: 1px solid #ebeef5;
  }

  .back {
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

#container {
  display: flex;
  justify-content: center;
  padding: 20px;

  h4 {
    border-left: 4px #60acef solid;
    padding-left: 14px;
    margin: 8px 0;
    font-weight: 600;
    margin-top: 20px;
  }

  .dui {
    background: $succ;
    color: #fff;
    border-radius: 2px;
  }

  .wu {
    background: #999999;
    color: #fff;
    border-radius: 2px;
  }

  .gao {
    color: $err;
  }

  .box {
    background: #eaedf3;
    border-radius: 10px;
    padding: 20px 14px;
    font-size: 13px;
    line-height: 30px;
  }

  .conter {
    background: #fff;
    width: 860px;
    min-height: 1217px;
    padding: 60px 50px;
    box-shadow: 2px 2px 20px 0px #ccc;
    position: relative;

    .seal {
      position: absolute;
      width: 130px;
      height: 130px;
      top: 100px;
      right: 50px;
      z-index: 2;
    }

    .title {
      display: flex;
      justify-content: space-between;

      &-l {
        display: flex;
        font-size: 30px;
        font-weight: 900;
        line-height: 67px;
      }

      &-r {
        font-size: 12px;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    .top {
      display: flex;

      .succ {
        color: $succ;
      }

      .err {
        color: $err;
      }

      .succ,
      .err {
        font-size: 18px;
      }

      .des {
        color: #84919a;
        font-size: 12px;
      }

      &-l,
      &-r {
        width: 45%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 47px;
        background-repeat: no-repeat;
        background-size: 40px;
      }

      &-l {
        background-image: url(~@/static/image/inspect/1.png);
      }

      &-r {
        background-image: url(~@/static/image/inspect/2.png);
      }
    }

    .hint {
      height: 37px;
      display: flex;
      line-height: 37px;
      margin-top: 20px;
      background: #fff6f6;
      border-radius: 5px;

      &-l {
        width: 140px;
        background: $err;
        height: 37px;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        font-size: 14px;
      }

      &-r {
        font-size: 12px;
        margin-left: 20px;

        .red {
          color: $err;
        }
      }
    }
  }
}


.tableHeader {
  height: 40px;
  display: flex;
  align-items: center;
  &_number {
    font-size: 45px;
    color: #939393;
  }

  &_title {
    margin-left: 15px;
    &_t {
      font-size: 20;
    }

    &_b {
      margin-top: 10px;
      font-size: 12px;
      color: #939393;
    }
  }
}
</style>
