
// 权益执行表单
const equityFrom = {
    type: null,
    subjectTypeA: null,
    subjectA: '',
    contactA: '',
    phoneA: '',
    weixinA: '',
    subjectTypeB: null,
    subjectB: null,
    phoneB: '',
    weixinB: '',
    verdictUrl: '',
    proofOfService: '',
    proofOfEffective: '',

}


const state = {
    equityFrom
}
const mutations = {
    setEquityFrom(state, payload) {
        const [key] = Object.keys(payload);
        state.equityFrom[key] = payload[key]
    },
    clearEquityFrom(state) {
        state.equityFrom = equityFrom
    }
}
const actions = {}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}