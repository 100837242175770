<template>
    <div class="qiye" @click="onclick">
        <div class="qiye-f qiye-item" v-if="!value">
            <div class="qiye-item-t">企业</div>
            <span>
                <span>享受</span>
                <span class="red">立减 5 折</span>
                <span>优惠价</span>
            </span>
            <svg class="qiye-item-b" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                fill="none">
                <path d="M0 16L16 0V14C16 15.1046 15.1046 16 14 16H0Z" fill="#EEEEEE" />
                <path d="M9.5 10.5L11 12L14 9" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <div class="qiye-t qiye-item" v-else>
            <div class="qiye-item-t">企业</div>
            <span class="red">
                <span>享受</span>
                <span>立减 5 折</span>
                <span>优惠价</span>
            </span>
            <svg class="qiye-item-b" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                fill="none">
                <path d="M0 16L16 0V14C16 15.1046 15.1046 16 14 16H0Z" fill="url(#paint0_linear_4_299)" />
                <path d="M9.5 10.5L11 12L14 9" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_4_299" x1="0.5" y1="0.399999" x2="19.237" y2="7.76636"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#F07B41" />
                        <stop offset="1" stop-color="#EB412A" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {

        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        };
    },
    created() { },
    computed: {},
    methods: {
        onclick(){
            this.$emit('input',!this.value)
        }
    },
};
</script>

<style scoped lang='scss'>
.qiye {
    .red {
        color: #FF0D0D;
    }

    &-f {}

   

    &-item {
        width: 280px;
        height: 44px;
        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid #EEE;
        background: #FFF;
        line-height: 44px;
        text-align: center;
        position: relative;
        cursor: pointer;

        &-t {
            width: 32px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            color: #FFF;
            font-size: 10px;
            border-radius: 4px 0px 4px 0px;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(111deg, #F07B41 2.95%, #EB412A 100%);
        }

        &-b {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    &-t {
        background: linear-gradient(111deg, rgba(240, 123, 65, 0.10) 2.95%, rgba(232, 51, 26, 0.10) 100%);
        border: 1px solid #F07B41;
    }
}
</style>