// import { ElMessage } from "element-plus";
import OSS from 'ali-oss'
import utils from "@/utils/utils"
//  上传
const uploadFile = (file, option,mulu='znsign') => {
	const client = new OSS({
		// yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
		region: 'oss-cn-beijing',
		// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
		accessKeyId: option.accessKeyId,
		accessKeySecret: option.accessKeySecret,
		// 从STS服务获取的安全令牌（SecurityToken）。
		stsToken: option.securityToken,
		// 填写Bucket名称。
		bucket: 'meimengapp'
	});

	return client.put(
		`${mulu}/${utils.guid() + file.name}`,
		file
		//{headers}
	);

};
export default uploadFile