
import api from '@/utils/api'
import utils from '@/utils/utils'
import hdqSection from "@/components/hdqSection.vue"
import Mavatar from "@/components/Mavatar.vue"
import im from '@/utils/im';

const install = (Vue) => {
	//api
	Vue.prototype.$api = api;
	Vue.prototype.$utils = utils;
	Vue.prototype.$im = im;

	// 时间格式化
	Vue.filter('timeFormat', (timestamp, format, shortYear) => utils.timeFormat(timestamp, format, shortYear))
	// 将多久以前的方法，注入到全局过滤器
	Vue.filter('timeFrom', (timestamp, format) => utils.timeFrom(timestamp, format))
	Vue.filter('formatBytes', (a, b) => utils.formatBytes(a, b))
	Vue.filter('priceFormat', (number, decimals, decimalPoint, thousandsSeparator) => utils.priceFormat(number, decimals, decimalPoint, thousandsSeparator))
	// 全局组件
	Vue.component('hdqSection', hdqSection)
	Vue.component('Mavatar', Mavatar)

}
export default install