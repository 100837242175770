/*
* @Author: 曹俊杰
* @Date: 2024-09-29 23:08:59
* @Last Modified by: 曹俊杰
* @Last Modified time: 2024-11-16 22:29:28
*/风险排查 是否核验三要素
<template>
    <div class="verification">
        <div class="item" :class="{ 'item-active': formdata.exposure }" @click="onClick('exposure')">
            <div class="top">
                <div class="top-tit">曝光台检索</div>
                <div class="top-ico mianfei">免费</div>
            </div>
            <div class="dsc">系统内曝光台进行检索，避免违约主播骗签</div>
            <c-radio :value="formdata.exposure" />
        </div>
        <div class="item" :class="{ 'item-active': formdata.contract }" @click="onClick('contract')">
            <div class="top">
                <div class="top-tit">重复签约检索</div>
                <div class="top-ico mianfei">免费</div>
            </div>
            <div class="dsc">系统内已签约信息检索，避免多次恶意签约</div>
            <c-radio :value="formdata.contract" />
        </div>
        <div class="item " :class="{ 'item-active': formdata.verification }" @click="onClick('verification')">
            <div class="top">
                <div class="top-tit">三要素报告</div>
                <div class="top-ico shoufei">20元/次</div>
            </div>
            <div class="dsc">大数据综合判断，需被查询人手机号接受短信验证码</div>
            <c-radio :value="formdata.verification" />
        </div>

    </div>
</template>

<script>
import cRadio from "@/components/form/cRadio.vue"
export default {
    components: {
        cRadio
    },
    props: {
        formdata: {
            type: Object,
            default: () => ({
                verification: false,
                exposure: false,
                contract: false,
            })
        }
    },
    methods: {
        onClick(key) {
            let value = !this.formdata[key]
            this.input(value, key)
        },
        input(value, key) {
            console.log(value)
            this.$emit('change', {
                ...this.formdata,
                [key]: value
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.verification {
    display: flex;

    .item:nth-child(3n - 1) {
        margin: 0 20px;
    }

    .item {
        cursor: pointer;
        width: calc((100% - (20px * 2)) / 3);
        border-radius: 4px;
        border: 1px solid #eee;
        padding: 20px;
        background: linear-gradient(90deg, #FFF 0%, #F8F8F8 100%);
        height: 133px;
        position: relative;

        .top {
            display: flex;
            align-items: center;

            &-tit {
                font-family: "Source Han Sans CN";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1px;
            }

            &-ico {
                // height: 16px;
                padding: 0 6px;
                flex-shrink: 0;
                border-radius: 2px;

                font-family: "Source Han Sans CN";
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                margin-left: 10px;
            }

            .mianfei {
                background: #D2DEFB;
                color: #0062FF;
            }

            .shoufei {
                color: #FF8412;
                background: #FFF3E7;
            }
        }

        .dsc {
            width: 200px;
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1px;
            margin-top: 10px;
        }

        &/deep/.svgicon {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 20px;
        }
    }

    .item-active {
        border: 1.5px solid #1F5AEC;
        background: #EFF6FF;
    }
}
</style>