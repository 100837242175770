import hdqPagination from "@/components/hdqPagination"
import etable from "@/views/ElectronicDocuments/etable"
import dirDialog from "@/views/ElectronicDocuments/components/dirDialog"
import insuranceDialog from "@/components/insuranceDialog"
import { mapState } from 'vuex'
export default {
    components: {
        hdqPagination,
        dirDialog,
        etable,
        insuranceDialog
    },
    data() {
        return {
            list: []
        }
    },
    computed: {
        ...mapState({
            phone: state => state.user.user.phone
        }),
    },
    methods: {

        async getList() {
            const { current, size } = this.$refs.pagination;
            const { params } = this.$route;
            let { listParams } = this;
            let time = {
                initiatedAtFrom: null,
                initiatedAtTo: null
            }
            if (listParams.initiated) {
                const [star, end] = listParams.initiated;
                time.initiatedAtFrom = star;
                time.initiatedAtTo = end;
                let { obj, ...initiated } = listParams;
                listParams = obj;
            }
            const { status, data } = await this.$api.getContract({ current, size, ...listParams, ...params, ...time })
            if (status == 200) {
                this.list = data.records;
                this.$refs.pagination.createPagination(data)
            }

        },
        guidang(data) {
            this.$refs.dirDialog.show(data)
        },
        downFile(fileId) {
            this.$store.dispatch('documents/getFile', {
                fileId, callback: ({ downloadUrl }) => {

                    window.open(downloadUrl)
                }
            })
        },
        insuranceDialogShow(data) {
            this.contractUuid = data.contractUuid
            this.$refs.insuranceDialog.show(data)
        },
        operate({ type, data }) {
            if (type == 'guidang') {
                this.guidang(data)
            } else if (type == 'chakan') {
                this.downFile(data.files[0].fileId)
            } else if (type == "xiazai") {
                this.downFile(data.files[0].fileId)
            } else if (type == "chuzheng") {
                this.$alert("出证请联系平台客服", "提示")
                // this.$router.push({ path: '/accurateEvidence/extension/apply', query: { data: JSON.stringify(data) } })
            } else if (type == "hetongbao") {
                this.insuranceDialogShow(data)
            } else if (type == 'chexiao') {
                this.deleteContract(data)
            } else if (type == 'xiugai') {
                this.updateContract(data)
            }
        },
        // 修改合同
        updateContract({ uuid, subject }) {
            this.$prompt('请输入合同的新名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: subject,
                inputPattern: /.+/,
                inputErrorMessage: '名称不能为空'
            }).then(async ({ value }) => {
                const { status, info } = await this.$api.putContract({
                    subject: value,
                    uuid
                })
                if (status == 200) {
                    this.$message({
                        type: 'success',
                        message: info
                    })
                    this.getList()

                } else {
                    this.$message({
                        type: 'error',
                        message: info
                    })
                }
            }).catch((res) => {
                console.log(res)
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

        },
        // 撤销合同
        async deleteContract({ uuid, subject }) {
            const { current, size } = this.$refs.pagination;
            const { status, info } = await this.$api.postRevokeContract({ phone: this.phone })
            if (status != 200) {
                this.$message({
                    type: 'error',
                    message: info
                })
                return
            }
            try {
                const { value: verifyCode } = await this.$prompt(`撤销合同《${subject}》,撤销后不可恢复。需要验证当前手机${this.phone}验证码。`, '请输入手机验证码', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^\d{4}$/,
                    inputErrorMessage: '请输入4位数验证码',
                    type: 'warning'
                })
                const { status, info } = await this.$api.deleteContract({ uuid, verifyCode })
                if (status == 200) {
                    this.$message({
                        type: 'success',
                        message: info
                    })
                    this.getList()
                } else {
                    this.$message({
                        type: 'error',
                        message: info
                    })
                }
            } catch (error) {
                console.log(error)
                if (error == 'cancel') {
                    this.$message({
                        message: `取消撤销《${subject}》`
                    })
                }
            }
        }
    },
}