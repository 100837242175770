<!-- 强制排查的回显确认 -->
<template>
  <div class="qiangzhiDialog">
    <el-dialog :visible.sync="dialogVisible" width="450px" :show-close="false" :modal-append-to-body="false" :close-on-click-modal="false">
      <div class="content">
        <div class="content_header">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-if="text">
            <path
              d="M10 20C4.47725 20 0 15.5228 0 10C0 4.47725 4.47725 0 10 0C15.5228 0 20 4.47725 20 10C20 15.5228 15.5228 20 10 20ZM8.9965 14.75C9.749 13.372 12.3773 10.907 14.254 9.649L14.9915 9.19725C15.282 9.04 15.536 8.932 15.7387 8.887C15.16 7.5645 15.6675 6.48975 15.75 5.25C15.0525 5.60775 14.349 6.113 13.6705 6.68875L12.8853 7.3965C10.6235 9.54525 8.8215 12.1935 8.8215 12.1935L7.5005 9.6305L4.5 11.0935C5.7695 11.5472 7.71175 13.1652 8.993 14.7493L8.9965 14.75Z"
              fill="#00C152" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-else>
            <g clip-path="url(#clip0_2516_4374)">
              <path
                d="M9.91796 -0.0827026C4.39518 -0.0827026 -0.0820312 4.3945 -0.0820312 9.91729C-0.0820312 15.4401 4.39518 19.9173 9.91796 19.9173C15.4407 19.9173 19.9179 15.4401 19.9179 9.91729C19.9179 4.3945 15.4407 -0.0827026 9.91796 -0.0827026ZM14.5032 12.7347C14.6556 12.8873 14.6556 13.1344 14.5032 13.2871L13.2877 14.5026C13.1351 14.6549 12.888 14.6553 12.7353 14.5026L9.91796 11.6852L7.10057 14.5026C6.94791 14.6549 6.70051 14.6549 6.54817 14.5026L5.33267 13.2871C5.18034 13.1347 5.18034 12.8873 5.33267 12.7347L8.15038 9.91729L5.33267 7.09992C5.18034 6.94726 5.18034 6.69986 5.33267 6.54753L6.54817 5.332C6.70085 5.17969 6.94791 5.17969 7.10057 5.332L9.91796 8.14973L12.7353 5.332C12.888 5.17969 13.1351 5.17969 13.2877 5.332L14.5032 6.54753C14.6556 6.69986 14.6556 6.94726 14.5032 7.09992L11.6859 9.91729L14.5032 12.7347Z"
                fill="#FF0D0D" />
              <path
                d="M14.5016 12.7347C14.6539 12.8873 14.6539 13.1344 14.5016 13.2871L13.2861 14.5026C13.1334 14.6549 12.8864 14.6553 12.7337 14.5026L9.91633 11.6852L7.09895 14.5026C6.94629 14.6549 6.69889 14.6549 6.54655 14.5026L5.33105 13.2871C5.17871 13.1347 5.17871 12.8873 5.33105 12.7347L8.14876 9.91729L5.33105 7.09993C5.17871 6.94727 5.17871 6.69987 5.33105 6.54753L6.54655 5.33201C6.69923 5.1797 6.94629 5.1797 7.09895 5.33201L9.91633 8.14974L12.7337 5.33201C12.8864 5.1797 13.1334 5.1797 13.2861 5.33201L14.5016 6.54753C14.6539 6.69987 14.6539 6.94727 14.5016 7.09993L11.6842 9.91729L14.5016 12.7347Z"
                fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_2516_4374">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p style="margin-left:10px">查询结果</p>
          <svg class="close" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" @click="handleClose">
            <path
              d="M8.44195 9.73268C8.79838 10.0891 9.37626 10.0891 9.73268 9.73268C10.0891 9.37626 10.0891 8.79838 9.73268 8.44195L6.29073 5L9.73268 1.55805C10.0891 1.20163 10.0891 0.623745 9.73268 0.267319C9.37626 -0.0891065 8.79838 -0.089106 8.44195 0.267319L5 3.70927L1.55805 0.267319C1.20162 -0.0891064 0.623745 -0.0891065 0.267319 0.267319C-0.0891064 0.623745 -0.0891064 1.20162 0.267319 1.55805L3.70927 5L0.267319 8.44195C-0.0891063 8.79838 -0.0891064 9.37626 0.267319 9.73268C0.623745 10.0891 1.20162 10.0891 1.55805 9.73268L5 6.29073L8.44195 9.73268Z"
              fill="#666666" />
          </svg>
        </div>
        <div class="text">
          <div class="text-t" v-if="title">{{ title }}</div>
          <div class="text-b" :style="title?'':'margin-top:20px'">
            <div v-if="text">{{ text }}</div>
            <div class="none" v-else>
              <img class="none-img" src="@/static/image/qiangzhiDialog.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: '',
      title: '',
      dialogVisible: false
    }
  },
  methods: {
    show({ text, title }) {
      this.text = text;
      this.title = title;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.text = '';
      this.title = '';
    }
  }

}
</script>

<style lang="scss" scoped>
.qiangzhiDialog {
  &/deep/.el-dialog {
    background: transparent;
    box-shadow: none;
    &__header {
      padding: 0;
    }

    &__body {
      padding: 0;
    }
  }
  .content {
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
    &_header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      background: #f3f6f8;
      color: #262626;
      /* 18px 二级标题 / 模块标题 / 粗体 */
      font-family: "Source Han Sans CN";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
      .close {
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
    }
    .text {
      &-t {
        height: 50px;
        text-align: center;
        line-height: 50px;

        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      &-b {
        max-height: 310px;
        overflow-y: auto;
        color: #666;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 178.571% */
        padding: 0 40px;
        padding-bottom: 24px;
        .none {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 60px;
        }
      }
    }
  }
}
</style>