/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-27 14:34:32
 * @Module: 电子文书成功页
 */
 <template>
  <div class="ElectronicDocumentsSuccess">
    <e-header :step="4" />
    <div class="content">
      <el-result icon="success" title="提交完成" subTitle="请查看短信根据提示进行操作">
        <template slot="extra">
          <el-button type="primary" size="mini" @click="$router.push({name:'home'})">返回首页</el-button>
        </template>
      </el-result>
    </div>
    <!-- <div class="code">
      <p>扫码完成签署</p>
      <p>签署代表您的真实意愿并将产生相应的法律效力</p>
      <vue-qr :text="$route.query?$route.query.url:''" :size="200" style="margin:0 auto;"></vue-qr>
      <p>请用
        <span>支付宝APP</span>
        “扫一扫”
      </p>
    </div> -->
  </div>
</template>
 <script>
import eHeader from "./components/eHeader.vue"
// import vueQr from 'vue-qr'
export default {
  components: { eHeader },
  data () {
    return {
      url: 'asd'
    };
  },
  mounted () {
  },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.content {
  background: #fff;
  margin-top: 20px;
}
.code {
  background: #fff;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  p:nth-child(1) {
    font-size: 20px;
    font-weight: 400;
    color: #262626;
    line-height: 28px;
    margin-top: 30px;
  }
  p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 10px;
  }
  p:nth-child(4) {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    span {
      color: #0062ff;
    }
  }
}
.ElectronicDocumentsSuccess {
  &/deep/ .eHeader {
    .btn {
      display: none;
    }
    .close {
      display: none;
    }
  }
}
</style>