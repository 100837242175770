/*
* @Author: 曹俊杰
* @Date: 2023-01-03 16:02:31
* @Module: 菜单
*/
<template>
  <ul class="menu">
    <router-link v-for="item in menuList" class="item pointer" :target="item.target"
      :style="$route.name === 'home' ? 'color:#fff' : ''"
      :active-class="$route.name === 'home' ? 'home-active' : 'def-active'" :key="item.name" :to='`/${item.name}`'
      v-show="authFn(item)">
      <li>
        {{ item.title }}
      </li>

      <div class="line"></div>
    </router-link>
  </ul>
</template>
<script>
import menuAuthority from "./menuAuthority.js"

export default {
  mixins: [menuAuthority],
  components: {},
  data() {
    return {
      menuList: [
        {
          title: '工作台',
          name: 'home',
          target: '_self',
          authority: []
        },
        {
          title: '风险排查',
          name: 'inspect',
          target: '_self',
          authority: ['companyAuth']
        },
        {
          title: '合同',
          target: '_self',
          name: 'electronicDocumentsList',
          authority: ['companyAuth']
        },
        // {
        //   title: '出证',
        //   target: '_self',
        //   name: 'accurateEvidence',
        //   authority: ['companyAuth']
        // },
        // {
        //   title: '权益执行',
        //   target: '_self',
        //   name: 'equity',
        //   authority: ['companyAuth']
        // },
        {
          title: '账户中心',
          target: '_blank',
          name: 'accountCenter',
          authority: []
        },


      ],
    };
  }
};
</script>
<style lang='scss' scoped>
@import "~@/assets/style/var.scss";

.menu {
  // background: #f66;
  // width: 100%;
  flex: 1;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .item {
    margin-right: 5%;
    position: relative;


  }

  /* 桌面电脑（屏幕宽度大于 1024px） */
  @media screen and (min-width: 1500px) {
    //低于1350

    /* 桌面电脑布局和样式 */
    .item {
      margin-right: 33.375px;
      position: relative;
    }
  }

  .def-active {
    color: $--color-primary !important;

    .line {
      display: block;
      background: #0062ff;
    }
  }

  .home-active {
    color: #fff;

    .line {
      display: block;
      background: #fff;
    }
  }

  .line {
    width: 16px;
    height: 2px;
    border-radius: 8px;

    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
  }
}
</style>