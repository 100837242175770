<!-- 新手礼包 -->
<template>
    <div class="libao">
        <img class="image" src="~@/static/image/home/home_bg_10.png" alt="" srcset="" @click="getBundleList"
            v-if="user.newerBundleCount">
        <el-dialog :show-close="false" :visible.sync="dialogVisible" width="800px" :lock-scroll="false"
            :modal-append-to-body="false">
            <div class="libao_diaog">
                <div class="detail">
                    <div class="value">{{ amount }}</div>
                    <div class="unit">元</div>
                    <div class="icon">x</div>
                    <div class="value">{{ count }}</div>
                    <div class="unit">张</div>
                </div>
                <div class="title">{{ voucherTypeRender(bundle.voucherTemplates) }}</div>
                <div class="btn" @click="buyShow">{{ bundle.price }}元购买</div>
                <svg class="close" @click="dialogVisible = false" xmlns="http://www.w3.org/2000/svg" width="12"
                    height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M4.7082 6.00001L0.267561 1.55936C0.0368035 1.3286 -0.0533176 0.992267 0.0311455 0.677046C0.115609 0.361825 0.361824 0.115609 0.677045 0.0311455C0.992266 -0.0533177 1.3286 0.0368035 1.55936 0.267561L6 4.70821L10.4406 0.267561C10.6714 0.0368035 11.0077 -0.0533177 11.323 0.0311455C11.6382 0.115609 11.8844 0.361825 11.9689 0.677046C12.0533 0.992267 11.9632 1.3286 11.7324 1.55936L7.2918 6.00001L11.7324 10.4407C12.0892 10.7974 12.0892 11.3757 11.7324 11.7325C11.3757 12.0892 10.7974 12.0892 10.4406 11.7325L6 7.29181L1.55936 11.7325C1.20264 12.0892 0.624281 12.0892 0.267561 11.7325C-0.0891593 11.3757 -0.0891593 10.7974 0.267561 10.4407L4.7082 6.00001Z"
                        fill="#999999" />
                </svg>
            </div>
        </el-dialog>
        <buy ref="buy" :getSubmit="buy"/>
    </div>
</template>

<script>
import selectData from '@/mixins/selectData';
import userinfo from '@/mixins/userinfo';
import buy from "@/components/buy.vue"
const bundle = {
    bundleId: null,
    bundleName: '',  //礼包名称
    originalPrice: null,  //原价
    price: null,    //价格
    voucherTemplates: []   //券列表
}
export default {
    mixins: [userinfo, selectData],
    components: { buy },
    data() {
        return {
            bundle: {
                ...bundle
            },
            scrollTop: 0
        };
    },
    created() { },
    computed: {
       
        dialogVisible: {
            get() {
                return !!this.bundle.bundleId
            },
            set() {
                this.clear()
            }
        },
        item() {
            const { voucherTemplates } = this.bundle;
            if (!voucherTemplates.length) {
                return
            }
            const [item] = voucherTemplates
            return item
        },
        // 券金额
        amount() {
            if (this.item) {
                return this.item.amount
            } else {
                return 0
            }
        },
        //券数量
        count() {
            if (this.item) {
                return this.item.count
            } else {
                return 0
            }
        }
    },
    methods: {
        buyShow(){
            this.$refs.buy.show({
                name: '新用户特惠合同包',
                price: this.bundle.price,
                balance: this.balance,
            })
        },
        voucherTypeRender() {
            if (!this.item) {
                return
            }
            const { type } = this.item
            return this.voucherTypeData.find(res => res.value == type).label
        },
        clear() {
            this.bundle = { ...bundle }
        },
        async getBundleList() {
            const { status, info, data: [data] } = await this.$api.getBundleList({ type: 1 })
            if (status == 200) {
                this.bundle = data
                return
            }
            // this.bundle.bundleId = 1
            this.$message({
                type: 'error',
                message: info
            })
        },
        async postBundleBuy() {
            const { status, info } = await this.$api.postBundleBuy({ bundleId: this.bundle.bundleId })
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.getAccount()
                this.$refs.buy.close()
                this.clear()
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        },
        async buy() {
            await this.$store.dispatch("user/buy", this.bundle.price);
            this.postBundleBuy()
        }
    },
};
</script>

<style scoped lang='scss'>
.libao {
    margin-top: 16px;


    .image {
        width: 100%;
        cursor: pointer;

    }

    /deep/ .el-dialog {
        background-color: rgba($color: #000000, $alpha: 0);
        box-shadow: none;
        margin-top: 0px !important;

        &__header {
            padding: 0;
        }

        &__body {
            padding: 0;
            background-color: rgba($color: #000000, $alpha: 0);
        }
    }

    &_diaog {
        background-image: url(~@/static/image/home/home_bg_11.png);
        width: 800px;
        height: 800px;
        // background-size: 100% auto;
        background-size: 800px auto;
        // background: #f66;
        background-repeat: no-repeat;
        overflow: hidden;
        position: relative;

        .detail {
            display: flex;
            justify-content: center;
            margin-top: 330px;
            font-size: 70px;
            align-items: flex-end;
            padding-left: 20px;

            .value {
                background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: "DIN Alternate";
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

            }

            .unit {
                background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: "DIN Alternate";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.22px;
                padding-bottom: 15px;
                margin-left: 6px;

            }

            .icon {
                opacity: 0.5;
                background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: "DIN Alternate";
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-bottom: 10px;
                margin: 0 10px;
            }
        }

        .title {
            color: #FFF;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 140px;
        }

        .btn {
            width: 180px;
            height: 40px;
            flex-shrink: 0;
            color: #FEF4D6;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;
            margin: 0 auto;
            margin-top: 82px;
            cursor: pointer;
        }

        .close {
            position: absolute;
            right: 130px;
            top: 130px;
            cursor: pointer;
        }


    }
}
</style>