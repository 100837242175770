/*
* @Author: 曹俊杰
* @Date: 2024-09-22 13:51:35
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-12-31 00:01:09
*/ 二级菜单
<template>
    <div class="menu">
        <router-link class="item1 item" :active-class="'active'" v-for="item in list" tag='div' v-show="authFn(item)"
            :key="item.name" :to='`${matched}${item.name}`' replace>
            <i class="iconfont" :class="item.icon"></i>
            <span>{{ item.title }}</span>
        </router-link>
    </div>
</template>

<script>
import menuAuthority from "./menuAuthority.js"
export default {
    mixins: [menuAuthority],
    props: {
        list: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            cengji: 0
        }
    },
    computed: {
        matched() {
            console.log(this.cengji)
            console.log(this.$route)
            if (this.cengji > 0) {
                return this.$route.matched[this.cengji - 1].path + '/'
            } else {
                return ''
            }
        }
    },
    mounted() {
        this.cengji = this.$route.matched.length - 1
    }
}
</script>

<style lang="scss" scoped>
.menu {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;

    .item {
        width: 168px;
        height: 40px;
        top: 48px;
        border-radius: 4px 0px 0px 0px;
        display: flex;
        padding: 0 20px;
        margin-bottom: 8px;
        align-items: center;
        cursor: pointer;

        i {
            margin-right: 8px;
        }
    }

    .item2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item3 {
        padding: 0 30px;
    }

    .active {
        background: #fff;
        border-radius: 4px;
        color: #0062FF;
    }

    .fold {
        //折叠
        height: 0px;
        overflow: hidden;
    }
}
</style>