/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-10 16:25:47
 * @Module: 个人实名认证
 */
 <template>
  <div class="auth" style="padding:0 200px;">
    <Header >
      <!-- <img src="@/static/logo1.png" @click="$router.push({name:'home'})" alt="" style="width:100px;height:38px;"> -->
    </Header>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
 <script>
import Header from "@/components/layouts/Header.vue"
export default {
  components: {
    Header
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.auth {
  background: rgb(246, 247, 248);
  min-height: 100vh;
  .content {
    width: 1200px;
    margin: 0 auto;
    height: calc(100vh - 70px - 16px);
    margin-top: 16px;
    overflow-y: auto;
  }
}
</style>