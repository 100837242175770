/*
* @Author: 曹俊杰
* @Date: 2024-11-11 22:38:31
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-14 09:27:03
*/推广收益提现
<template>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="450px" @closed="closed"
        :close-on-click-modal="false">
        <div class="rewardWithdrawdialog" :style="type === -1?'height:280px':'height:100px;'">
            <el-form size="mini" label-position="top" :model="form" ref="form">
                <el-form-item label="收款支付宝账号" prop="alipayAccount" v-if="type === -1" :rules="[
                    { required: true, message: '请输入收款支付宝账号', trigger: 'blur' },
                ]">
                    <el-input placeholder="请输入收款支付宝账号" v-model.number="form.alipayAccount" ></el-input>
                </el-form-item>

                <el-form-item label="收款支付宝姓名" prop="alipayName" v-if="type === -1" :rules="[
                    { required: true, message: '请输入收款支付宝姓名', trigger: 'blur' },
                ]">
                    <el-input placeholder="请输入收款支付宝姓名" v-model.number="form.alipayName" disabled></el-input>
                </el-form-item>
                <el-form-item label="提现金额" prop="amount" :rules="[
                    { required: true, message: '请输入提现金额', trigger: 'blur' },
                ]">
                    <div class="amount-box">
                        <el-input-number :controls="false" :precision="1" :max="user.reward" type="number" :step="0.1"
                            v-model.number="form.amount" placeholder="请输入金额" style="width: 110px;"></el-input-number>
                        <div class="amount-box-1">元</div>
                        <div class="amount-box-2" @click="all">全部提现</div>
                    </div>
                </el-form-item>
            </el-form>
            <div class="rewardWithdrawdialog-text">可提现金额（¥{{ user.reward | priceFormat }}）</div>
        </div>
        <span slot="footer" class="dialog-footer">

            <el-button @click="close" size="mini">取 消</el-button>
            <el-button type="primary" @click="submit" size="mini">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import userinfo from '@/mixins/userinfo.js'
export default {
    mixins: [userinfo],
    components: {
    },
    computed: {
        title() {
            if (this.type == -1) {
                return "提现"
            } else if (this.type == -2) {
                return "转入余额"
            } else {
                return ""
            }
        }
    },
    data() {

        return {
            dialogVisible: false,
            form: {
                amount: null,   //金额，最小为0.1
                alipayAccount: null,   //支付宝帐号(type为1时必填)
                alipayName: null    //支付宝姓名(type为1时必填)
            },
            type: 2       //1 提现   2 转入余额
        };
    },
    mounted() { },
    methods: {
        change(res) {
            console.log(res)
        },
        show(type) {
            this.type = type;
            if(type=== -1){
                this.form.alipayName = this.user.name
            }
            this.dialogVisible = true;
        },
        all() {
            this.form.amount = this.user.reward
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.postBrokerageRecord()
                } else {
                    return false
                }
            })
        },
        close() {
            this.dialogVisible = false;

        },
        closed() {
            this.$refs.form.resetFields();
            this.close()
        },
        async postBrokerageRecord() {
            const {
                alipayAccount,
                alipayName,
                amount
            } = this.form
            if (!amount) {
                this.$message({
                    type: 'error',
                    message: "提现金额最小为0.1"
                })
                return
            }
            let params = { amount, type: this.type }
            if (this.type == -1) {
                params.alipayAccount = alipayAccount;
                params.alipayName = alipayName;
            }
            const { status, info } = await this.$api.postBrokerageRecord(params);
            if (status == 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.dialogVisible = false
                this.$emit('success')
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        }

    },
};
</script>
<style lang='scss' scoped>
/deep/.el-dialog {
    &__body {
        padding: 0 20px;
    }

    &__header {
        // padding: 0;
    }
}

.rewardWithdrawdialog {
    position: relative;
    // padding-top: 30px;
    // &-text {
    //     position: absolute;
    //     left: 0px;
    //     bottom: -30px;
    // }

    .amount-box {
        display: flex;

        &-1 {
            margin-left: 10px;
        }

        &-2 {
            margin-left: 20px;
            color: #0062FF;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
        }
    }
}
</style>