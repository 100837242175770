<template>

    <div class="bg">
        <div class="top">
            <div class="title">全部合同</div>
        </div>
        <div class="row">
            <div class="lable">合同状态：</div>
            <div class="main">
                <zhuangtai @choose="choose({ value: '' })" style="margin-right: 10px;" :active="searchForm.state === ''"
                    text="全部" :value="''" />
                <zhuangtai @choose="choose(item)" style="margin-right: 10px;" v-for="item in contractStateList"
                    :active="item.value === searchForm.state" :key="item.value" :text="item.label"
                    :value="item.value" />
            </div>
        </div>
        <div class="b_row">
            <div class="col" style="flex: 1;">
                <div class="lable">合同名称：</div>
                <div class="input">
                    <el-input placeholder="可以输入合同名称、编号、签约方信息" size="mini" v-model="searchForm.keyword"
                        clearable></el-input>
                </div>
            </div>
            <div class="col">
                <div class="lable">合同锁状态：</div>
                <div class="input">
                    <el-select v-model="searchForm.insuranceState" size="mini" style="width:100%;"
                        placeholder="请选择合同锁状态" @keyup.enter.native="onSubmit" clearable>
                        <el-option v-for="item in insuranceState" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col">
                <div class="lable">发起时间：</div>
                <div class="input">
                    <el-date-picker size="mini" v-model="searchForm.initiated" style="width: 100%;" type="daterange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp">
                    </el-date-picker>
                </div>
            </div>
           
            <div class="col">
                <div class="lable">完成时间：</div>
                <div class="input">
                    <el-date-picker size="mini" v-model="searchForm.finishedAt" style="width: 100%;" type="daterange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp">
                    </el-date-picker>
                </div>
            </div>
            <div class="col" v-if="user.creator">
                <div class="lable">操作人：</div>
                <div class="input">
                    <el-select v-model="searchForm.empUuid" placeholder="选择操作人" size="mini" clearable>
                        <el-option v-for="item in accountColleagueList" :key="item.uuid" :label="item.name"
                            :value="item.uuid">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col last">
                <el-button type="primary" style="width: 78px;" icon="el-icon-search" size="mini"
                    @click="onSubmit">搜索</el-button>
                <el-button size="mini" style="width: 78px;">重置</el-button>
            </div>
        </div>
    </div>

</template>

<script>
import zhuangtai from "@/views/ElectronicDocuments/components/zhuangtai.vue";
import selectData from "@/mixins/selectData.js"

import userinfo from "@/mixins/userinfo.js"
export default {
    mixins: [userinfo,selectData],
    props: {
        searchForm: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        zhuangtai,
    },
    data() {
        return {
            accountColleagueList: [],

        }
    },
    mounted() {
        this.getAccountColleagueList()
    },
    methods: {
        choose(res) {
            this.$emit('update:searchForm', {
                ...this.searchForm,
                state: res.value
            })
        },
        onSubmit() {
            this.$emit('submit')
        },
        async getAccountColleagueList() {  // 获取消费账号
            const { status, data } = await this.$api.getAccountColleagueList()
            if (status === 200) {
                this.accountColleagueList = data
            }
        },
    }
}

</script>

<style lang="scss" scoped>
.bg {
    background: #fff;
    height: 220px;
    border-radius: 6px;
    padding: 0 20px;

    .title {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;

    }

    .row {
        height: 50px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .main {
            display: flex;
        }

    }

    .b_row {
        height: 100px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .col {
            height: 50px;
            display: flex;
            align-items: center;
            width: calc((100% - (10px * 2) - 80px)/3);
            // background: #66f;
        }
    }

    .lable {
        color: #666;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100px;
        text-align: end;
    }

    .input {
        width: calc(100% - 100px)
    }

    .last {
        margin-left: 20px;
    }
}
</style>