/*
* @Author: 曹俊杰
* @Date: 2024-10-24 12:24:17
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-11-23 11:25:43
*/咨询vip

<template>
    <div style="overflow:hidden">
        <div class="banner" :class="{none:serviceState.key === 0}">
            <div class="banner-l">
                <img src="@/static/image/home/home_bg_8.png" alt="" v-if="serviceState.key === 0">
                <img src="@/static/image/home/home_bg_6.png" alt="" v-else>
                <div class="banner-l-r">
                    <div class="banner-l-r-t">
                        <div class="text">咨询VIP</div>
                        <div class="icon" v-show="VoucherNum">
                            <div class="icon-l">待使用</div>
                            <div class="icon-r pointer" @click="buy">
                                <span> {{ VoucherNum }}张代金券</span>
                                <span style="margin-left: 2px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7"
                                        fill="none">
                                        <path
                                            d="M0.571429 -2.49779e-08L-8.00301e-09 0.583333L2.85714 3.5L-2.54983e-08 6.41667L0.571429 7L4 3.5L0.571429 -2.49779e-08Z"
                                            fill="white" />
                                    </svg>
                                </span>

                            </div>
                        </div>
                    </div>
                    <div class="banner-l-r-b">
                        <span>{{ serviceState.value }}</span>
                        <span v-if="serviceState.key == 1">
                            <span style="margin: 0 4px;">｜</span>
                            <span>{{ user.serviceUntil| timeFormat('yyyy-mm-dd') }} 到期</span>
                        </span>

                    </div>
                </div>
            </div>
            <div class="banner-r" @click="buy">
                {{ serviceState.key == 0 ? '立即开通' : '续费' }}

            </div>
        </div>
        <div class="box-content">
            <div class="box-content-title">购买记录</div>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column label="支付日期">
                    <template slot-scope="scope">
                        <span>{{ scope.row.createdAt | timeFormat('yyyy-mm-dd') }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="购买套餐">
                    <template slot-scope="scope">
                        <span>
                            <span>{{ scope.row.content }}</span>
                            <!-- <span>（{{ scope.row.duration }}个月）</span> -->
                            
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="paymentAmount" label="实际支付" width="180">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <span style="color:#0062FF">¥{{ scope.row.paymentAmount | priceFormat(2) }}</span>
                            （
                            <div v-if="scope.row.paymentMethod == '2'">
                                <i class="el-icon-ali-xingzhuang" style="color:#0062FF;font-size: 12px;"></i>
                                <span>支付宝支付</span>
                            </div>
                            <div v-else-if="scope.row.paymentMethod == '1'">
                                <span style="margin: 0 2px;">
                                    <i class="el-icon-ali-qiyeyue" style="color:#0062FF;font-size: 12px;"></i>
                                </span>

                                <span>余额支付</span>
                            </div>
                            ）

                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="代金券">
                    <template slot-scope="scope">
                        <span>{{ scope.row.voucherAmount || '--' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="开始时间">
                    <template slot-scope="scope">
                        <span>{{ scope.row.startAt | timeFormat('yyyy-mm-dd') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="到期时间">
                    <template slot-scope="scope">
                        <span>{{ scope.row.endAt | timeFormat('yyyy-mm-dd') }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <buyAdvisory ref="buyAdvisory" @success="buySuccess"/>
            <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
        </div>
    </div>
</template>
<script>
import hdqPagination from "@/components/hdqPagination.vue"
import userinfo from "@/mixins/userinfo.js"
import buyAdvisory from "./components/buyAdvisory.vue"
export default {
    mixins: [userinfo],
    components: {
        hdqPagination,
        buyAdvisory
    },
    data() {
        return {
            tableData: [],
            //  现有代金券数量
            VoucherNum: 0
        }
    },
    mounted() {
        this.getAdvisoryList();
        this.getVoucherList();
        this.pageShow()
    },
    filters: {
        payment(res) {
            if (res == 'ALIPAY') {
                return '支付宝'
            } else if (res == 'GIVEAWAY') {
                return '系统赠送'
            } else {
                return res
            }
        }
    },
    methods: {
        // 页面加载
        pageShow() {
            const { query } = this.$route;
            if(query){
                if(query.buy==1){
                    this.buy()
                }
            }
        },
        buySuccess(){
            this.getAdvisoryList();
            this.getVoucherList();
            this.getAccount()
        },
        buy() {
            this.$refs.buyAdvisory.show()
        },
        search() {
            this.$refs.pagination.createPagination({ current: 1 })
            this.getAdvisoryList()
        },
        paginationChange() {
            this.getAdvisoryList()
        },
        async getAdvisoryList() {
            const { current, size } = this.$refs.pagination
            const { data, status } = await this.$api.getAdvisoryList({ current, size })
            if (status == 200) {
                this.tableData = data.records
                this.$refs.pagination.createPagination(data)
            }
        },
        async getVoucherList(params) {
            const { data, status } = await this.$api.getVoucherList({ type: 1, state: 0 });
            if (status == 200) {
                this.VoucherNum = data.records.length
            }
        }
    },
};
</script>
<style lang='scss' scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.box-content {
    padding: 0 20px 20px;
    background: #fff;

    &-title {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
    }
}

.banner {
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: linear-gradient(90deg, #FEEACD 0%, #FBD19C 100%);
    background-image: url(~@/static/image/home/home_bg_91.png);
    height: 90px;
    margin-bottom: 16px;
    background-size:auto 100% ;

    &-l {
        display: flex;
        align-items: center;

        img {
            width: 109px;

        }

        &-r {
            &-t {
                display: flex;
                align-items: center;

                .text {
                    font-family: "Source Han Sans CN";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(90deg, #974D1C 0%, #311909 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .icon {
                    display: flex;
                    margin-left: 6px;

                    &-l {
                        color: #FFF;
                        font-family: "Source Han Sans CN";
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        display: inline-flex;
                        height: 18px;
                        padding: 2px 4px;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        border-radius: 4px 0px 0px 4px;
                        background: linear-gradient(111deg, #F07B41 2.95%, #E8331A 100%);
                        text-align: center;
                    }

                    &-r {
                        display: flex;
                        height: 18px;
                        padding: 2px 4px;
                        justify-content: center;
                        align-items: center;
                        gap: 2px;
                        flex-shrink: 0;
                        border-radius: 0px 4px 4px 0px;
                        background: linear-gradient(90deg, #974D1C 0%, #311909 100%);
                        color: #FFF;
                        font-family: "Source Han Sans CN";
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: center;

                    }
                }
            }

            &-b {
                color: #974D1C;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 4px;
            }
        }
    }

    &-r {
        width: 116px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 100px;
        background: linear-gradient(90deg, #974D1C 0%, #311909 100%);
        color: #FFF;
        font-family: "Source Han Sans CN";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
    }
}
.none{
    background-image: url(~@/static/image/home/home_bg_9.png);
}
</style>
