<template>
    <div class="box">
        <el-carousel height="168.9px" v-if="bannerList.length > 1" :interval="30000">
            <el-carousel-item v-for="item in bannerList" :key="item.src" >
                <el-image :src="item.src" alt="" srcset="" @click="toList(item)" />
            </el-carousel-item>
        </el-carousel>
        <el-image v-else :src="bannersrc" alt="" srcset="" @click="toList(null)" />
    </div>
</template>

<script>
import userinfo from "@/mixins/userinfo.js"
import banner from '@/static/image/home/banner_1.png'

export default {
    mixins: [userinfo],
    components: {
    },
    data() {
        return {
            banner,
            bannerList: [],
            bannersrc: ''
        }
    },
    computed: {
        // bannerList() {
        //     return [{
        //         src: this.banner
        //     }]
        // }
    },
    created() {
        this.getBannerList()
    },
    methods: {
        async getBannerList() {
            const { status, data, info } = await this.$api.getBannerList({ type: 1 })
            if (status !== 200) {
                this.$message({
                    type: 'error',
                    message: info
                })
                return
            }
            if (data.length > 1) {
                this.bannerList = data.map(res => ({ src: this.$oss + res.cover,url:res.url }));
                return
            } else if (data.length === 1) {
                this.bannersrc = this.$oss + data[0].cover
            } else {
                this.bannersrc = this.banner
            }
            // 如果后台没有设置，就设置一个默认图片

        },
        async toList(item) {
            console.log(123123,item)
            if(item && item.url){
                window.open(item.url, '_blank');
                return
            }
            if (this.companyAuth) {
                window.open('/course/1', '_blank');
                return
            }
            await this.$confirm('观看课程，需要完成企业实名', '提示', {
                confirmButtonText: '去完成',
                cancelButtonText: '取消',
                type: 'warning'
            })
            this.$store.dispatch('user/toAccountCompanyAuth')
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #fff;
    // padding: 0 20px;
    border-radius: 4px;
    overflow: hidden;
    line-height: 0px;
}
</style>