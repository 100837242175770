/*
* @Author: 曹俊杰
* @Date: 2022-02-17 15:04:21
* @Module: 默认layouts
*/
<template>
  <div class="page" :class="{ homeBg: $route.name === 'home' }">
    <el-container class="page_content">
      <el-header height="64px" id="page_header">
        <Header />
      </el-header>
      <div id="projectContainer">

        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" class="minHeight"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" class="minHeight"></router-view>
        <el-footer v-show="!hiddenFooter">
          <footer>
            ©2014-2022 中能魔力（河南）网络科技有限公司版权所有 豫ICP备14100822号-1 豫公网安备 44030602004264号文本
          </footer>
        </el-footer>
      </div>
    </el-container>
    <service />
  </div>
</template>
<script>
import service from "@/components/service.vue";
import Header from "@/components/layouts/Header.vue"
import { mapState } from "vuex";
export default {
  components: { Header, service },
  data() {
    return {
      hiddenFooter: false,

      menuList: [
        {
          title: '首页',
          name: '/home',
          icon: 'el-icon-ali-shouye1',
          authority: 'all'
        },
        {
          title: '电子合同',
          name: '/electronicDocuments',
          icon: 'el-icon-ali-dianzihetong',
          authority: 'all'
        },
        {
          title: '精准取证',
          name: '/accurateEvidence',
          icon: 'el-icon-ali-jingzhunquzheng',
          authority: 'all'
        },
        {
          title: '归档管理',
          name: '/archiveManagement',
          icon: 'el-icon-ali-guidangguanli',
          authority: 'all'
        },
        {
          title: '三要素核查',
          name: '/inspect',
          icon: 'el-icon-ali-sanyaosuhecha',
          authority: 'all'
        },
        {
          title: '查询小号',
          name: '/search',
          icon: 'el-icon-search',
          authority: 'all'
        },
        {
          title: '用户中心',
          name: '/accountManagement',
          icon: 'el-icon-ali-yonghuzhongxin',
          authority: 'all'
        },
        {
          title: '费用中心',
          name: '/expenseCenter',
          icon: 'el-icon-ali-feiyongzhongxin',
          authority: 'all'
        },
        {
          title: '员工管理',
          name: '/accountColleague',
          icon: 'el-icon-user',
          authority: 'all'
        },
        {
          title: '印章管理',
          name: '/sealManagement',
          icon: 'el-icon-ali-xingzhuang',
          authority: 'all'
        },
        {
          title: '法务学院',
          name: '/instituteJustice',
          icon: 'el-icon-ali-fawuxueyuan',
          authority: 'all'
        },
        {
          title: '帮助中心',
          name: '/help',
          icon: 'el-icon-ali-wenhao-bangzhu',
          authority: 'all'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      userLogList: state => state.userLog.userLogList
    })
  },
  created() {

  } ,
  mounted() {
  },
  methods: {
  },
  watch: {
    "$route.meta": {
      handler({ hiddenFooter = false }) {
        this.hiddenFooter = hiddenFooter
      },
      immediate: true
    }
  }
};
</script>
<style lang='scss' scoped>
@import "~@/assets/style/var.scss";

.page_content {
  width: 100%;
}

.homeBg {
  background-image: url(~@/static/image/home/home_bg.png);
  background-size: 100vw 304px;
  background-position: top center;
  background-repeat: repeat-x;

}

.minHeight {
  min-height: calc(100vh - 64px - 60px);
}

footer {
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: center;
}

#page_header {
  padding: 30px;
  min-width: 1200px;
  // zoom: 0.71;
  box-sizing: border-box;
}

// .page_header,#projectContainer {
//   width: 1300px;
//   margin: 0 auto;
// }
#projectContainer {
  // zoom: 0.71;
  width: 1200px;
  margin: 0 auto;
}

// /* 桌面电脑（屏幕宽度大于 1440px） */
// @media screen and (min-width: 1440px) {

//   /* 桌面电脑布局和样式 */
//   #projectContainer {
//     zoom: 1;
//     width: 100%;

//   }

//   .page {
//     padding: 0 100px;
//   }
// }</style>