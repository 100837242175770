/*
* @Author: 曹俊杰
* @Date: 2024-09-23 11:33:10
* @Last Modified by: 曹俊杰
* @Last Modified time: 2024-10-12 15:48:38
*/结果
<template>
    <div class="box">
        <div class="title">
            结束案件
        </div>
        <equity-search-form :searchForm.sync="listParams" @submit="getList" />
        <div class="main">
            <equityList :list="list" @todetail="todetail" />
            <hdq-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getList" />
            <detail />
        </div>
    </div>
</template>

<script>
import equitySearchForm from "./components/equitySearchForm.vue"
import hdqPagination from "@/components/hdqPagination.vue"
import equityList from "./components/equityList.vue"
import list from "./mixins/list"
export default {
    mixins: [list],
    components: {
        equitySearchForm,
        hdqPagination,
        equityList
    },
    data() {
        return {
            listParams: {
                state: 2
            }
        }
    }


}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/listStyle.scss";
</style>