<template>
    <el-dialog destroy-on-close :visible.sync="dialogVisible" @close="close" custom-class="content">
        <insuranceSelect :insurance="insurance" @change="change" />
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="mini">取 消</el-button>
            <el-button type="primary" @click="submit" size="mini">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import insuranceSelect from "./insuranceSelect"
export default {
    components: {
        insuranceSelect
    },
    data() {
        return {
            insurance: {
                insuranceId: null,
                title: '',
                insuranceVersion: null
            },
            contractUuid: null,
            dialogVisible: false
        }
    },
    methods: {
        close() {
            this.dialogVisible = false;
            this.clear()
        },
        show(res) {

            const { insuranceId, insuranceVersion, title, uuid:contractUuid } = res
            this.insurance = { insuranceId, insuranceVersion, title };
            this.contractUuid = contractUuid;
            this.dialogVisible = true
        },
        clear() {
            this.insurance = {
                insuranceId: null,
                title: '',
                insuranceVersion: null
            }
            this.contractUuid = null
        },
        change(res) {
            const { insuranceId, insuranceVersion, title } = res
            this.insurance = { insuranceId, insuranceVersion, title };
        },
        submit() {
            this.putContractBuy()
        },
        async putContractBuy() {
            const params = {
                insuranceId: this.insurance.insuranceId,
                insuranceVersion: this.insurance.insuranceVersion,
                contractUuid: this.contractUuid
            }
            const { status, info } = await this.$api.putContractBuy(params);
            if (status == 200) {
                this.$message({
                    message: info,
                    type: 'success'
                })
                this.$emit('success')
                this.dialogVisible = false;
            } else {
                this.$message({
                    message: info,
                    type: "error"
                })
            }

        }
    }

}
</script>

<style></style>