/*
* @Author: 曹俊杰
* @Date: 2024-12-13 11:46:23
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-01 16:52:00
*/签署信息
<template>
    <div class="signers">
        <div v-for="(item, index) in list" :key="item.uuid" class="item">
            <div>
                <span>{{ item.operatorName }}</span>
                <span v-if="item.signerType == 'ORG'">（操作人）</span>
            </div>
            <div style="margin: 0 5px;">|</div>
            <div v-if="item.state === 'IN_PROGRESS'" style="color: #0062FF" class="pointer"
                @click="getSignerUrl(item, index)">
                签署</div>
            <div v-if="item.state === 'FINISHED'" style="color: #00A646;">已签署</div>
            <div v-if="item.state === 'PENDING'" style="color: #FF8412;">待审批</div>
            <div v-if="item.state === 'REFUSED'" style="color: #FF0D0D;">拒签</div>
        </div>
        <qrDialog ref="qrDialog" @close="close">
            <template>
                <span>
                    <span v-if="name">可通知【{{ name }}】</span>
                    <span>使用</span>
                    <span style="color:#0062FF;">「微信账号」</span>
                    <span>或</span>
                    <span style="color:#0062FF;">「支付宝账号」</span>
                    <span>扫码完成签署</span>
                </span>
            </template>
        </qrDialog>
    </div>
</template>

<script>
import qrDialog from "@/components/qrDialog.vue"
export default {
    props: {
        list: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
        qrDialog
    },
    data() {
        return {
            name: ''
        }
    },
    computed: {
    },
    methods: {
        close() {
            this.name = ''
        },
        async getSignerUrl({ uuid, operatorName }, index) {
            const { status, data, info } = await this.$api.getSignerUrl({ uuid })
            if (status === 200) {
                if (index) {
                    this.name = operatorName
                }
                this.$refs.qrDialog.show(data.signUrl)
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
            console.log(data)

        }
    }
}
</script>

<style scoped lang="scss">
.signers {
    min-width: 150px;
    .item {
        display: flex;
        font-size: 12px;
    }
}
</style>