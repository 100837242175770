/*
* @Author: 曹俊杰
* @Date: 2024-09-29 15:23:19
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-08 22:53:19
*/合同宝Icon

<template>
    <div class="hetongbao">
        <div v-if="value === 0" class="type0">--</div>
        <!-- -------------------------------------- -->
        <div v-else-if="value === 1" class="type1 item">
            <span style="margin-left: 0;">不可投保</span>
        </div>
        <!-- -------------------------------------- -->
        <div v-else-if="value === 2" class="type2 item">
            <span>保障中</span>
        </div>
        <!-- -------------------------------------- -->
        <div v-else-if="value === 3" class="type3 item">
            <span>已到期</span>
        </div>
        <!-- -------------------------------------- -->
        <div v-else-if="value === 4" class="type4 item">
            <span>启动维权</span>
        </div>
        <!-- -------------------------------------- -->
        <div v-else-if="value === 5" class="type5 item">
            <span>部分退款</span>
        </div>
        <!-- -------------------------------------- -->
        <div v-else-if="value === 6" class="type5 item">
            <span>全额退款</span>
        </div>
        <div v-else class="none">--</div>
    </div>
</template>
<script>
import selectData from "@/mixins/selectData.js"
export default {
    mixins: [selectData],
    props: {
        value: {
            type: Number,
            default: 0
        },
        item: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        buyClick() {
            this.$emit('buyClick')
        }
    }

}
</script>

<style lang="scss" scoped>
.hetongbao {
    font-family: "PingFangSC-Regular";
    .item {
        display: flex;
        align-items: center;

        span {
            // margin: 0 4px;
        }
    }

    .type0 {
        // color: #0062FF;
        // font-family: "Source Han Sans CN";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // text-decoration-line: underline;
    }

    .type1 {
        color: #666;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .type2 {
        background: linear-gradient(180deg, #00A646 0%, #00401B 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
    }

    .type3 {
        color: #666;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
    }

    .type4 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
        background: linear-gradient(180deg, #FF0D0D 0%, #990808 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .type5 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
        background: linear-gradient(113deg, #FF8412 4.92%, #994F0B 94.37%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
