/*
* @Author: 曹俊杰
* @Date: 2024-09-30 10:33:35
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-10 11:29:59
*/三要素购买菜单

<template>
  <div class="dialog">
    <el-dialog :visible.sync="dialogVisible" width="20%" title="订单支付" @close="close" append-to-body>
      <div class="dialog__title" style="background-color: #f3f6f8">
        订单信息
      </div>
      <div class="dialog__item">
        <div class="dialog__item__l">{{ parme.name }}：</div>
        <div class="dialog__item__r">¥ {{ parme.price | priceFormat }}</div>
      </div>
      <div class="dialog__item">
        <div class="dialog__item__l">总金额：</div>
        <div class="dialog__item__r blue">
          ¥ {{ parme.price | priceFormat }}
        </div>
      </div>
      <div class="dialog__title" style="margin-top: 20px; background-color: #f3f6f8">
        支付方式
      </div>
      <paymentMethod :value="value" @input="updateValue" :isbalance="isbalance" />
      <div v-show="value==2" style="color:#FF0D0D;font-size: 12px;margin-top: 10px;">* 扫码支付成功后，页面自动跳回，请勿手动操作！</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getSubmit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paymentMethod from "@/components/paymentMethod/paymentMethod";
import userinfo from "@/mixins/userinfo.js"
export default {
  mixins: [userinfo],
  props: {
    value: {
      default: null
    },
    getSubmit: {
      type: Function,
      default: () => ({}),
    },
  },
  components: {
    paymentMethod
  },
  data() {
    return {
      dialogVisible: false,
      parme: {
        name: "",
        price: null,
        balance: null,
        paymentMethod: 1
      },
    };
  },
  computed: {
    isbalance() {  //  余额是否够
      return (this.balance - 20) >= 0
    },
  },
  methods: {
    show(parme) {
      this.parme = parme;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    updateValue(newValue) {
      this.$emit('input', newValue); // 将更新通知父组件
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .blue {
    color: #0062ff;
  }

  .red {
    color: #ff0d0d;
  }

  /deep/.el-dialog {
    background: #f3f6f8;
  }

  &__title {
    color: #262626;
    font-family: "Source Han Sans CN";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    background: #fff;
    padding: 0 16px;
  }

  &__item {
    color: #262626;
    font-family: "Source Han Sans CN";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__l {}

    &__r {}
  }
}
</style>
