/*
* @Author: 曹俊杰
* @Date: 2022-05-23 14:43:29
* @Module: 员工列表
*/
<template>
  <div class="AccountColleague">
    <el-card shadow="never">
      <div slot="header" class="title">
        <span>{{ user.company.name }}</span>
        <el-divider direction="vertical"></el-divider>
        <span>共
          <span style="color:#0062FF;">{{ list.length }}</span>
          个成员</span>
        <el-button style="float: right; margin-right:20px;" size="small" type="primary" @click="visible = true"
          :disabled="!hasAuth">添加员工</el-button>
      </div>
      <authorized :hasAuth.sync="hasAuth" />
      <el-table :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column label="账号信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <i class="el-icon-user" style="color:#0062FF;"></i>
            {{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="手机号" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="身份" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>
              <el-tag type="warning" size="mini" v-if="scope.row.creator">法人</el-tag>
              <el-tag v-else size="mini">员工</el-tag>
              <span v-if="user.uuid === scope.row.uuid">
                <el-divider direction="vertical"></el-divider> <span style="color:#0062FF;">当前帐号</span>
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="加入企业时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>
              {{ scope.row.joinAt | timeFormat('yy-mm-dd hh:MM:ss') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">{{ EnumRender(scope.row.state,employeeType)  }}</template>
        </el-table-column>
        <el-table-column label="操作" :resizable="false">
          <template slot-scope="scope">
            <!-- <el-link :underline="false" :disabled="!hasAuth" slot="reference" type="info"
              @click="() => operate('delete', scope.row)" v-if="user.uuid !== scope.row.uuid">删除</el-link> -->
            <el-link :underline="false" :disabled="!hasAuth" slot="reference" 
              @click="() => operate('edit', scope.row)" v-if="user.uuid !== scope.row.uuid">编辑</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <personnelSelection ref="personnelSelection" :multiple="false" :validate="[]" @submit="submit"
      :visible.sync="visible">
      <el-link type="warning" icon="el-icon-info" style="margin-top: 10;"
        :underline="false">请先让员工注册本系统，并完成实名认证后，才可被添加</el-link>
    </personnelSelection>
    <deleteDialog ref="deleteDialog" @success="getAccountColleagueList" :list="list" />
    <editDialog ref="editDialog" @success="getAccountColleagueList" />
  </div>
</template>
<script>
import authorized from "@/components/authorized.vue"
import personnelSelection from "@/components/personnelSelection.vue"
import deleteDialog from "./components/deleteDialog.vue"
import editDialog from "./components/editDialog.vue"
import selectData from '@/mixins/selectData.js'
import { mapState } from "vuex";
export default {
  mixins:[selectData],
  components: {
    authorized,
    personnelSelection,
    deleteDialog,
    editDialog
  },
  data() {
    return {
      list: [],
      hasAuth: true,
      visible: false,

    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),

  },
  mounted() {
    this.getAccountColleagueList()
  },
  methods: {
    async getAccountColleagueList() {
      const { data, status } = await this.$api.getAccountColleagueList()
      if (status === 200)
        this.list = data;
    },
    async submit(arr, verifyCode) {
      const { status, info } = await this.$api.putAccount({ empUuid: arr[0].empUuid, verifyCode });
      if (status == 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.visible = false;
        this.getAccountColleagueList()
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },
    operate(type, data) {
      if (type === 'delete') {
        // this.empUuid = data.uuid
        this.$refs.deleteDialog.show(data.uuid)
        return
      }
      if (type === 'edit') {
        // this.empUuid = data.uuid
        this.$refs.editDialog.show(data)
        return
      }
    },

  },
};
</script>
<style lang='scss' scoped>
.AccountColleague {
  .title {
    line-height: 50px;
    background: #ffffff;
    margin-top: 10px;
    padding-left: 24px;

    font-size: 16px;
    font-weight: 500;
    color: #262626;

    span:last-child {
      color: #0062ff;
      font-size: 16px;
    }
  }
}
</style>