/*
* @Author: 曹俊杰
* @Date: 2024-09-22 14:56:53
* @Last Modified by: 曹俊杰
* @Last Modified time: 2024-11-23 11:19:57
*/代金券
<template>
    <div class="box">
        <ul class="menu">
            <li v-for="item in menuList" class="item pointer" :class="{ 'def-active': active === item.key }"
                :key="item.key" @click="menuClick(item)">
                {{ item.title }}
                <div class="line"></div>
            </li>
        </ul>

        <div class="voucherTitle" v-show="fawuList.length">法律咨询代金劵</div>
        <div class="content">
            <voucherItem v-for="item in fawuList" :key="item.voucherId" :item="item" />
        </div>
        <div class="voucherTitle voucherTitle2" v-show="hetongList.length">电子合同代金劵</div>
        <div class="content">
            <voucherItem v-for="item in hetongList" :key="item.voucherId" :item="item" />
        </div>
        <empty v-show="!list.length" />
    </div>
</template>

<script>
import empty from "@/components/empty.vue";
import voucherItem from "@/views/AccountCenter/components/voucherItem";
export default {
    components: {
        empty,
        voucherItem
    },
    data() {
        return {
            menuList: [{
                title: '未使用',
                key: '0'
            },
            {
                title: '已使用',
                key: '1'
            },
            {
                title: '已过期',
                key: '2'
            }],
            active: '0',
            list: []
        }
    },
    computed: {
        fawuList() {
            return this.list.filter(res => res.type === 1) || []
        },
        hetongList() {
            return this.list.filter(res => res.type === 2) || []
        }
    },
    methods: {
        menuClick({ key }) {
            this.active = key
        },
        async getVoucherList(params) {
            const { data, status } = await this.$api.getVoucherList(params);
            console.log(data.records, status)
            if (status == 200) {
                this.list = data.records
            }
        }
    },
    watch: {
        "active": {
            handler(state) {
                this.getVoucherList({ state, size: 100 })
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #fff;
    border-radius: 6px;
    flex-direction: column;

    .menu {
        flex: 1;
        display: flex;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
        height: 60px;
        padding: 0 20px;

        .item {
            margin-right: 40px;
            position: relative;
        }

        .def-active {
            color: #0062FF;

            .line {
                display: block;
                background: #0062FF;
            }
        }



        .line {
            width: 16px;
            height: 2px;
            border-radius: 8px;
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            margin: auto;
            display: none;
        }
    }

    .content {
        padding: 10px 20px;
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        width: 1000px;


    }

    .voucherTitle {
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: linear-gradient(90deg, #BC6416 3.33%, #914F1A 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 0 20px;
    }

    .voucherTitle2 {
        background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>