<template>
  <div class="box">
    <e-header :step="2" @next="submitForm" :showbtn="showbtn" />

    <div class="content">
      <div class="item" v-show="item1">
        <div class="title">
          <div class="title_l">
            <i class="solid"></i>
            <p>强制执行排查</p>
          </div>
          <div class="title_r" v-show="!qiangzhishow">
            <el-button type="text" size="mini" @click="qiangzhireset">修改查询</el-button>
            <el-button type="primary" size="mini" @click="qiangzhiRightClick">查看</el-button>
          </div>
        </div>
        <qiangzhi @callback="qiangzhiCBK" v-show="qiangzhishow" />
      </div>

      <div class="item" style="margin-top: 16px;" v-show="item2">
        <div class="title">
          <div class="title_l">
            <i class="solid"></i>
            <p>风险排查</p>
            <span class="esc" v-show="!fxpcshow">曝光台检索、重复签约检索
              <span v-show="fxpcdata.content">、三要素报告</span>
            </span>
          </div>
          <div class="title_r">
            <div class="title_r" v-show="!fxpcshow"> <el-button type="primary" size="mini" @click="fxpcRightClick">查看</el-button></div>
          </div>

        </div>
        <InspectSearch ismode @success="fxpcchange" v-show="fxpcshow" />
      </div>
      <div class="item" style="margin-top: 16px;" v-show="item3">
        <div class="title">
          <div class="title_l">
            <i class="solid"></i>
            <p>合同锁</p>
            <span class="esc" v-show="!hetongbaoshow">{{ title}}</span>
          </div>
          <div class="title_r" v-show="!hetongbaoshow"> <el-button type="primary" size="mini" @click="hetongbaoRightClick">修改</el-button></div>
        </div>
        <hetongbao ref="hetongbao" v-show="hetongbaoshow" />
      </div>
    </div>
    <InspectDetail ref="InspectDetail" />
    <mElectronicFooter @next="submitForm" :disabledNext="disabledNext" v-show="!disabledNext" />
    <qiangzhiDialog ref="qiangzhiDialog" />
  </div>
</template>

<script>
import eHeader from "@/views/ElectronicDocuments/components/eHeader.vue"
import qiangzhi from "@/views/ContractInsurance/qiangzhi.vue"
import InspectSearch from "@/views/Inspect/InspectSearch.vue"
import hetongbao from "@/views/ContractInsurance/hetongbao.vue"
import InspectDetail from "@/views/Inspect/components/InspectDetail.vue"
import qiangzhiDialog from "@/views/ContractInsurance/components/qiangzhiDialog.vue"
import mElectronicFooter from "@/components/mElectronicFooter.vue"
import { mapState } from "vuex";
export default {
  components: {
    eHeader,
    qiangzhi,
    hetongbao,
    InspectSearch,
    InspectDetail,
    mElectronicFooter,
    qiangzhiDialog
  },
  data() {
    return {
      // fxpcshow: true,
      //   hetongbaoshow: true,
      // qiangzhishow: true,
      qiangzhi: {
        CBK: []
      },
      item1: true,
      // item2: false,
      // item3: false,
    }
  },
  computed: {
    ...mapState('insuranceSelect', ['insuranceId', 'title', 'insuranceVersion', 'grade', 'hetongbaoshow']),
    item3() {
      //  强制排查展开 合同锁隐藏
      if (this.qiangzhishow) {
        return false
      }
      //风险排查显示  
      if (this.item2) {
        // 风险排查收起  
        if (!this.fxpcshow) {
          return true
        }
        return false
      }
      return true
    },
    // 风险排查显示
    item2() {
      if (this.qiangzhishow) {
        return false
      }
      return true
    },
    // 风险排查展开  如果id为空  就是展开
    fxpcshow() {
      return !this.fxpcdata.detectionId
    },
    // 强制排查展开  如果type为空  就是展开
    qiangzhishow() {
      return !this.$store.state.electronic.qiangzhipaichaData.type
    },
    showbtn() {
      return (!this.fxpcshow) && (!this.hetongbaoshow)
    },
    // 合同保展开   如果title 为空  就是展开   
    // hetongbaoshow() {
    //   return !this.$store.state.electronic.hetongbaoData.title
    // },
    disabledNext() {
      // {{ $store.state.electronic.hetongbaoData.title }}
      return this.qiangzhishow || this.fxpcshow || this.hetongbaoshow
    },
    fxpcdata() {
      return this.$store.state.electronic.fxpcdata
    }
  },
  mounted() {
    this.qiangzhi.CBK = [...this.$store.state.electronic.qiangzhipaichaCallback];
  },
  methods: {
    // 强制修改查询
    qiangzhireset() {
      this.qiangzhi = {
        CBK: []
      }
      this.$store.commit("electronic/setqiangzhipaichaData", {
        type: null,
        cityId: 0,
        province: null,
        city: null
      })
    },
    submitForm() {
      this.$router.push({ name: 'electronicDocumentsPDF' })
    },
    hetongbaoRightClick() {
      this.$store.dispatch("insuranceSelect/setShow", true)
    },
    fxpcchange(res) {
      // console.log(res)
      this.fxpcshow = false;
      this.$store.commit("electronic/setfxpcData", res)
      this.fxpcRightClick()
    },
    fxpcRightClick() {
      this.$refs.InspectDetail.show(this.fxpcdata.detectionId)
    },
    // 强制查回调
    qiangzhiCBK({ data, cbk }) {
      this.$store.commit("electronic/setqiangzhipaichaData", data)
      this.$store.commit("electronic/setqiangzhipaichaCallback", cbk)
      this.qiangzhi.CBK = cbk;
      this.qiangzhiRightClick()
    },
    qiangzhiRightClick() {
      const { qiangzhi } = this
      const { province, city } = this.$store.state.electronic.qiangzhipaichaData;
      this.$refs.qiangzhiDialog.show({
        title: (province && city) ? `申请执行法院：${province}${city}` : '',
        text: qiangzhi.CBK[0] ? qiangzhi.CBK[0].advice : ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding-bottom: 30px;

  .content {
    width: 100%;
    margin: 0 auto;
    margin-top: 16px;

    /deep/.inspiectSearch {
      .title {
        display: none;
      }
    }

    .title {
      height: 60px;
      display: flex;
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      align-items: center;
      padding: 0 20px;
      justify-content: space-between;

      &_l {
        display: flex;
        align-items: center;

        .solid {
          border-radius: 10px;
          background: #0062ff;
          width: 2px;
          height: 16px;
          margin-right: 10px;
        }
      }

      &_r {
        color: #999;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .esc {
      color: #999;
      font-family: "Source Han Sans CN";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
    }

    .item {
      background: #fff;
    }
  }
}
</style>