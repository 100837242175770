<template>
    <div class="daijinquan">
        <el-dialog :visible.sync="dialogVisible1" width="450px" custom-class="daijinquan-box" :show-close="false">
            <div class="voucher">
                <div class="voucher-title">
                    <p>我的代金券（{{ VoucherList.length }}）</p>
                </div>
                <div class="voucher-content">
                    <div class="voucher-item pointer" v-for="item in VoucherList" :key="item.voucherId"
                        @click="voucherClick(item)">
                        <div class="voucher-item-t">
                            <div class="voucher-item-t-l">
                                <div>{{ item.amount }}</div>
                                <p class="icon">元</p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="60" viewBox="0 0 2 60"
                                fill="none">
                                <path opacity="0.5" d="M1 0V60" stroke="#0062FF" stroke-dasharray="5 5" />
                            </svg>
                            <div class="voucher-item-t-r ">
                                <div>电子合同-代金券</div>
                                <div v-if="item.voucherId === params.voucherId">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#0062FF" />
                                        <rect x="4" y="4" width="8" height="8" rx="4"
                                            fill="url(#paint0_linear_1912_2531)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1912_2531" x1="8" y1="4" x2="8" y2="12"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#0062FF" />
                                                <stop offset="1" stop-color="#003B99" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <rect opacity="0.5" x="0.5" y="0.5" width="13" height="13" rx="6.5"
                                            stroke="#0062FF" />
                                    </svg>
                                </div>

                            </div>
                        </div>
                        <div class="voucher-item-c"></div>
                        <div class="voucher-item-b">
                            <div class="voucher-item-b-l">有效期至：</div>
                            <div class="voucher-item-b-r">{{ item.validUntil | timeFormat('yyyy-mm-dd') }}</div>
                        </div>
                    </div>
                </div>
                <div class="voucher-footer">
                    <div class="voucher-footer-btn" @click="voucherBtnClick">确认</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        VoucherList: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            dialogVisible1: false,
            params: {
                voucherId: null,
            }
        };
    },
    created() { },
    computed: {},
    methods: {

        // 代金券点击
        voucherClick(item) {
            const { voucherId } = item;
            if (this.params.voucherId == voucherId) {
                this.params.voucherId = null;
            } else {
                this.params.voucherId = voucherId;
            }
        },
        voucherBtnClick() {
            const { voucherId } = this.params;
            if (!voucherId) {
                this.$emit('success', null)
                this.dialogVisible1 = false

                return
            }
            const item = this.VoucherList.find(res => res.voucherId === voucherId)
            this.$emit('success', item)
            this.dialogVisible1 = false
        },
        show() {
            this.dialogVisible1 = true;

        }
    },
};
</script>

<style scoped lang='scss'>
.daijinquan {
    &-box {
        width: 450px !important;
    }

    /deep/.el-dialog {
        &__header {
            padding: 0 !important;
        }

        &__body {
            padding: 0;
        }
    }

    .voucher {
        width: 450px !important;
        background: linear-gradient(0deg, #FFF 0%, #EFF6FF 100%);

        margin: 0 auto;
        border-radius: 6px;
        overflow: hidden;
      
        &-title {
            height: 70px;
            background: linear-gradient(90deg, #0062FF 0%, #003B99 100%);
            font-family: "Source Han Sans CN";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 70px;
            background: linear-gradient(90deg, #0062FF 0%, #003B99 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            overflow: hidden;

            p {

                margin-top: -5px;
            }
        }

        &-content {
            margin-top: 35px;
            height: 325px;
            overflow-y: auto;
        }

        &-item {
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            width: 390px;
            height: 120px;
            margin-bottom: 20px;
            border: 1px solid #0062FF;
            &-t {
                width: 390px;
                height: 88px;
                border-radius: 4px;
                background: linear-gradient(90deg, #EFF6FF 0%, #D6E8FF 100%);
                display: flex;
                align-items: center;

                &-l {
                    width: 147px;
                    color: #003B99;
                    font-family: "DIN Alternate";
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        color: #003B99;
                        font-family: "Source Han Sans CN";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-top: 16px;
                        margin-left: 6px;
                    }
                }

                &-r {
                    flex: 1;
                    display: flex;
                    padding: 0 30px;
                    align-items: center;
                    justify-content: space-between;
                    font-family: "Source Han Sans CN";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    // background: linear-gradient(90deg, #BC6416 3.33%, #914F1A 100%);
                    background: linear-gradient(90deg, #0062FF 0%, #003B99 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            &-c {
                width: 390px;
                height: 1px;
                flex-shrink: 0;
                background: linear-gradient(90deg, #FFFAF1 0%, #FED993 100%);
            }

            &-b {
                width: 390px;
                height: 32px;
                flex-shrink: 0;
                // background: linear-gradient(90deg, #F6D8AA 0%, #F4C283 100%);
                background: linear-gradient(90deg, #0062FF 0%, #003B99 100%);
                display: flex;
                padding: 0 30px;
                align-items: center;
                justify-content: space-between;
                color: #fff;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        &-footer {
            width: 450px;
            height: 80px;
            flex-shrink: 0;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            &-btn {
                width: 144px;
                height: 40px;
                flex-shrink: 0;
                border-radius: 100px;
                background: linear-gradient(90deg, #0062FF 0%, #003B99 100%);
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
                color: #fff;
            }
        }

    }
}
</style>