<template>
    <el-empty :image-size="200" >
        <img slot="image" src="@/static/image/home/empty.png" alt="" srcset="">
    </el-empty>
    
</template>

<script>
export default {

}
</script>

<style></style>