<template>
    <div class="service">
        <div class="box">
            <iframe id="myIframe" class="iframe"  frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
        };
    },
    mounted() {
        this.geturl()
    },
    computed: {},
    methods: {
        async geturl() {
            const url = await this.$im.geturl()
            this.$nextTick(() => {
                const iframe = document.getElementById("myIframe");
                iframe.src = url;
            });
        }
    },
};
</script>

<style scoped lang='scss'>
.box {
    width: 590px;
    height: 625px;
    margin: 0 auto;
    background: transparent;
    padding-top: 20px;

    .iframe {
        width: 100%;
        height: 100%;
    }
}

.eHeader {
    height: 64px;

    // border: 1px solid #fff;
    // position: absolute;
    .eHeaderContent {
        z-index: 99;
        position: fixed;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
        width: 100vw;
        top: 0px;
        height: 64px;
        left: 0;

        &-center {
            width: 100%;
            height: 100%;
            position: relative;

            .close {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                position: absolute;
                left: 24px;
                top: 0px;
                bottom: 0px;
                margin: auto;
                height: 20px;

                cursor: pointer;
            }

            .btn {
                position: absolute;
                right: 24px;
                top: 0px;
                bottom: 0px;
                margin: auto;

                width: 78px;
                height: 32px;
                background: #0062ff;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                line-height: 32px;
                text-align: center;

                cursor: pointer;
            }

            .center {
                width: 662px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: space-between;

                .solid {
                    border-bottom: 1px solid #e3e3e3;
                    flex: 1;
                    margin: 0 10px;
                }

                .item {
                    display: flex;
                    align-items: center;

                    .num {
                        width: 24px;
                        height: 24px;
                        background: #f7f8f9;
                        border: 1px solid #e3e3e3;
                        border-radius: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-size: 14px;
                        color: #999999;
                        line-height: 20px;

                        margin-right: 8px;

                        box-sizing: border-box;
                    }

                    .text {
                        font-size: 14px;
                        font-weight: 400;
                        color: #999999;
                        line-height: 20px;
                    }
                }

                .active {
                    .num {
                        background: #0062ff;
                        color: #fff;
                    }

                    .text {
                        color: #0062ff;
                    }
                }
            }
        }
    }
}
</style>