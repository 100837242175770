/*
* @Author: 曹俊杰
* @Date: 2024-09-22 23:36:52
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-11-17 18:19:04
*/签署出证
<template>
  <div class="box">
    <div class="title">
      签署出证
    </div>
    <extension-search-form :searchForm.sync="listParams" @submit="getList" />
    <div class="main">
      <etable :list="list" @operate="operate" />
      <hdq-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getList" />
    </div>
    <dirDialog ref="dirDialog" @success="getList" />
  </div>
</template>

<script>
import extensionSearchForm from "./components/extensionSearchForm.vue"
import etable from "@/views/ElectronicDocuments/etable"
import list from "@/views/ElectronicDocuments/mixins/list.js"
export default {
  mixins: [list],
  components: {
    extensionSearchForm,
    etable
  },

  data() {
    return {
      listParams: {
        state: 'FINISHED',   //合同状态
      }
    };
  },
  mounted() {
    this.getList()
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/listStyle.scss";
</style>