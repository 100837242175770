<template>
    <div class="ExperienceOfficerbox" v-show="show">
        <div class="ExperienceOfficer">
            <img :src="avatar" alt="" style="width: 22px;height: 22px;border-radius: 50%;">
            <div class="name">产品体验官</div>
        </div>
        <div class="mode">
            <div class="content">
                <div class="content_t">
                    <div class="content_t_l">
                        <img :src="avatar" alt="" style="width: 44px;height: 44px;border-radius: 50%;">
                    </div>
                    <div class="content_t_r">
                        <div class="name">
                            <p>{{ name }}</p>
                            <img src="@/static/tiyanguan.png" alt="" srcset="">
                        </div>
                        <div class="phone">手机号：{{ phone }}</div>
                    </div>
                </div>
                <div class="code">
                    <img :src="$oss+weixinQrcode" alt="" srcset="" style="margin:0 auto;width:172px;height:172px;">
                    <!-- <vue-qr :text="weixinQrcode" :size="172" style="margin:0 auto;"></vue-qr> -->
                </div>
                <div class="ti">微信二维码</div>
            </div>
        </div>
    </div>

</template>

<script>
// import vueQr from 'vue-qr'
export default {
    // components: { vueQr },
    data() {
        return {
            ExperienceOfficer: {
                uuid: null,
                name: null,
                avatar: null,
                weixinQrcode: null,
                phone: null
            },
            show:false
        }
    },
    computed: {
        avatar() {
            if (!this.ExperienceOfficer.avatar) {
                return ''
            }
            return this.$oss + this.ExperienceOfficer.avatar
        },
        name() {
            if (!this.ExperienceOfficer.avatar) {
                return ''
            }
            return this.ExperienceOfficer.name
        },
        phone() {
            if (!this.ExperienceOfficer.phone) {
                return ''
            }
            return this.ExperienceOfficer.phone
        },
        weixinQrcode(){
            if (!this.ExperienceOfficer.weixinQrcode) {
                return ''
            }
            return this.ExperienceOfficer.weixinQrcode
        }
    },
    mounted() {
        this.getExperienceOfficer()
    },
    methods: {
        async getExperienceOfficer() {  // 产品体验官
            const { status, data } = await this.$api.getExperienceOfficer()
            if (status === 200) {
                if(!data.name){
                    return
                }
                this.ExperienceOfficer = data;
                this.show=true
            }
        },
    }

}
</script>

<style lang="scss" scoped>
.ExperienceOfficerbox {
    .ExperienceOfficer {
        display: flex;
        align-items: center;
        cursor: pointer;

        .name {
            color: #262626;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 8px;
        }
    }

    &:hover {
        .mode {
            display: block;
        }
    }

    position: relative;

    .mode {

        display: none;
        position: absolute;
        z-index: 20;
        top: 22px;
        right: 0px;

        .content {
            width: 280px;
            flex-shrink: 0;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.70);
            margin-top: 18px;
            padding: 20px 40px;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
            &_t {
                display: flex;

                &_l {
                    margin-right: 10px;
                }

                &_r {

                    .name {
                        display: flex;
                        color: #fff;

                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        align-items: center;

                        img {
                            width: 59px;
                            height: 16px;
                            flex-shrink: 0;
                            margin-left: 6px;
                        }
                    }

                    .phone {
                        color: #fff;
                        opacity: 0.6;
                        font-family: "Source Han Sans CN";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-top: 7px;
                    }
                }
            }

            .code {
                border-radius: 6px;
                border: 1px solid #EEE;
                background: #F3F6F8;
                width: 200px;
                height: 200px;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .ti {
                text-align: center;
                color: #666;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 10px;
            }
        }
    }
}
</style>