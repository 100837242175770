<template>
    <el-drawer :visible.sync="isshow" direction="rtl" size="888px" :with-header="false" :modal="false" destroy-on-close>
        <el-card shadow="never">
            <div slot="header" class="header">
                <!-- <el-button type="text" class="back" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button> -->
                <div class="tittle">核查结果</div>
            </div>
            <div id="container">
                <div class="conter">
                    <img src="@/static/image/inspect/seal_1.png" class="seal"
                        v-if="infoLog_final_weight && hxLog_final_weight" />
                    <img src="@/static/image/inspect/seal_2.png" class="seal" v-else />
                    <div class="title">
                        <div class="title-l">
                            <img src="@/static/image/inspect/image001.jpg" alt="" srcset="">
                            <p>审批报告</p>
                        </div>
                        <div class="title-r">
                            <p>报告编号：{{ infoLog.swift_number }},</p>
                            <p>{{ hxLog.swift_number }}</p>
                            <p>生成时间：{{ data.verification.createdAt | timeFormat('yyyy-mm-dd hh:MM:ss') }}</p>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <div class="top">
                        <div class="top-l">
                            <p>验证规则：
                                <span class="err" v-if="infoLog_final_weight">高风险</span>
                                <span class="succ" v-else>低风险</span>
                            </p>
                            <p class="des">(MCP_BR0001195 / 预置_验证流程策略_001)</p>
                        </div>
                        <div class="top-r">
                            <p>反欺诈规则：
                                <span class="err" v-if="hxLog_final_weight">高风险</span>
                                <span class="succ" v-else>低风险</span>
                            </p>
                            <p class="des">(STR0040902 / 贷前策略api)</p>
                        </div>
                    </div>
                    <div class="hint">
                        <div class="hint-l">规则风险提示</div>
                        <div class="hint-r">
                            该用户共命中 <span class="red">0</span> 条异常规则信息
                        </div>
                    </div>

                    <!-- 要素核查 -->
                    <div>
                        <h4>手机三要素核验</h4>
                        <div class="box">
                            <p>策略描述： 使用姓名、手机号、身份证号等信息对被查询人进行信息核</p>
                            <p>
                                结论分析：
                                <span>
                                    <template v-if="rule_c_telcheck_s.weight < 0">
                                        <i class="el-icon-close wu"></i>
                                        {{ rule_c_telcheck_s.name_rule }}
                                    </template>
                                    <template v-else-if="rule_c_telcheck_s.weight < 80">
                                        <i class="el-icon-check dui"></i>
                                        {{ rule_c_telcheck_s.name_rule }}
                                    </template>
                                    <template v-else>
                                        <i class="el-icon-ali-gaoxiao text-red"></i>
                                        {{ rule_c_telcheck_s.name_rule }}
                                    </template>
                                </span>
                            </p>
                        </div>
                    </div>




                    <div>
                        <h4>手机在网时长</h4>
                        <div class="box">
                            <p>策略描述： 查询被查询人的手机在网时长</p>
                            <p>
                                结论分析：
                                <span>
                                    <template v-if="rule_c_telperiod.weight < 0">
                                        <i class="el-icon-close wu"></i>
                                        {{ rule_c_telperiod.name_rule }}
                                    </template>
                                    <template v-else-if="rule_c_telperiod.weight < 80">
                                        <i class="el-icon-check dui"></i>
                                        {{ rule_c_telperiod.name_rule }}
                                    </template>
                                    <template v-else>
                                        <i class="el-icon-ali-gaoxiao text-red"></i>
                                        {{ rule_c_telperiod.name_rule }}
                                    </template>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div>
                        <h4>手机状态</h4>
                        <div class="box">
                            <p>策略描述： 查询被查询人的手机状态</p>
                            <p>
                                结论分析：
                                <span>
                                    <template v-if="rule_c_telstatus.weight < 0">
                                        <i class="el-icon-close wu"></i>
                                        {{ rule_c_telstatus.name_rule }}
                                    </template>
                                    <template v-else-if="rule_c_telstatus.weight < 80">
                                        <i class="el-icon-check dui"></i>
                                        {{ rule_c_telstatus.name_rule }}
                                    </template>
                                    <template v-else>
                                        <i class="el-icon-ali-gaoxiao text-red"></i>
                                        {{ rule_c_telstatus.name_rule }}
                                    </template>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div>
                        <h4>法院信息</h4>
                        <div class="box">
                            <p>策略描述： 查询被查询人的法院信息详情</p>
                            <p>
                                结论分析：
                                <span>
                                    <template v-if="rule_c_courtdetailpro.weight < 0">
                                        <i class="el-icon-close wu"></i>
                                        {{ rule_c_courtdetailpro.name_rule }}
                                    </template>
                                    <template v-else-if="rule_c_courtdetailpro.weight < 80">
                                        <i class="el-icon-check dui"></i>
                                        {{ rule_c_courtdetailpro.name_rule }}
                                    </template>
                                    <template v-else>
                                        <i class="el-icon-ali-gaoxiao text-red"></i>
                                        {{ rule_c_courtdetailpro.name_rule }}
                                    </template>
                                </span>
                            </p>
                        </div>
                    </div>



                    <!-- 风险识别 -->
                    <div>
                        <h4>风险识别</h4>
                        <div class="box">
                            <p>策略描述： 检测被查询人的借贷风险情况，及在司法体系中是否存失信行为等风险</p>
                            <p>
                                结论分析：
                                <span><i class="el-icon-close wu"></i> 无结果</span>
                            </p>
                        </div>
                    </div>
                    <!-- 借贷评估 -->
                    <div>
                        <h4>借贷评估
                            <span style="font-size:12px;font-weight:400;color: #84919a;">该产品类命中 <span
                                    style="color:#ff6365;font-weight:600">{{ ruleapplyloan_height }}</span> 条高风险</span>
                        </h4>

                        <div class="box">
                            <p>策略描述： 检测被查询人的借贷风险情况，及在司法体系中是否存失信行为等风险</p>
                            <p>
                                结论分析：
                                <span v-for="(item, index) in ruleapplyloan" :key="index">
                                    <template v-if="item.weight < 0">
                                        <i class="el-icon-close wu"></i>
                                        {{ item.name_rule }}
                                    </template>
                                    <template v-else-if="item.weight < 80">
                                        <i class="el-icon-check dui"></i>
                                        {{ item.name_rule }}
                                    </template>
                                    <template v-else>
                                        <i class="el-icon-ali-gaoxiao text-red"></i>
                                        {{ item.name_rule }}
                                    </template>
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- 运营商核验产品 - 命中详情 -->
                    <div class="tableHeader" style="margin-top:20px;">
                        <div class="tableHeader_number">01</div>
                        <div class="tableHeader_title">
                            <div class="tableHeader_title_t">运营商核验产品 - 命中详情</div>
                            <div class="tableHeader_title_b">Operator verification</div>
                        </div>
                    </div>
                    <div class="header2">
                        <div class="header2_l">手机信息验证</div>
                        <div class="header2_r" v-show="false">无建议</div>
                    </div>
                    <el-table :header-cell-style="{ background: '#f7f7fa', color: '#333' }" border size="mini"
                        :data="phoneDetail" style="width: 100%;">
                        <el-table-column prop="operation" label="手机运营商类型" width="180">
                        </el-table-column>
                        <el-table-column prop="TelStatus" label="手机在网状态" width="180">
                        </el-table-column>
                        <el-table-column prop="TelPeriod" label="手机在网时长">
                        </el-table-column>
                    </el-table>
                    <div class="des2">
                        <p>注：</p>
                        <ul>
                            <li>1. “手机在网状态” 、 “手机在网时长” 数据覆盖全国范围，移动、联通、电信三家运营商数据。</li>
                        </ul>
                    </div>

                    <!-- 借贷评估产品 - 命中详情 -->
                    <div class="tableHeader" style="margin-top:20px;">
                        <div class="tableHeader_number">02</div>
                        <div class="tableHeader_title">
                            <div class="tableHeader_title_t">借贷评估产品 - 命中详情</div>
                            <div class="tableHeader_title_b">Loan assessment</div>
                        </div>
                    </div>
                    <div class="header2">
                        <div class="header2_l">借贷意向验证</div>
                        <div class="header2_r" v-show="false">无建议</div>
                    </div>
                    <div class="des">借贷意向数据覆盖大部分的金融机构。机构类型包括银行、改制机构、小贷、消费类分期、现金类分期、代偿类分期和非银其它。</div>
                    <div class="header3">
                        本人在本机构借贷意向表现
                    </div>
                    <el-table :header-cell-style="{ background: '#f0f1f5', color: '#333' }" border size="mini" :data="renderTable([
                        [
                            '银行',
                            [ObjMap.als_d7_id_bank_selfnum, ObjMap.als_d7_cell_bank_selfnum],
                            [ObjMap.als_d15_id_bank_selfnum, ObjMap.als_d15_cell_bank_selfnum,],
                            [ObjMap.als_m1_id_bank_selfnum, ObjMap.als_m1_cell_bank_selfnum,],
                            [ObjMap.als_m3_id_bank_selfnum, ObjMap.als_m3_cell_bank_selfnum,],
                            [ObjMap.als_m6_id_bank_selfnum, ObjMap.als_m6_cell_bank_selfnum,],
                            [ObjMap.als_m12_id_bank_selfnum, ObjMap.als_m12_cell_bank_selfnum,],
                        ],
                        [
                            '非银',
                            [ObjMap.als_d7_id_nbank_selfnum, ObjMap.als_d7_cell_nbank_selfnum],
                            [ObjMap.als_d15_id_nbank_selfnum, ObjMap.als_d15_cell_nbank_selfnum,],
                            [ObjMap.als_m1_id_nbank_selfnum, ObjMap.als_m1_cell_nbank_selfnum,],
                            [ObjMap.als_m3_id_nbank_selfnum, ObjMap.als_m3_cell_nbank_selfnum,],
                            [ObjMap.als_m6_id_nbank_selfnum, ObjMap.als_m6_cell_nbank_selfnum,],
                            [ObjMap.als_m12_id_nbank_selfnum, ObjMap.als_m12_cell_nbank_selfnum,],
                        ]
                    ])" style="width: 100%;margin-top:10px;">
                        <el-table-column prop="1" label="申请次数">
                        </el-table-column>
                        <el-table-column prop="2" label="近7天">
                        </el-table-column>
                        <el-table-column prop="3" label="近15天">
                        </el-table-column>
                        <el-table-column prop="4" label="近1个月">
                        </el-table-column>
                        <el-table-column prop="5" label="近3个月">
                        </el-table-column>
                        <el-table-column prop="6" label="近6个月">
                        </el-table-column>
                        <el-table-column prop="7" label="近12个月">
                        </el-table-column>
                    </el-table>
                    <div class="des2">
                        <p>注：</p>
                        <ul>
                            <li>1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</li>
                            <li>2. 取值为"空" "0" "N"——空：无申请记录；0：本机构无申请记录但其他非银机构有申请；N：申请次数详情。</li>
                        </ul>
                    </div>

                    <!-- <div class="header3">
                        本人在各个客户类型借贷意向表现
                    </div>
                    <el-table :header-cell-style="{ background: '#f0f1f5', color: '#333' }" border size="mini" :data="renderTable([[
                        '银行汇总',
                        [ObjMap.als_d7_id_bank_selfnum, ObjMap.als_d7_cell_bank_selfnum],
                        [ObjMap.als_d15_id_bank_selfnum, ObjMap.als_d15_cell_bank_selfnum,],
                        [ObjMap.als_m1_id_bank_selfnum, ObjMap.als_m1_cell_bank_selfnum,],
                        [ObjMap.als_m3_id_bank_selfnum, ObjMap.als_m3_cell_bank_selfnum,],
                        [ObjMap.als_m6_id_bank_selfnum, ObjMap.als_m6_cell_bank_selfnum,],
                        [ObjMap.als_m12_id_bank_selfnum, ObjMap.als_m12_cell_bank_selfnum,],
                    ],
                    [
                        '传统银行',
                        [ObjMap.als_d7_id_nbank_selfnum, ObjMap.als_d7_cell_nbank_selfnum],
                        [ObjMap.als_d15_id_nbank_selfnum, ObjMap.als_d15_cell_nbank_selfnum,],
                        [ObjMap.als_m1_id_nbank_selfnum, ObjMap.als_m1_cell_nbank_selfnum,],
                        [ObjMap.als_m3_id_nbank_selfnum, ObjMap.als_m3_cell_nbank_selfnum,],
                        [ObjMap.als_m6_id_nbank_selfnum, ObjMap.als_m6_cell_nbank_selfnum,],
                        [ObjMap.als_m12_id_nbank_selfnum, ObjMap.als_m12_cell_nbank_selfnum,],
                    ], [
                        '非银汇总',
                        [ObjMap.als_d7_id_nbank_selfnum, ObjMap.als_d7_cell_nbank_selfnum],
                        [ObjMap.als_d15_id_nbank_selfnum, ObjMap.als_d15_cell_nbank_selfnum,],
                        [ObjMap.als_m1_id_nbank_selfnum, ObjMap.als_m1_cell_nbank_selfnum,],
                        [ObjMap.als_m3_id_nbank_selfnum, ObjMap.als_m3_cell_nbank_selfnum,],
                        [ObjMap.als_m6_id_nbank_selfnum, ObjMap.als_m6_cell_nbank_selfnum,],
                        [ObjMap.als_m12_id_nbank_selfnum, ObjMap.als_m12_cell_nbank_selfnum,],
                    ], [
                        '持牌消费金融',
                        [ObjMap.als_d7_id_nbank_selfnum, ObjMap.als_d7_cell_nbank_selfnum],
                        [ObjMap.als_d15_id_nbank_selfnum, ObjMap.als_d15_cell_nbank_selfnum,],
                        [ObjMap.als_m1_id_nbank_selfnum, ObjMap.als_m1_cell_nbank_selfnum,],
                        [ObjMap.als_m3_id_nbank_selfnum, ObjMap.als_m3_cell_nbank_selfnum,],
                        [ObjMap.als_m6_id_nbank_selfnum, ObjMap.als_m6_cell_nbank_selfnum,],
                        [ObjMap.als_m12_id_nbank_selfnum, ObjMap.als_m12_cell_nbank_selfnum,],
                    ]])" style="width: 100%;margin-top:10px;">
                        <el-table-column prop="1" label="客户类型">
                        </el-table-column>
                        <el-table-column label="近7天">
                            <el-table-column prop="2" label="机构数">
                            </el-table-column>
                            <el-table-column prop="3" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近15天">
                            <el-table-column prop="4" label="机构数">
                            </el-table-column>
                            <el-table-column prop="5" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近1个月">
                            <el-table-column prop="6" label="机构数">
                            </el-table-column>
                            <el-table-column prop="7" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近3个月">
                            <el-table-column prop="8" label="机构数">
                            </el-table-column>
                            <el-table-column prop="9" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近6个月">
                            <el-table-column prop="10" label="机构数">
                            </el-table-column>
                            <el-table-column prop="11" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近12个月">
                            <el-table-column prop="12" label="机构数">
                            </el-table-column>
                            <el-table-column prop="13" label="次数">
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                    <div class="des2">
                        <p>注：</p>
                        <ul>
                            <li>1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</li>
                            <li>2. 取值为"空" "N"——空：无申请记录；N：申请记录详情。</li>
                        </ul>
                    </div> -->

                    <div class="header3">
                        本人在各个业务类型借贷意向表现
                    </div>
                    <el-table :header-cell-style="{ background: '#f0f1f5', color: '#333' }" border size="mini" :data="renderTable(
                        [[
                            '信用卡（类信用卡)',
                            [ObjMap.als_d7_id_rel_orgnum, ObjMap.als_d7_cell_rel_orgnum,],
                            [ObjMap.als_d7_id_rel_allnum, ObjMap.als_d7_cell_rel_allnum],
                            [ObjMap.als_d15_id_rel_orgnum, ObjMap.als_d15_cell_rel_orgnum,],
                            [ObjMap.als_d15_id_rel_allnum, ObjMap.als_d15_cell_rel_allnum],
                            [ObjMap.als_m1_id_rel_orgnum, ObjMap.als_m1_cell_rel_orgnum,],
                            [ObjMap.als_m1_id_rel_allnum, ObjMap.als_m1_cell_rel_allnum,],
                            [ObjMap.als_m3_id_rel_orgnum, ObjMap.als_m3_cell_rel_orgnum,],
                            [ObjMap.als_m3_id_rel_allnum, ObjMap.als_m3_cell_rel_allnum,],
                            [ObjMap.als_m6_id_rel_orgnum, ObjMap.als_m6_cell_rel_orgnum,],
                            [ObjMap.als_m6_id_rel_allnum, ObjMap.als_m6_cell_rel_allnum,],
                            [ObjMap.als_m12_id_rel_orgnum, ObjMap.als_m12_cell_rel_orgnum,],
                            [ObjMap.als_m12_id_rel_allnum, ObjMap.als_m12_cell_rel_allnum,],
                            [ObjMap.als_d7_id_rel_orgnum, ObjMap.als_d7_cell_rel_orgnum,],
                            [ObjMap.als_d7_id_rel_allnum, ObjMap.als_d7_cell_rel_allnum,],

                        ],
                        [
                            '线上现金分期',
                            [ObjMap.als_d7_id_caon_orgnum, ObjMap.als_d7_cell_caon_orgnum,],
                            [ObjMap.als_d7_id_caon_allnum, ObjMap.als_d7_cell_caon_allnum],
                            [ObjMap.als_d15_id_caon_orgnum, ObjMap.als_d15_cell_caon_orgnum,],
                            [ObjMap.als_d15_id_caon_allnum, ObjMap.als_d15_cell_caon_allnum],
                            [ObjMap.als_m1_id_caon_orgnum, ObjMap.als_m1_cell_caon_orgnum,],
                            [ObjMap.als_m1_id_caon_allnum, ObjMap.als_m1_cell_caon_allnum,],
                            [ObjMap.als_m3_id_caon_orgnum, ObjMap.als_m3_cell_caon_orgnum,],
                            [ObjMap.als_m3_id_caon_allnum, ObjMap.als_m3_cell_caon_allnum,],
                            [ObjMap.als_m6_id_caon_orgnum, ObjMap.als_m6_cell_caon_orgnum,],
                            [ObjMap.als_m6_id_caon_allnum, ObjMap.als_m6_cell_caon_allnum,],
                            [ObjMap.als_m12_id_caon_orgnum, ObjMap.als_m12_cell_caon_orgnum,],
                            [ObjMap.als_m12_id_caon_allnum, ObjMap.als_m12_cell_caon_allnum,],
                            [ObjMap.als_d7_id_caon_orgnum, ObjMap.als_d7_cell_caon_orgnum,],
                            [ObjMap.als_d7_id_caon_allnum, ObjMap.als_d7_cell_caon_allnum,],
                        ], [
                            '线下现金分期',
                            [ObjMap.als_d7_id_caoff_orgnum, ObjMap.als_d7_cell_caoff_orgnum,],
                            [ObjMap.als_d7_id_caoff_allnum, ObjMap.als_d7_cell_caoff_allnum],
                            [ObjMap.als_d15_id_caoff_orgnum, ObjMap.als_d15_cell_caoff_orgnum,],
                            [ObjMap.als_d15_id_caoff_allnum, ObjMap.als_d15_cell_caoff_allnum],
                            [ObjMap.als_m1_id_caoff_orgnum, ObjMap.als_m1_cell_caoff_orgnum,],
                            [ObjMap.als_m1_id_caoff_allnum, ObjMap.als_m1_cell_caoff_allnum,],
                            [ObjMap.als_m3_id_caoff_orgnum, ObjMap.als_m3_cell_caoff_orgnum,],
                            [ObjMap.als_m3_id_caoff_allnum, ObjMap.als_m3_cell_caoff_allnum,],
                            [ObjMap.als_m6_id_caoff_orgnum, ObjMap.als_m6_cell_caoff_orgnum,],
                            [ObjMap.als_m6_id_caoff_allnum, ObjMap.als_m6_cell_caoff_allnum,],
                            [ObjMap.als_m12_id_caoff_orgnum, ObjMap.als_m12_cell_caoff_orgnum,],
                            [ObjMap.als_m12_id_caoff_allnum, ObjMap.als_m12_cell_caoff_allnum,],
                            [ObjMap.als_d7_id_caoff_orgnum, ObjMap.als_d7_cell_caoff_orgnum,],
                            [ObjMap.als_d7_id_caoff_allnum, ObjMap.als_d7_cell_caoff_allnum,],
                        ], [
                            '其他',
                            [ObjMap.als_d7_id_oth_orgnum, ObjMap.als_d7_cell_oth_orgnum,],
                            [ObjMap.als_d7_id_oth_allnum, ObjMap.als_d7_cell_oth_allnum],
                            [ObjMap.als_d15_id_oth_orgnum, ObjMap.als_d15_cell_oth_orgnum,],
                            [ObjMap.als_d15_id_oth_allnum, ObjMap.als_d15_cell_oth_allnum],
                            [ObjMap.als_m1_id_oth_orgnum, ObjMap.als_m1_cell_oth_orgnum,],
                            [ObjMap.als_m1_id_oth_allnum, ObjMap.als_m1_cell_oth_allnum,],
                            [ObjMap.als_m3_id_oth_orgnum, ObjMap.als_m3_cell_oth_orgnum,],
                            [ObjMap.als_m3_id_oth_allnum, ObjMap.als_m3_cell_oth_allnum,],
                            [ObjMap.als_m6_id_oth_orgnum, ObjMap.als_m6_cell_oth_orgnum,],
                            [ObjMap.als_m6_id_oth_allnum, ObjMap.als_m6_cell_oth_allnum,],
                            [ObjMap.als_m12_id_oth_orgnum, ObjMap.als_m12_cell_oth_orgnum,],
                            [ObjMap.als_m12_id_oth_allnum, ObjMap.als_m12_cell_oth_allnum,],
                            [ObjMap.als_d7_id_oth_orgnum, ObjMap.als_d7_cell_oth_orgnum,],
                            [ObjMap.als_d7_id_oth_allnum, ObjMap.als_d7_cell_oth_allnum,],
                        ]]
                    )" style="width: 100%;margin-top:10px;">
                        <el-table-column prop="1" label="业务类型">
                        </el-table-column>
                        <el-table-column label="近7天">
                            <el-table-column prop="2" label="机构数">
                            </el-table-column>
                            <el-table-column prop="3" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近15天">
                            <el-table-column prop="4" label="机构数">
                            </el-table-column>
                            <el-table-column prop="5" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近1个月">
                            <el-table-column prop="6" label="机构数">
                            </el-table-column>
                            <el-table-column prop="7" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近3个月">
                            <el-table-column prop="8" label="机构数">
                            </el-table-column>
                            <el-table-column prop="9" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近6个月">
                            <el-table-column prop="10" label="机构数">
                            </el-table-column>
                            <el-table-column prop="11" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近12个月">
                            <el-table-column prop="12" label="机构数">
                            </el-table-column>
                            <el-table-column prop="13" label="次数">
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                    <div class="des2">
                        <p>注：</p>
                        <ul>
                            <li>1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</li>
                            <li>2. 取值为"空" "N"——空：无申请记录；N：申请记录详情。</li>
                        </ul>
                    </div>

                    <div class="header3">
                        本人在异常时间段借贷意向表现
                    </div>
                    <el-table :header-cell-style="{ background: '#f0f1f5', color: '#333' }" border size="mini" :data="renderTable(
                        [[
                            '夜间银行',
                            [ObjMap.als_d7_id_bank_night_orgnum, ObjMap.als_d7_cell_bank_night_orgnum,],
                            [ObjMap.als_d7_id_bank_night_allnum, ObjMap.als_d7_cell_bank_night_allnum],
                            [ObjMap.als_d15_id_bank_night_orgnum, ObjMap.als_d15_cell_bank_night_orgnum,],
                            [ObjMap.als_d15_id_bank_night_allnum, ObjMap.als_d15_cell_bank_night_allnum],
                            [ObjMap.als_m1_id_bank_night_orgnum, ObjMap.als_m1_cell_bank_night_orgnum,],
                            [ObjMap.als_m1_id_bank_night_allnum, ObjMap.als_m1_cell_bank_night_allnum,],
                            [ObjMap.als_m3_id_bank_night_orgnum, ObjMap.als_m3_cell_bank_night_orgnum,],
                            [ObjMap.als_m3_id_bank_night_allnum, ObjMap.als_m3_cell_bank_night_allnum,],
                            [ObjMap.als_m6_id_bank_night_orgnum, ObjMap.als_m6_cell_bank_night_orgnum,],
                            [ObjMap.als_m6_id_bank_night_allnum, ObjMap.als_m6_cell_bank_night_allnum,],
                            [ObjMap.als_m12_id_bank_night_orgnum, ObjMap.als_m12_cell_bank_night_orgnum,],
                            [ObjMap.als_m12_id_bank_night_allnum, ObjMap.als_m12_cell_bank_night_allnum,],
                            [ObjMap.als_d7_id_bank_night_orgnum, ObjMap.als_d7_cell_bank_night_orgnum,],
                            [ObjMap.als_d7_id_bank_night_allnum, ObjMap.als_d7_cell_bank_night_allnum,],

                        ],
                        [
                            '夜间非银',
                            [ObjMap.als_d7_id_nbank_night_orgnum, ObjMap.als_d7_cell_nbank_night_orgnum,],
                            [ObjMap.als_d7_id_nbank_night_allnum, ObjMap.als_d7_cell_nbank_night_allnum],
                            [ObjMap.als_d15_id_nbank_night_orgnum, ObjMap.als_d15_cell_nbank_night_orgnum,],
                            [ObjMap.als_d15_id_nbank_night_allnum, ObjMap.als_d15_cell_nbank_night_allnum],
                            [ObjMap.als_m1_id_nbank_night_orgnum, ObjMap.als_m1_cell_nbank_night_orgnum,],
                            [ObjMap.als_m1_id_nbank_night_allnum, ObjMap.als_m1_cell_nbank_night_allnum,],
                            [ObjMap.als_m3_id_nbank_night_orgnum, ObjMap.als_m3_cell_nbank_night_orgnum,],
                            [ObjMap.als_m3_id_nbank_night_allnum, ObjMap.als_m3_cell_nbank_night_allnum,],
                            [ObjMap.als_m6_id_nbank_night_orgnum, ObjMap.als_m6_cell_nbank_night_orgnum,],
                            [ObjMap.als_m6_id_nbank_night_allnum, ObjMap.als_m6_cell_nbank_night_allnum,],
                            [ObjMap.als_m12_id_nbank_night_orgnum, ObjMap.als_m12_cell_nbank_night_orgnum,],
                            [ObjMap.als_m12_id_nbank_night_allnum, ObjMap.als_m12_cell_nbank_night_allnum,],
                            [ObjMap.als_d7_id_nbank_night_orgnum, ObjMap.als_d7_cell_nbank_night_orgnum,],
                            [ObjMap.als_d7_id_nbank_night_allnum, ObjMap.als_d7_cell_nbank_night_allnum,],
                        ], [
                            '周末银行',
                            [ObjMap.als_d7_id_bank_week_orgnum, ObjMap.als_d7_cell_bank_week_orgnum,],
                            [ObjMap.als_d7_id_bank_week_allnum, ObjMap.als_d7_cell_bank_week_allnum],
                            [ObjMap.als_d15_id_bank_week_orgnum, ObjMap.als_d15_cell_bank_week_orgnum,],
                            [ObjMap.als_d15_id_bank_week_allnum, ObjMap.als_d15_cell_bank_week_allnum],
                            [ObjMap.als_m1_id_bank_week_orgnum, ObjMap.als_m1_cell_bank_week_orgnum,],
                            [ObjMap.als_m1_id_bank_week_allnum, ObjMap.als_m1_cell_bank_week_allnum,],
                            [ObjMap.als_m3_id_bank_week_orgnum, ObjMap.als_m3_cell_bank_week_orgnum,],
                            [ObjMap.als_m3_id_bank_week_allnum, ObjMap.als_m3_cell_bank_week_allnum,],
                            [ObjMap.als_m6_id_bank_week_orgnum, ObjMap.als_m6_cell_bank_week_orgnum,],
                            [ObjMap.als_m6_id_bank_week_allnum, ObjMap.als_m6_cell_bank_week_allnum,],
                            [ObjMap.als_m12_id_bank_week_orgnum, ObjMap.als_m12_cell_bank_week_orgnum,],
                            [ObjMap.als_m12_id_bank_week_allnum, ObjMap.als_m12_cell_bank_week_allnum,],
                            [ObjMap.als_d7_id_bank_week_orgnum, ObjMap.als_d7_cell_bank_week_orgnum,],
                            [ObjMap.als_d7_id_bank_week_allnum, ObjMap.als_d7_cell_bank_week_allnum,],
                        ], [
                            '周末非银',
                            [ObjMap.als_d7_id_nbank_week_orgnum, ObjMap.als_d7_cell_nbank_week_orgnum,],
                            [ObjMap.als_d7_id_nbank_week_allnum, ObjMap.als_d7_cell_nbank_week_allnum],
                            [ObjMap.als_d15_id_nbank_week_orgnum, ObjMap.als_d15_cell_nbank_week_orgnum,],
                            [ObjMap.als_d15_id_nbank_week_allnum, ObjMap.als_d15_cell_nbank_week_allnum],
                            [ObjMap.als_m1_id_nbank_week_orgnum, ObjMap.als_m1_cell_nbank_week_orgnum,],
                            [ObjMap.als_m1_id_nbank_week_allnum, ObjMap.als_m1_cell_nbank_week_allnum,],
                            [ObjMap.als_m3_id_nbank_week_orgnum, ObjMap.als_m3_cell_nbank_week_orgnum,],
                            [ObjMap.als_m3_id_nbank_week_allnum, ObjMap.als_m3_cell_nbank_week_allnum,],
                            [ObjMap.als_m6_id_nbank_week_orgnum, ObjMap.als_m6_cell_nbank_week_orgnum,],
                            [ObjMap.als_m6_id_nbank_week_allnum, ObjMap.als_m6_cell_nbank_week_allnum,],
                            [ObjMap.als_m12_id_nbank_week_orgnum, ObjMap.als_m12_cell_nbank_week_orgnum,],
                            [ObjMap.als_m12_id_nbank_week_allnum, ObjMap.als_m12_cell_nbank_week_allnum,],
                            [ObjMap.als_d7_id_nbank_week_orgnum, ObjMap.als_d7_cell_nbank_week_orgnum,],
                            [ObjMap.als_d7_id_nbank_week_allnum, ObjMap.als_d7_cell_nbank_week_allnum,],
                        ]]
                    )" style="width: 100%;margin-top:10px;">
                        <el-table-column prop="1" label="时间-机构">
                        </el-table-column>
                        <el-table-column label="近7天">
                            <el-table-column prop="2" label="机构数">
                            </el-table-column>
                            <el-table-column prop="3" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近15天">
                            <el-table-column prop="4" label="机构数">
                            </el-table-column>
                            <el-table-column prop="5" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近1个月">
                            <el-table-column prop="6" label="机构数">
                            </el-table-column>
                            <el-table-column prop="7" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近3个月">
                            <el-table-column prop="8" label="机构数">
                            </el-table-column>
                            <el-table-column prop="9" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近6个月">
                            <el-table-column prop="10" label="机构数">
                            </el-table-column>
                            <el-table-column prop="11" label="次数">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="近12个月">
                            <el-table-column prop="12" label="机构数">
                            </el-table-column>
                            <el-table-column prop="13" label="次数">
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                    <div class="des2">
                        <p>注：</p>
                        <ul>
                            <li>1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。</li>
                            <li>2. 取值为"空" "N"——空：无申请记录；N：申请记录详情。</li>
                        </ul>
                    </div>
                    <div class="footer">
                        <p>阅读须知：</p>
                        <ul>
                            <li>1.客户使用本报告，需经过被查询人授权，客户承担因授权不充分引起的任何法律责任。</li>
                            <li>2.本报告仅限客户内部使用，请妥善保管本报告，不得向任何第三方泄露或允许任何第三方使用本报告。
                            </li>
                            <li>3.本报告仅供客户参考，不作为客户决策的依据。</li>
                            <li>4.未经我司书面许可，任何人不得擅自复制、摘录、编辑、转载、披露和发表。</li>
                            <li>5.请确保在安全的物理及网络环境操作并确保导出内容的保密、安全以及合规应用。</li>
                        </ul>
                    </div>
                </div>
            </div>
        </el-card>
    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            data: null,
            isshow: true
        }
    },
    computed: {
        infoLog() {
            const defData = {
                swift_number: '',
                InfoVerification: {
                    final_weight: 0,
                    hit_rules: {
                        rule_c_telcheck_s: {
                            CLD011: {
                                "weight": "0",
                                "name_rule": "无结果"
                            }
                        }
                    }
                }
            }
            if (this.data) {
                if (this.data.verification) {
                    const data = JSON.parse(this.data.verification.infoLog)
                    return data
                }
                return defData
            }

            return defData
        },
        hxLog() {
            const defData = {
                swift_number: '',
                "Rule": {
                    "result": {
                        "final_weight": "0",
                        "final_decision": "Accept"
                    }
                },
                ApplyLoanStr: {}
            }
            if (this.data) {
                if (this.data.verification) {
                    return JSON.parse(this.data.verification.hxLog)
                }
                return defData
            }
            return defData
        },
        // 验证规则是否通过
        infoLog_final_weight() {
            console.log(this.infoLog.InfoVerification)
            return this.infoLog.InfoVerification.final_weight > 80
        },
        // 反欺诈规则是否通过
        hxLog_final_weight() {
            return this.hxLog.Rule.result.final_weight > 80
        },
        //手机三要素核验
        rule_c_telcheck_s() {
            let data = {
                "weight": "-1",
                "name_rule": "无结果"
            }
            if (!this.infoLog.InfoVerification.hit_rules) {
                return data
            }
            if (!this.infoLog.InfoVerification.hit_rules.rule_c_telcheck_s) {
                return data
            }

            const values = Object.values(this.infoLog.InfoVerification.hit_rules.rule_c_telcheck_s)
            values.forEach(res => {
                if (res.weight > data.weight) {
                    data = res
                }

            })
            return data
        },
        //手机在网时长
        rule_c_telperiod() {
            let data = {
                "weight": "-1",
                "name_rule": "无结果"
            }
            if (!this.infoLog.InfoVerification.hit_rules) {
                return data
            }
            if (!this.infoLog.InfoVerification.hit_rules.rule_c_telperiod) {
                return data
            }

            const values = Object.values(this.infoLog.InfoVerification.hit_rules.rule_c_telperiod)
            values.forEach(res => {
                if (res.weight > data.weight) {
                    data = res
                }

            })
            return data
        },
        //手机状态
        rule_c_telstatus() {
            let data = {
                "weight": "-1",
                "name_rule": "无结果"
            }
            if (!this.infoLog.InfoVerification.hit_rules) {
                return data
            }
            if (!this.infoLog.InfoVerification.hit_rules.rule_c_telstatus) {
                return data
            }

            const values = Object.values(this.infoLog.InfoVerification.hit_rules.rule_c_telstatus)
            values.forEach(res => {
                if (res.weight > data.weight) {
                    data = res
                }

            })
            return data
        },
        //手机状态
        rule_c_courtdetailpro() {
            let data = {
                "weight": "-1",
                "name_rule": "无结果"
            }
            if (!this.infoLog.InfoVerification.hit_rules) {
                return data
            }
            if (!this.infoLog.InfoVerification.hit_rules.rule_c_courtdetailpro) {
                return data
            }

            const values = Object.values(this.infoLog.InfoVerification.hit_rules.rule_c_courtdetailpro)
            values.forEach(res => {
                if (res.weight > data.weight) {
                    data = res
                }

            })
            return data
        },
        //借贷评估
        ruleapplyloan_height() {
            let list = []

            if (!this.hxLog.Rule) {
                return 0
            }
            if (!this.hxLog.Rule.hit_rules) {
                return 0
            }

            const values = Object.values(this.hxLog.Rule.hit_rules)

            values.forEach(re => {
                console.log(re)
                Object.values(re).forEach(res => {
                    list.push(res)
                })
            })
            return list.filter(res => res.weight >= 80).length
        },
        ruleapplyloan() {
            let data = [{
                "weight": "-1",
                "name_rule": "无结果"
            }]
            let list = []

            if (!this.hxLog.Rule) {
                return data
            }
            if (!this.hxLog.Rule.hit_rules) {
                return data
            }

            const values = Object.values(this.hxLog.Rule.hit_rules)

            values.forEach(re => {
                console.log(re)
                Object.values(re).forEach(res => {
                    list.push(res)
                })

            })
            const list80 = list.filter(res => res.weight >= 80)
            const list0_79 = list.filter(res => res.weight < 80)
            if (list80.length) {
                return list80
            } else if (list0_79.length) {
                return [{
                    "weight": "60",
                    "name_rule": "核验通过"
                }]
            } else {
                return data
            }
        },
        phoneDetail() {
            let phone = [
                {
                    operation: '--',
                    TelPeriod: '--',
                    TelStatus: '--'
                }
            ]
            if (this.infoLog.TelPeriod) {
                console.log(this.infoLog.TelPeriod)
                if (this.infoLog.TelPeriod.operation) {
                    const { operation } = this.infoLog.TelPeriod
                    if (operation) {
                        const arr = ['电信', '联通', '移动', '其他']
                        phone[0].operation = arr[parseInt(operation) - 1]
                    }
                }
                if (this.infoLog.TelPeriod.data) {
                    const arr = ['半年内', '半年到一年', '一年到两年', '两年以上']
                    const { value } = this.infoLog.TelPeriod.data
                    if (value) {
                        phone[0].TelPeriod = arr[parseInt(value) - 1]
                    } else {
                        phone[0].TelPeriod = '无结果'
                    }
                }
            }
            if (this.infoLog.TelStatus) {
                if (this.infoLog.TelStatus.data) {
                    const arr = ['正常', '停机', '销号', '异常（包括预销号、不在网）']
                    const { value } = this.infoLog.TelStatus.data
                    if (value) {
                        phone[0].TelStatus = arr[parseInt(value) - 1]

                    } else {
                        phone[0].TelStatus = '无结果'
                    }
                }
            }


            return phone
        },
        ObjMap() {
            return this.transformObject({ als: this.hxLog.ApplyLoanStr })
        }

    },
    methods: {
        renderTable(arr) {
            let list = []
            arr.forEach((res, index) => {
                let obj = {}
                res.forEach((re, idx) => {

                    if (Array.isArray(re)) {
                        obj[idx + 1] = `${re[0] || '-'}/${re[1] === undefined ? '-' : re[1]}`
                    } else {
                        obj[idx + 1] = re
                    }
                })
                list.push(obj)
            })
            return list
        },
        show(data) {
            if (!data.verification) {
                this.$message({
                    type: 'error',
                    message: 'verification is null'
                })
                return
            }
            // const newhxLog = JSON.parse(data.verification.hxLog)
            // const newinfoLog = JSON.parse(data.verification.infoLog)
            // if (((newhxLog.code !== '00') && (newhxLog.code !== '100002')) || ((newinfoLog.code !== '00') && (newinfoLog.code !== '100002'))) {
            //     this.$message({
            //         type: 'error',
            //         message: data.verification.hxLog
            //     })
            //     return
            // }
            this.data = data;
            this.isshow = true;
        },
        hidd() {
            this.isshow = false;
            this.$emit("callback")
        },
        transformObject(obj, prefix = '') {
            let result = {};
            // 遍历对象的每个键值对
            for (let key in obj) {
                // 检查属性是否为对象
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    // 递归调用，如果是对象则继续展开
                    Object.assign(result, this.transformObject(obj[key], prefix + key + '_'));
                } else {
                    // 否则创建新的键值对
                    result[prefix + key] = obj[key];
                }
            }
            return result;
        }
    }
}
</script>

<style scoped lang="scss">
$succ: #40c2bd;
$err: #ff6365;

.text-red {
    color: $err;
}

.text-green {
    color: $succ;
}

.header {
    position: relative;

    .tittle {
        line-height: 50px;
        font-size: 16px;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        border-bottom: 1px solid #ebeef5;
    }

    .back {
        position: absolute;
        left: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

#container {
    display: flex;
    justify-content: center;
    padding: 20px;

    h4 {
        border-left: 4px #60acef solid;
        padding-left: 14px;
        margin: 8px 0;
        font-weight: 600;
        margin-top: 20px;
    }

    .dui {
        background: $succ;
        color: #fff;
        border-radius: 2px;
    }

    .wu {
        background: #999999;
        color: #fff;
        border-radius: 2px;
    }

    .gao {
        color: $err;
    }

    .box {
        background: #eaedf3;
        border-radius: 10px;
        padding: 20px 14px;
        font-size: 13px;
        line-height: 30px;
    }

    .conter {
        background: #fff;
        width: 860px;
        min-height: 1217px;
        padding: 60px 50px;
        box-shadow: 2px 2px 20px 0px #ccc;
        position: relative;

        .seal {
            position: absolute;
            width: 130px;
            height: 130px;
            top: 100px;
            right: 50px;
            z-index: 2;
        }

        .title {
            display: flex;
            justify-content: space-between;

            &-l {
                display: flex;
                font-size: 30px;
                font-weight: 900;
                line-height: 67px;
            }

            &-r {
                font-size: 12px;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }

        .top {
            display: flex;

            .succ {
                color: $succ;
            }

            .err {
                color: $err;
            }

            .succ,
            .err {
                font-size: 18px;
            }

            .des {
                color: #84919a;
                font-size: 12px;
            }

            &-l,
            &-r {
                width: 45%;
                height: 40px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 47px;
                background-repeat: no-repeat;
                background-size: 40px;
            }

            &-l {
                background-image: url(~@/static/image/inspect/1.png);
            }

            &-r {
                background-image: url(~@/static/image/inspect/2.png);
            }
        }

        .hint {
            height: 37px;
            display: flex;
            line-height: 37px;
            margin-top: 20px;
            background: #fff6f6;
            border-radius: 5px;

            &-l {
                width: 140px;
                background: $err;
                height: 37px;
                text-align: center;
                color: #fff;
                border-radius: 5px;
                font-size: 14px;
            }

            &-r {
                font-size: 12px;
                margin-left: 20px;

                .red {
                    color: $err;
                }
            }
        }
    }
}

.tableHeader {
    height: 40px;
    display: flex;
    align-items: center;

    &_number {
        font-size: 45px;
        color: #939393;
    }

    &_title {
        margin-left: 15px;

        &_t {
            font-size: 20;
        }

        &_b {
            margin-top: 10px;
            font-size: 12px;
            color: #939393;
        }
    }
}

.header2 {
    height: 38px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    background: #f7f7fa;

    &_l {
        display: inline-block;
        width: 306px;
        height: 38px;
        line-height: 38px;
        text-align: left;
        text-indent: 18px;
        font-size: 14px;
        color: #fff;
        border-top-right-radius: 27px;
        border-top-left-radius: 4px;
        border-left: 0 solid transparent;
        border-bottom: 38px solid #449ae7;
        border-right: 24px solid transparent;
    }

    &_r {
        display: inline-block;
        text-align: right;
        padding-right: 18px;
        width: 200px;
        float: right;
        line-height: 38px;
        font-weight: 700;
        color: #999;
        font-size: 12px;
    }
}

.des {
    font-size: 12px;
    margin-top: 10px;
}

.des2 {
    list-style: none;
    font-size: 12px;
    color: #999;
    vertical-align: top;
    display: flex;
    margin-top: 10px;
    line-height: 1.5;
}

.header3 {
    font-size: 12px;
    line-height: 1;
    color: #495060;
    margin-top: 20px;

    &:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        width: 3px;
        height: 12px;
        position: relative;
        top: -2px;
        vertical-align: text-bottom;
        background: #449ae7;
        font-weight: 700;
    }
}

.footer {
    margin-top: 20px;
    font-size: 14px;

    ul {
        margin-top: 10px;
        list-style: none;
        font-size: 12px;
        color: #999 !important;
        line-height: 24px;
    }
}
</style>