/*
* @Author: 曹俊杰
* @Date: 2023-04-22 22:11:44
* @Module: 分类表单
*/
<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="400px" @closed="closed" :close-on-click-modal="false">
    <div>
      <el-form size="mini" label-position="top" :model="form" ref="form" @submit.native.prevent>
        <el-row :gutter="30">
          <el-col :span="24">
            <el-form-item :label="`${label}名称`" prop="item">
              <el-input  maxlength="7" show-word-limit @keyup.enter.native="()=>submit()" v-model="form.label" :placeholder="`请输入${label}名称`" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {

  data() {

    return {
      type: 'add',
      dialogVisible: false,
      dirId: null,
      parentId: null,
      form: {
        label: ''
      },
      addtype: null
    };
  },
  mounted() { },
  computed: {
    label() {
      if (this.addtype == 'fenlei') {
        return '分类'
      }
      return '目录'
    },
    title() {
      if (this.type == 'add') {
        if (this.addtype == 'fenlei') {
          return '创建分类'
        }
        return '添加目录'
      }
      if (this.type == 'revision') {
        if (this.addtype == 'fenlei') {
          return '重命名分类'
        }
        return '重命名目录'
      }
      return '提示'
    }
  },
  methods: {
    show({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.dirId = data.dirId;
      this.type = type;
      this.addtype = data.type
      this.dialogVisible = true
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (this.type == "delete") {
          this.onsubmit()
        } else {
          if (valid) {
            this.onsubmit()
          } else {
            return false
          }
        }
      })
    },
    close() {
      this.dialogVisible = false
    },
    closed() {
      this.form = {
        label: ''
      }
      this.dirId = null;
      this.parentId = null
    },

    async onsubmit() {
      let api = {
        revision: {
          func: this.$api.putChangeArchiveDir,
          params: {
            ...this.form
          }
        },
        add: {
          func: this.$api.putArchiveDir,
          params: {
            ...this.form
          }
        }
      }
      if (this.type === 'add' && this.dirId) {
        api.add.params.parentId = this.dirId
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        window.location.reload();
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
</script>
<style lang='scss' scoped></style>