<template>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="450px">
        <div>
            <el-form size="mini" label-position="top" :model="form" ref="form">
                <el-form-item label="手机号" prop="phone" :rules="[
                    { validator: phoneValidator, trigger: 'blur' }
                ]">
                    <el-input placeholder="请输入手机号" v-model.number="form.phone"></el-input>
                </el-form-item>

                <el-form-item label="员工状态" prop="verifyCode" :rules="[
                    { required: true, message: '请选择员工状态', trigger: 'blur' },
                ]">
                    <el-select v-model="form.state" placeholder="请选择员工状态" style="width: 100%;">
                        <el-option v-for="item in employeeType" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>

                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="mini">取 消</el-button>
            <el-button type="primary" @click="submit" size="mini">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import userinfo from '@/mixins/userinfo.js'
import selectData from '@/mixins/selectData.js'
export default {
    mixins: [userinfo, selectData],
    props: {
        list: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
    },
    computed: {
        title() {
            return `编辑员工`
        }
    },
    data() {

        return {
            dialogVisible: false,
            form: {
                phone: null,
                state: null,
            },
            empUuid: null,

        };
    },
    mounted() { },
    methods: {
        phoneValidator(_, value, callback) {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            }
            if (this.$utils.test.mobile(value)) {
                return callback();
            } else {
                return callback(new Error('请输入正确的手机号'));
            }
        },
        close() {
            this.dialogVisible = false
            this.$refs.form.clearValidate()
        },
        submit() {
            this.postAccountEmployee()
        },
        show({ uuid: empUuid, phone, state }) {
            this.empUuid = empUuid;
            this.form.phone = phone;
            this.phone = phone;
            this.form.state = state;
            this.dialogVisible = true
        },
        async postAccountEmployee() {
            console.log(this.form.phone,this.phone)
            let prem = {
                empUuid: this.empUuid,
                state:this.form.state
            }
            if(this.form.phone!=this.phone){
                prem.phone = this.form.phone
            }
            const { status, data, info } = await this.$api.postAccountEmployee(prem)
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                });
                this.close()
                this.$emit('success')
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        }

    },
};
</script>
<style lang='scss' scoped>
/deep/.el-dialog__body {
    padding: 0 20px;
}
</style>