<template>
    <div class="tuiguang ">
        <el-dialog :visible.sync="dialogVisible" width="415px" top="50px" :modal-append-to-body="false">
            <div class="content" @click="saveFn">
                <canvas id="myCanvas" :width="width * devicePixelRatio" :height="height * devicePixelRatio"
                    :style="`width: ${width}px;height: ${height}px;`"> </canvas>
            </div>
        </el-dialog>
    </div>

</template>

<script>
import haibao from '@/static/image/haibao2.png'
export default {
    data() {
        return {
            dialogVisible: false,
            code: "",
            canvasData: null,
            // haibaoUrl:'https://meimengapp.oss-cn-beijing.aliyuncs.com/znsign/poster/template.png'
            haibaoUrl: haibao,
            // devicePixelRatio: 4
            width: 415,
            height: 3076
        }
    },
    computed: {
        devicePixelRatio() {
            // return window.devicePixelRatio || 1
            return 6
        }
    },
    methods: {

        async show(code) {
            this.dialogVisible = true;
            this.code = code
            await this.$nextTick();
            this.int()
        },
        int() {
            var canvas = document.getElementById('myCanvas');
            this.canvasData = canvas
            var ctx = canvas.getContext('2d');

            var backgroundImage = new Image();
            backgroundImage.setAttribute('crossOrigin', 'anonymous');

            const width = canvas.width;
            const height = canvas.height;
            backgroundImage.onload = () => {
                ctx.drawImage(backgroundImage, 0, 0, width, height);

                // 绘制文字
                ctx.font = `${20 * this.devicePixelRatio}px Arial`;
                ctx.fillStyle = '#262626';
                var text = this.code;
                var textWidth = ctx.measureText(text).width;
                ctx.fillText(this.code, (width / 2) - (textWidth / 2),  (498 * this.devicePixelRatio));
            }
            backgroundImage.src = this.haibaoUrl;
        },
        saveFn() {
           
            const { canvasData: canvas } = this
            const dataURL = canvas.toDataURL('image/png');
            const downloadLink = document.createElement('a');

            downloadLink.href = dataURL;
            downloadLink.download = 'share.png';
            downloadLink.click();
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="scss" scoped>
.tuiguang {

    /deep/.el-dialog {
        &__header,
        &__body {
            padding: 0;
        }
    }

    .content {

        height: 800px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

    }
}
</style>